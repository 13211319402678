import { DELETE, GET, POST } from '@utils/api';

import type {
  ListProductResponse,
  CurrentSubscription,
  Product,
  CheckoutSubscriptionParams,
  CheckoutSubscriptionResponse,
} from './types';
import {
  CheckoutSessionResponse,
  CheckoutSessionParams,
} from '@dapi/planAndBilling/types';
import { MutableRefObject } from 'react';

export const listProducts = ({
  path,
  token,
}: {
  path: string;
  token: string;
}): Promise<ListProductResponse> => {
  return GET<ListProductResponse>({ path, token });
};

export const currentSubscription = ({
  path,
  token,
}: {
  path: string;
  token: string;
}): Promise<CurrentSubscription> => {
  return GET<CurrentSubscription>({ path, token });
};

export const subscribeAddOn = ({
  path,
  token,
}: {
  path: string;
  token: string;
}): Promise<Product> => {
  return POST<Product>({ path, token, data: {} });
};

export const unSubscribe = ({
  path,
  token,
}: {
  path: string;
  token: string;
}): Promise<Product> => {
  return DELETE<Product>({ path, token, data: {} });
};

export const subscribeToProduct = ({
  path,
  token,
  data,
}: {
  path: string;
  token: string;
  data: CheckoutSessionParams;
}): Promise<CheckoutSessionResponse> => {
  return POST<CheckoutSessionResponse>({ path, token, data });
};

export const checkoutSubscription = ({
  path,
  token,
  data,
  abortControllerRef,
}: {
  path: string;
  token: string;
  data: CheckoutSubscriptionParams;
  abortControllerRef: MutableRefObject<AbortController>;
}): Promise<CheckoutSubscriptionResponse> => {
  return POST<CheckoutSubscriptionResponse>({
    path,
    token,
    data,
    abortControllerRef,
  });
};

export const completeSubscription = ({
  path,
  token,
}: {
  path: string;
  token: string;
}): Promise<never> => {
  return POST<never>({ path, token, data: {} });
};
