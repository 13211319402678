import { CopyButton } from '@components/CopyButton';

function urlAsLastActive() {
  const url = new URL(window.location.href);
  const pathArray = url.pathname.split('/');
  const pathIndex = pathArray.indexOf('instances');
  const remainingPath = pathArray.slice(pathIndex + 2).join('/');
  return `${url.protocol}//${url.host}/last-active?path=${remainingPath}`;
}

export default function StaffCopyURL() {
  return (
    <CopyButton
      ariaLabel='Copy URL as Last Active'
      buttonText='Copy URL as Last Active'
      text={urlAsLastActive()}
      intent='textButton'
      size='full'
    />
  );
}
