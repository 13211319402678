import { useOrganization } from '@clerk/nextjs';

export const useMembership = () => {
  const { membership, isLoaded } = useOrganization();

  const isAdmin =
    membership?.role === 'admin' || membership?.role === 'org:admin';

  return { ...membership, isAdmin, isLoaded };
};
