import React from 'react';
import {
  // See https://linear.app/clerk/issue/DES-872/remove-chakra
  // eslint-disable-next-line no-restricted-imports
  Box,
  // See https://linear.app/clerk/issue/DES-872/remove-chakra
  // eslint-disable-next-line no-restricted-imports
  BoxProps,
} from '@chakra-ui/react';
import Link from 'next/link';

type HelperLinkProps = {
  href: string;
  children?: React.ReactNode;
} & BoxProps;

export const HelperLink = ({
  children = 'Learn where to find this',
  color = 'primary.500',
  href,
  textStyle = 'sm-medium',
  ...rest
}: HelperLinkProps): JSX.Element => (
  <Link target='_blank' href={href} passHref>
    <Box as='span' textStyle={textStyle} color={color} {...rest}>
      {children}
    </Box>
  </Link>
);
