import { useUser } from '@clerk/nextjs';
import { useState, useEffect } from 'react';

export default function useClerkStaff() {
  const { user } = useUser();
  const [isClerkStaff, setIsClerkStaff] = useState(false);

  useEffect(() => {
    const hasClerkEmailAddress =
      user?.primaryEmailAddress?.emailAddress.endsWith('@clerk.dev') &&
      user?.primaryEmailAddress?.verification.status === 'verified';

    // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
    setIsClerkStaff(hasClerkEmailAddress);
  }, []);

  return isClerkStaff;
}
