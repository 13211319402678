import { create } from 'zustand';
import { getCookie, setCookie } from '@/utils/cookies';

type StaffModeStore = {
  staffModeEnabled: boolean;
  toggleStaffMode: () => void;
};

const staffModeEnabled =
  typeof document !== 'undefined'
    ? getCookie('__clerk_staff_mode') === 'true'
    : false;

export const useStaffModeStore = create<StaffModeStore>(set => ({
  staffModeEnabled,
  toggleStaffMode: () => {
    set(state => {
      const staffModeEnabled = !state.staffModeEnabled;
      setCookie('__clerk_staff_mode', String(staffModeEnabled), 400);
      return { staffModeEnabled };
    });
  },
}));
