export const Tabs = {
  parts: ['tablist', 'tab', 'tabpanel'],
  baseStyle: {
    tab: {
      paddingInlineStart: '0',
      paddingInlineEnd: '0',
      pt: '0 !important',
      padding: '0',
      pb: '2',
      mr: '6',
    },
    tabpanel: {
      p: '0',
      pt: '12',
    },
  },
  variants: {
    line: {
      tablist: {
        borderBottomWidth: '1px',
        borderColor: 'borders.base',
      },
      tab: {
        color: 'legacyGray.500',
        fontSize: '0.8125rem',
        fontWeight: '450',
        lineHeight: '1.125rem',
        textStyle: 'md-medium',
        borderBottomWidth: '1px',
        mb: '-1px', // Aligns tabs' border-bottom with container's border-bottom
        _selected: {
          color: 'black',
        },
        _active: {
          bg: 'unset',
        },
        _focus: {
          boxShadow: 'none',
        },
      },
    },
  },
};
