import { useController, useFormContext } from 'react-hook-form';
import { useTLDParser } from '@hooks/useTLDParser';
import { RadioGroup } from '@clerk/ceramic/experimental/components/RadioGroup';

export function MultipleAppsExtraFields() {
  const { parse } = useTLDParser();
  const { watch, control } = useFormContext();
  const { home_url } = watch();

  const {
    field: { onChange: onChangeIsSecondary, value },
  } = useController({
    control,
    name: 'is_secondary',
  });

  const tldResult = parse(home_url);

  const hasSubdomain = tldResult && tldResult.subdomain;

  if (!hasSubdomain) {
    return null;
  }

  const onChangeRadio = value =>
    onChangeIsSecondary(value === 'true' ? true : false);

  return (
    <div className='rounded-lg bg-[#F5F5F5] p-4'>
      <p className='text-[0.875rem] leading-[1.125rem] font-normal mb-4'>
        Is this the primary application for <strong>{tldResult.domain}</strong>{' '}
        or a secondary application?
      </p>
      <RadioGroup
        layout='compact'
        onChange={onChangeRadio}
        defaultValue={value ? 'true' : 'false'}
        items={[
          {
            label: 'Primary application',
            value: 'false',
            children: (
              <ul className='text-sm text-secondary list-disc space-y-1 ml-4'>
                <li>
                  Clerk’s API will be hosted on{' '}
                  <span className='text-legacyGray-900 font-semibold'>
                    clerk.{tldResult.domain}
                  </span>
                </li>
                <li>
                  Verification emails will be sent from @
                  <span className='text-legacyGray-900 font-semibold'>
                    {tldResult.domain}
                  </span>
                </li>
              </ul>
            ),
          },
          {
            label: 'Secondary application',
            value: 'true',
            children: (
              <ul className='text-sm text-secondary list-disc space-y-1 ml-4'>
                <li>
                  Clerk’s API will be hosted on{' '}
                  <span className='text-legacyGray-900 font-semibold'>
                    clerk.{tldResult.hostname}
                  </span>
                </li>
                <li>
                  Verification emails will be sent from @
                  <span className='text-legacyGray-900 font-semibold'>
                    {tldResult.hostname}
                  </span>
                </li>
              </ul>
            ),
          },
        ]}
      />
    </div>
  );
}
