'use client';

import {
  UserButton as ClerkUserButton,
  SignedIn,
  useSession,
} from '@clerk/nextjs';

export function UserButton() {
  const { isLoaded } = useSession();
  return (
    <div className='size-7'>
      {isLoaded ? (
        <SignedIn>
          <ClerkUserButton
            appearance={{
              elements: {
                UserButtonPopoverCard:
                  '-translate-y-[0.1875rem] rounded-[0.875rem] [animation:none] bg-clip-padding border border-black/5 bg-white dark:bg-gray-400 shadow-lg dark:shadow-black/20',
                userButtonTrigger:
                  'after:absolute after:-inset-1 after:rounded-full after:border-2 after:border-blue-900 after:opacity-0 focus-visible:after:opacity-100 focus:shadow-none',
              },
            }}
          />
        </SignedIn>
      ) : (
        <div className='size-7 animate-pulse rounded-full bg-gray-400' />
      )}
    </div>
  );
}
