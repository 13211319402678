export function MagnifyingGlassIconPath(props: { size?: 'sm' | 'base' }) {
  const { size = 'base' } = props;
  return (
    <>
      {size === 'base' ? (
        <>
          <path
            d='M13.869 8.80952C13.869 10.2098 13.3002 11.4773 12.381 12.3933C11.4659 13.3053 10.2035 13.869 8.80952 13.869C6.01523 13.869 3.75 11.6038 3.75 8.80952C3.75 6.01523 6.01523 3.75 8.80952 3.75C11.6038 3.75 13.869 6.01523 13.869 8.80952Z'
            fill='currentColor'
            fillOpacity={0.15}
          />
          <path d='M12.381 12.3933C13.3002 11.4773 13.869 10.2098 13.869 8.80952C13.869 6.01523 11.6038 3.75 8.80952 3.75C6.01523 3.75 3.75 6.01523 3.75 8.80952C3.75 11.6038 6.01523 13.869 8.80952 13.869C10.2035 13.869 11.4659 13.3053 12.381 12.3933ZM12.381 12.3933L16.25 16.25' />
        </>
      ) : (
        <>
          <path d='M10 10.0104C10.7722 9.24089 11.25 8.17625 11.25 7C11.25 4.65279 9.34721 2.75 7 2.75C4.65279 2.75 2.75 4.65279 2.75 7C2.75 9.34721 4.65279 11.25 7 11.25C8.17096 11.25 9.23132 10.7764 10 10.0104ZM10 10.0104L13.25 13.25' />
        </>
      )}
    </>
  );
}
