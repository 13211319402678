export function stripProtocol(url: string): string {
  return (url || '').replace(/(https?)?(:\/+)?/, '');
}

export function parseSearchParams(queryString = ''): URLSearchParams {
  if (queryString.startsWith('?')) {
    queryString = queryString.slice(1);
  }
  return new URLSearchParams(queryString);
}

export function isValidURL(url: string): boolean {
  try {
    return Boolean(new URL(url));
  } catch (e) {
    return false;
  }
}

export function appendHttps<T extends Record<string, string | null>>(
  obj: Partial<T>,
): Partial<T> {
  const result: Partial<T> = { ...obj };

  for (const key in result) {
    if (result[key] !== null) {
      const value = result[key];
      if (
        typeof value === 'string' &&
        value !== '' &&
        !value.startsWith('https://')
      ) {
        result[key] = `https://${value}` as T[Extract<keyof T, string>];
      }
    }
  }

  return result;
}

export function removeHttps<T extends Record<string, string | null>>(
  obj: Partial<T>,
): Partial<T> {
  const result: Partial<T> = { ...obj };

  for (const key in result) {
    if (result[key] !== null) {
      const value = result[key];
      if (typeof value === 'string' && value.startsWith('https://')) {
        result[key] = value.replace('https://', '') as T[Extract<
          keyof T,
          string
        >];
      }
    }
  }

  return result;
}
