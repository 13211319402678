import * as React from 'react';
import { Command, useCommandState } from 'cmdk';
import { useEffect, useState } from 'react';
import * as Dialog from '@clerk/ceramic/experimental/components/Dialog';
import { useApplication } from '@hooks/useApplication';
import { useInstance } from '@hooks/useInstance';
import { useRouter } from 'next/router';
import { CommandMenuDocsSearch } from './CommandMenuDocsSearch';

// FEATURES/MVP SHIP FIRST:
// page navigation
// groups

// TODOS/ADDONS:
// loading state
// doc search
// paste api key, show app with that key
// org switcher, app switcher
// breadcrumbs so people can get back from sub menu
// page-specific commands
// upgrade to paid, contact support

// ON THE HOME PAGE, separate menu (likely requires DAPI work):
// search app names across all orgs
// search domains across all orgs, ideally for production domains (customer facing improvement)

interface CommandItem {
  label: string;
  path?: string;
  shortcut?: string[];
  href?: string;
  keywords?: string[];
  callback?: () => void;
}

interface CommandGroup {
  label: string;
  items: CommandItem[];
}

export type SubmenuTypes = 'default' | 'docs';

export const useSearchableCommandItems = (): {
  allItems: CommandGroup[];
  setActiveMenu: React.Dispatch<React.SetStateAction<SubmenuTypes>>;
  activeMenu: string;
} => {
  const [activeMenu, setActiveMenu] = useState<SubmenuTypes>('default');
  const { data: application } = useApplication();
  const { data: instance } = useInstance();

  if (!application || !instance) {
    return { allItems: [], setActiveMenu, activeMenu };
  }

  const { id: appId } = application;
  const { id: instanceId } = instance;
  const baseUrl = `/apps/${appId}/instances/${instanceId}`;

  const ITEMS: CommandGroup[] = [
    {
      label: '',
      items: [
        { label: 'Home', path: '', shortcut: ['g', 'h'] },
        { label: 'Users', path: '/users' },
        { label: 'Organizations', path: '/organizations' },
        { label: 'Search Docs', callback: () => setActiveMenu('docs') },
      ],
    },
    {
      label: 'Configure',
      items: [
        {
          label: 'Email, Phone & Username',
          path: 'user-authentication/email-phone-username',
          keywords: [
            'contact',
            'authentication',
            'personal information',
            'permissions',
          ],
        },
        {
          label: 'Social Connections',
          path: 'user-authentication/social-connections',
          keywords: ['oauth'],
        },
        {
          label: 'Web3',
          path: 'user-authentication/web3',
          keywords: ['metamask', 'crypto'],
        },
        {
          label: 'Enterprise Connections',
          path: 'user-authentication/enterprise-connections',
          keywords: ['saml', 'sso', 'single sign on'],
        },
        {
          label: 'Multi-Factor',
          path: 'user-authentication/multi-factor',
          keywords: ['2fa'],
        },
        {
          label: 'Restrictions',
          path: 'user-authentication/restrictions',
          keywords: [
            'allowlist',
            'blocklist',
            'whitelist',
            'block',
            'permissions',
          ],
        },
        {
          label: 'Attack Protection',
          path: 'user-authentication/attack-protection',
          keywords: ['security', 'ddos', 'dos'],
        },
        {
          label: 'Organization settings',
          path: 'organizations-settings',
          keywords: ['roles', 'permissions'],
        },
        {
          label: 'Sessions',
          path: 'sessions',
          keywords: ['token', 'multi', 'multi-session'],
        },
        {
          label: 'Account Portal',
          path: 'account-portal',
          keywords: ['redirects', 'customization', 'colors', 'branding'],
        },
        {
          label: 'Avatars',
          path: 'customization/avatars',
          keywords: [
            'customization',
            'organization logo',
            'logo',
            'colors',
            'branding',
          ],
        },
        {
          label: 'Emails',
          path: 'customization/email',
          keywords: [
            'magic link',
            'invitation',
            'password',
            'reset',
            'verification',
          ],
        },
        {
          label: 'SMS',
          path: 'customization/sms',
          keywords: [
            'magic link',
            'invitation',
            'password',
            'reset',
            'verification',
          ],
        },
        {
          label: 'Integrations',
          path: 'integrations',
          keywords: ['firebase', 'google', 'analytics'],
        },
        { label: 'JWT Templates', path: 'jwt-templates' },
        { label: 'WebHooks', path: 'webhooks', keywords: ['svix'] },
        { label: 'Stripe Billing', path: 'stripe-billing' },
        {
          label: 'iOS Application',
          path: 'ios-application',
          keywords: ['apple'],
        },
      ],
    },
    {
      label: 'Developers',
      items: [
        {
          label: 'API Keys',
          path: 'api-keys',
          keywords: [
            'api urls',
            'frontend api url',
            'backend api url',
            'pem public',
            'publishable',
            'secret',
          ],
        },
        { label: 'Paths', path: 'paths', keywords: ['subdomain', 'redirects'] },
        {
          label: 'Domains',
          path: 'domains',
          keywords: ['satellite', 'multi domain', 'multi'],
        },
      ],
    },
    {
      label: 'Application',
      items: [
        {
          label: 'Plan & Billing',
          path: 'plan-billing',
          keywords: ['subscription', 'payment'],
        },
        {
          label: 'Settings',
          path: 'settings',
          keywords: [
            'branding',
            'logo',
            'favicon',
            'testing',
            'test mode',
            'support email',
            'application name',
          ],
        },
      ],
    },
  ];

  const DOCS_SUBMENU = [
    {
      label: '',
      items: [
        {
          label: 'Docs item...',
          href: '/',
        },
        {
          label: 'More...',
          href: '/',
        },
      ],
    },
  ];

  function activeItems() {
    switch (activeMenu) {
      case 'docs':
        return DOCS_SUBMENU;

      default:
        return ITEMS;
    }
  }
  const allItems = activeItems().map(group => ({
    ...group,
    items: group.items.map(item => ({
      ...item,
      href:
        typeof item.path === 'string' ? `${baseUrl}/${item.path}` : undefined,
    })),
  }));

  return { allItems, setActiveMenu, activeMenu };
};

export default function CommandMenu() {
  const [open, setOpen] = useState(false);
  const { allItems, setActiveMenu, activeMenu } = useSearchableCommandItems();
  const router = useRouter();

  useEffect(() => {
    const down = e => {
      if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        setOpen(prev => !prev);
        if (!open) {
          setActiveMenu('default');
        }
      }

      if (e.key === 'Escape') {
        setOpen(false);
      }
    };
    document.addEventListener('keydown', down);
    return () => document.removeEventListener('keydown', down);
  }, []);

  useEffect(() => {
    const handleRouteChange = () => {
      setOpen(false);
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router]);

  useEffect(() => {
    const pressedKeys = new Set();
    const itemsWithShortcuts = allItems
      .flatMap(group => group.items)
      .filter(item => item.shortcut);

    const down = e => {
      pressedKeys.add(e.key);

      itemsWithShortcuts.forEach(item => {
        if (item.shortcut?.every(key => pressedKeys.has(key))) {
          e.preventDefault();
          if (item.href) {
            void router.push(String(item.href));
          } else if (item.callback) {
            item.callback();
          }
        }
      });
    };

    const up = e => {
      pressedKeys.delete(e.key);
    };

    document.addEventListener('keydown', down);
    document.addEventListener('keyup', up);

    return () => {
      document.removeEventListener('keydown', down);
      document.removeEventListener('keyup', up);
    };
  }, [allItems, router]);

  return (
    <Dialog.Root
      fullWidth
      open={open}
      onOpenChange={o => {
        setOpen(o);
      }}
    >
      <Dialog.Content className='p-0 w-[640px]'>
        {activeMenu === 'default' ? (
          <Command label='Global Command Menu'>
            <ol className='p-2 flex gap-1'>
              <li>
                <button
                  className='h-5 px-1.5 rounded-sm text-legacyGray-700 bg-legacyGray-100 flex items-center text-sm'
                  onClick={() => setActiveMenu('default')}
                >
                  Home
                </button>
              </li>
              {activeMenu !== 'default' ? (
                <li>
                  <button className='h-5 px-1.5 rounded-sm text-legacyGray-700 bg-legacyGray-100 flex items-center text-sm capitalize'>
                    {activeMenu}
                  </button>
                </li>
              ) : null}
            </ol>
            <CommandInput setActiveMenu={setActiveMenu} />
            <Command.List className='max-h-96 overflow-y-auto p-2 scroll-p-2'>
              <Command.Empty className='h-20 flex justify-center items-center'>
                No results found.
              </Command.Empty>
              {allItems.map(({ label, items }) => (
                <React.Fragment key={label}>
                  <Command.Group
                    key={label}
                    heading={label}
                    className='[&>[cmdk-group-heading]:bg-legacyGray-400] [&>div]:data-[state=open]:rotate-180'
                  >
                    {items.map(item => (
                      <CommandItem item={item} key={item.label} />
                    ))}
                  </Command.Group>
                  <Command.Separator className='my-2 last:my-0 border-b last:border-none' />
                </React.Fragment>
              ))}
            </Command.List>
          </Command>
        ) : (
          <CommandMenuDocsSearch
            setOpen={setOpen}
            setActiveMenu={setActiveMenu}
          />
        )}
      </Dialog.Content>
    </Dialog.Root>
  );
}

function CommandItem({ item }: { item: CommandItem }) {
  const router = useRouter();

  return (
    <Command.Item
      key={item.label}
      value={
        item.keywords ? [...item.keywords, item.label].join(' ') : item.label
      }
      onSelect={() => {
        if (item.href) {
          void router.push(item.href);
        } else if (item.callback) {
          item.callback();
        }
      }}
      className='flex h-10 text-black/80 items-center px-2 data-[selected=true]:bg-black/5 rounded-lg data-[selected=true]:text-primary cursor-pointer'
    >
      {item.label}
      {item.shortcut ? (
        <div className='space-x-1 flex ml-auto'>
          {item.shortcut.map(item => (
            <kbd
              className='h-5 w-5 grid place-items-center rounded border bg-white uppercase text-xs'
              key={item}
            >
              {item}
            </kbd>
          ))}
        </div>
      ) : null}
    </Command.Item>
  );
}

function CommandInput({
  setActiveMenu,
}: {
  setActiveMenu: React.Dispatch<SubmenuTypes>;
}) {
  const search = useCommandState(state => state.search);

  return (
    <Command.Input
      autoFocus
      placeholder='What do you need?'
      className='h-12 placeholder:text-tertiary w-full text-base px-4 focus:outline-none border-b'
      onKeyDown={e => {
        if (e.key === 'Backspace') {
          if (search === '') {
            setActiveMenu('default');
          }
        }
      }}
    />
  );
}
