/**
 * Returns the environment that Dashboard is running in.
 */
export function getDashboardEnvironment() {
  const url = new URL(process.env.NEXT_PUBLIC_DASHBOARD_API ?? '');

  return {
    isDevelopment:
      url.host.includes('.lclclerk.com') ||
      url.host.includes('.clerklocal.dev'),
    isStaging: url.host.includes('.clerkstage.dev'),
    isProduction: url.host.includes('.clerk.com'),
  };
}

if (import.meta.vitest) {
  describe('getDashboardEnvironment', () => {
    describe('with `lclclerk.com`', () => {
      it('is development', () => {
        vi.stubEnv(
          'NEXT_PUBLIC_DASHBOARD_API',
          'https://dapi.prod.lclclerk.com',
        );

        const environment = getDashboardEnvironment();

        expect(environment).toEqual({
          isDevelopment: true,
          isStaging: false,
          isProduction: false,
        });
      });
    });

    describe('with `clerklocal.dev`', () => {
      it('is development', () => {
        vi.stubEnv('NEXT_PUBLIC_DASHBOARD_API', 'https://dapi.clerklocal.dev');

        const environment = getDashboardEnvironment();

        expect(environment).toEqual({
          isDevelopment: true,
          isStaging: false,
          isProduction: false,
        });
      });
    });

    describe('with `clerk.com`', () => {
      it('is production', () => {
        vi.stubEnv('NEXT_PUBLIC_DASHBOARD_API', 'https://dapi.clerk.com');

        const environment = getDashboardEnvironment();

        expect(environment).toEqual({
          isDevelopment: false,
          isStaging: false,
          isProduction: true,
        });
      });
    });

    describe('with `clerkstage.dev`', () => {
      it('is staging', () => {
        vi.stubEnv('NEXT_PUBLIC_DASHBOARD_API', 'https://dapi.clerkstage.dev');

        const environment = getDashboardEnvironment();

        expect(environment).toEqual({
          isDevelopment: false,
          isStaging: true,
          isProduction: false,
        });
      });
    });
  });
}
