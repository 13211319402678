import { cx } from 'cva';

interface NumberProps {
  children: string | number;
  prefix?: string;
  suffix?: string;
  className?: string;
  'aria-label'?: string;
}

function numberWithCommas(x: string | number) {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function Number({
  children,
  prefix,
  className,
  suffix,
  'aria-label': ariaLabel,
}: NumberProps) {
  let number = children;

  if (prefix === '$' && typeof number === 'number') {
    number = number.toFixed(2);
  }

  return (
    <span
      aria-label={ariaLabel}
      className={cx('font-number tracking-tight', className)}
    >
      {prefix}
      {numberWithCommas(number)}
      {suffix}
    </span>
  );
}
