import React from 'react';
import { useController, UseControllerProps } from 'react-hook-form';
import {
  RadioGroup as ChakraRadioGroup,
  RadioGroupProps as ChakraRadioGroupProps,
} from '@chakra-ui/react';
import { cx } from 'cva';

type RadioGroupProps = {
  children: React.ReactNode;
  gap?: 2 | 3;
  align?: 'stretch' | 'start';
  mt?: 4;
} & ChakraRadioGroupProps &
  UseControllerProps<any>;

/**
 * @deprecated Use `components/common/Form/RadioGroup.tsx` instead.
 */
export function RadioGroup(props: RadioGroupProps) {
  const {
    name,
    rules,
    control,
    children,
    align = 'stretch',
    gap = 2,
    mt,
    isDisabled,
  } = props;
  const { field } = useController({ control, name, rules });

  return (
    <ChakraRadioGroup {...field} isDisabled={isDisabled}>
      <div
        className={cx(
          'flex flex-col',
          { start: 'items-start', stretch: 'items-stretch' }[align],
          { 2: 'gap-2', 3: 'gap-3' }[gap],
          mt === 4 && 'mt-4',
        )}
      >
        {children}
      </div>
    </ChakraRadioGroup>
  );
}
