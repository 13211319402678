import { SystemStyleObjectRecord } from '@chakra-ui/react';
import { cx } from 'cva';

// Note:
// Migrated `layerStyles` from Chakra for ease of migration
// Not to be used for new components
export const layerClassNames = {
  card: cx('border border-[#E2E8F0]', 'rounded-2xl', 'px-6 py-8', 'flex-1'),
  linkBoxCard: cx(
    'flex cursor-pointer flex-col overflow-hidden rounded-2xl',
    'border border-[#E2E8F0]',
    'bg-white transition-all duration-150',
    'hover:shadow-[0px_12px_24px_rgba(0,0,0,0.08)]',
  ),
};

export const layerStyles: SystemStyleObjectRecord = {
  card: {
    border: '1px solid',
    borderColor: 'borders.base',
    rounded: '2xl',
    py: 8,
    px: 6,
    flex: 1,
  },
  linkBoxCard: {
    display: 'flex',
    flexDirection: 'column',
    bg: 'white',
    border: '1px solid',
    borderColor: 'borders.base',
    cursor: 'pointer',
    rounded: '2xl',
    overflow: 'hidden',
    transition: 'all 150ms',
    _hover: {
      boxShadow: 'dropShadow2',
    },
  },
};
