export function ArrowUpTrayIconPath(props: { size?: 'sm' | 'base' }) {
  const { size = 'base' } = props;
  return (
    <>
      {size === 'base' ? (
        <>
          <path d='M3.75 12.75V14.25C3.75 15.3546 4.64543 16.25 5.75 16.25H14.25C15.3546 16.25 16.25 15.3546 16.25 14.25V12.75' />
          <path d='M10 12.25V3.75M10 3.75L6.75 6.97222M10 3.75L13.25 6.97222' />
        </>
      ) : (
        <>
          <path d='M2.75 10.75V11.25C2.75 12.3546 3.64543 13.25 4.75 13.25H11.25C12.3546 13.25 13.25 12.3546 13.25 11.25V10.75' />
          <path d='M8 9.25V2.75M8 2.75L4.75 5.96429M8 2.75L11.25 5.96429' />
        </>
      )}
    </>
  );
}
