import React from 'react';
import type { ModalData } from './types';

interface PaymentRequiredContext {
  showModal: (params?: ModalData) => void;
}

const PaymentRequiredContext =
  // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
  React.createContext<PaymentRequiredContext>(null);

function usePaymentRequired(): PaymentRequiredContext {
  const context = React.useContext(PaymentRequiredContext);
  if (context === undefined) {
    throw new Error(
      'usePaymentRequired must be used within a PaymentRequiredProvider',
    );
  }

  return {
    ...context,
  };
}

export { PaymentRequiredContext, usePaymentRequired };
