import useClerkStaff from '@hooks/useClerkStaff';
import useClerkStaffFeatureToggle from '@hooks/useClerkStaffFeatureToggle';
import { Icon } from '@clerk/ceramic/experimental/components/Icon';
import { easeOut } from '@clerk/ceramic/experimental/utils/easings';
import { cx } from 'cva';
import { AnimatePresence, MotionConfig, motion } from 'framer-motion';

export default function StaffModeToggle() {
  const isClerkStaff = useClerkStaff();
  const [staffModeEnabled, toggleStaffMode] = useClerkStaffFeatureToggle();

  if (!isClerkStaff) {
    return null;
  }

  return (
    <button
      className={cx(
        'group flex w-full items-center gap-3 rounded py-1.5 outline-none [transform-style:preserve-3d]',
        'focus-visible:ring-[0.1875rem] focus-visible:ring-black/8',
      )}
      onClick={toggleStaffMode}
    >
      <span
        className={cx(
          'transition-colors',
          staffModeEnabled ? 'text-primary' : 'text-secondary',
        )}
      >
        <Icon name='clerk-logo-legacy' size='sm' />
      </span>
      <span className='flex items-center gap-1.5 [perspective:500px]'>
        <span
          className={cx(
            'truncate font-book capitalize leading-none transition group-hover:text-primary initial:text-secondary',
          )}
        >
          Staff Mode
        </span>

        <MotionConfig transition={{ duration: 0.4, ease: easeOut }}>
          <AnimatePresence mode='popLayout' initial={false}>
            {staffModeEnabled ? (
              <motion.span
                key={'staff-mode-enabled-badge'}
                initial={{
                  y: -8,
                  opacity: 0,
                  rotateX: 90,
                  filter: 'blur(2px)',
                }}
                animate={{ y: 0, opacity: 1, rotateX: 0, filter: 'blur(0px)' }}
                exit={{ y: 8, opacity: 0, rotateX: -90, filter: 'blur(2px)' }}
                className={cx(
                  'inline-flex rounded bg-purple px-1.5 py-xs text-xs font-medium text-white ring-1 ring-[theme(colors.purple.500/0.2)]',
                  'shadow-[inset_0px_1px_0px_theme(colors.white/0.08),inset_0px_-1px_0px_theme(colors.white/0.04),0px_0px_0px_1px_theme(colors.purple.500),0px_2px_2px_-1px_theme(colors.purple.900/24%),0px_4px_4px_-2px_theme(colors.purple.900/12%)]',
                )}
              >
                Enabled
              </motion.span>
            ) : (
              <motion.span
                key={'staff-mode-disabled-badge'}
                initial={{
                  y: -8,
                  opacity: 0,
                  rotateX: 90,
                  filter: 'blur(2px)',
                }}
                animate={{ y: 0, opacity: 1, rotateX: 0, filter: 'blur(0px)' }}
                exit={{ y: 8, opacity: 0, rotateX: -90, filter: 'blur(2px)' }}
                className={cx(
                  'inline-flex rounded px-1.5 py-xs text-xs font-medium text-tertiary',
                  'bg-legacyGray-50 shadow-[0px_2px_0px_-1px_,theme(colors.black/0.04)] ring-1 ring-legacyGray-200',
                  'dark:bg-legacyGray-800 dark:shadow-[inset_0_1px_0_theme(colors.white/0.03),inset_0_0_0_1px_theme(colors.white/0.02),0_2px_2px_theme(colors.black/0.15),0_4px_4px_theme(colors.black/0.15)] dark:[text-shadow:0_1px_1px_theme(colors.black/0.4)]',
                )}
              >
                Disabled
              </motion.span>
            )}
          </AnimatePresence>
        </MotionConfig>
      </span>
    </button>
  );
}
