import React from 'react';
import {
  AnalyticsContext,
  AnalyticsContextType,
} from '@components/analytics/Analytics';

// Create an analytics hook that we can use with other components.
export const useAnalytics = (): AnalyticsContextType => {
  return React.useContext(AnalyticsContext);
};
