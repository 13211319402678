export function InformationSquareIconPath(props: { size?: 'sm' | 'base' }) {
  const { size = 'base' } = props;
  return (
    <>
      {size === 'base' ? (
        <>
          <path
            d='M2.75 14.25V5.75C2.75 4.09315 4.09315 2.75 5.75 2.75H14.25C15.9069 2.75 17.25 4.09315 17.25 5.75V14.25C17.25 15.9069 15.9069 17.25 14.25 17.25H5.75C4.09315 17.25 2.75 15.9069 2.75 14.25Z'
            fill='currentColor'
            fillOpacity={0.15}
          />
          <path d='M10 11V13' />
          <path d='M10 7.01V7' />
        </>
      ) : (
        <>
          <rect x='2.75' y='2.75' width='10.5' height='10.5' rx='2' />
          <path d='M8 8.75V10.25' />
          <path d='M8 5.75V6.25' />
        </>
      )}
    </>
  );
}
