import { GET } from '@utils/api';

import { FeatureFlags } from './types';

export const getFeatureFlags = ({
  path,
  token,
}: {
  path: string;
  token: string;
}): Promise<FeatureFlags> => {
  const res = GET<FeatureFlags>({ path, token });
  return res;
};
