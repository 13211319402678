import { GET, PUT } from '@utils/api';
import { SystemConfigResponse } from './types';

export const getSystemConfig = ({
  path,
  token,
}: {
  path: string;
  token: string;
}): Promise<SystemConfigResponse> => {
  return GET<SystemConfigResponse>({ path, token });
};

export const updatePreferences = ({
  path,
  token,
  data,
}: {
  path: string;
  token: string;
  data: Record<string, any>;
}): Promise<never> => {
  return PUT<never>({ path, token, data });
};
