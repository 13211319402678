import React from 'react';
import { useRouter } from 'next/router';

import ApplicationProvider, {
  useApplicationContext,
} from '../application/Provider';
import FeatureFlagsProvider, {
  useFeatureFlagContext,
} from '../featureFlags/Provider';
import InstanceProvider, { useInstanceContext } from '../instance/Provider';

import { PageLoader } from '@components/common/PageLoader';
import {
  CurrentSubscriptionProvider,
  useCurrentSubscription as useCurrentSubscriptionContext,
} from '@components/planAndBilling/currentSubscription/context/CurrentSubscriptionProvider';
import { useLastActive } from '@hooks/useLastActive';
import SettingsProvider, { useSettingsContext } from '../settings/Provider';

const VitalWrapper = ({ children }) => {
  const {
    error: applicationError,
    isLoading: isLoadingApplication,
    data: application,
  } = useApplicationContext();
  const { isLoading: isLoadingInstance } = useInstanceContext();
  const {
    user: { isLoading: isLoadingUserSettings },
    organization: { isLoading: isLoadingOrganizationSettings },
  } = useSettingsContext();
  const { isLoading: isLoadingFeatureFlags } = useFeatureFlagContext();
  const { isLoading: isLoadingCurrentSubscription } =
    useCurrentSubscriptionContext();
  const { push } = useRouter();
  const { clearLastActive } = useLastActive();

  if (
    isLoadingApplication ||
    isLoadingInstance ||
    isLoadingFeatureFlags ||
    isLoadingCurrentSubscription ||
    isLoadingUserSettings ||
    isLoadingOrganizationSettings ||
    (!application && !applicationError)
  ) {
    return <PageLoader />;
  }
  if (applicationError) {
    // When application is not found,
    // clear the last active app and redirect to the home page
    clearLastActive();
    return void push('/');
  }

  return children;
};

export const VitalProviders = ({ children }: { children: React.ReactNode }) => {
  return (
    <ApplicationProvider>
      <InstanceProvider>
        <FeatureFlagsProvider>
          <CurrentSubscriptionProvider>
            <SettingsProvider>
              <VitalWrapper>{children}</VitalWrapper>
            </SettingsProvider>
          </CurrentSubscriptionProvider>
        </FeatureFlagsProvider>
      </InstanceProvider>
    </ApplicationProvider>
  );
};
