export function ClipboardIconPath(props: { size?: 'sm' | 'base' }) {
  const { size = 'base' } = props;
  return (
    <>
      {size === 'base' ? (
        <>
          <path
            d='M3.75 15.25V5.75C3.75 4.64543 4.64543 3.75 5.75 3.75H14.25C15.3546 3.75 16.25 4.64543 16.25 5.75V15.25C16.25 16.3546 15.3546 17.25 14.25 17.25H5.75C4.64543 17.25 3.75 16.3546 3.75 15.25Z'
            fill='currentColor'
            fillOpacity={0.15}
          />
          <path
            d='M9 5.25L7.75 4.25C7.75 3.42157 8.42157 2.75 9.25 2.75H10.75C11.5784 2.75 12.25 3.42157 12.25 4.25L11 5.25H9Z'
            fill='#EEEEF0'
          />
          <path d='M7.83535 3.75H5.75C4.64543 3.75 3.75 4.64543 3.75 5.75V15.25C3.75 16.3546 4.64543 17.25 5.75 17.25H14.25C15.3546 17.25 16.25 16.3546 16.25 15.25V5.75C16.25 4.64543 15.3546 3.75 14.25 3.75H12.1646M12.1646 3.75C12.2199 3.90639 12.25 4.07468 12.25 4.25C12.25 4.375 11 5.25 11 5.25H9C9 5.25 7.75 4.375 7.75 4.25C7.75 3.42157 8.42157 2.75 9.25 2.75H10.75C11.4031 2.75 11.9587 3.1674 12.1646 3.75Z' />
          <path d='M7.75 8.75H12.25' />
          <path d='M7.75 13.25H12.25' />
        </>
      ) : (
        <>
          <path d='M6 3.25H4.75C3.64543 3.25 2.75 4.14543 2.75 5.25V11.25C2.75 12.3546 3.64543 13.25 4.75 13.25H11.25C12.3546 13.25 13.25 12.3546 13.25 11.25V5.25C13.25 4.14543 12.3546 3.25 11.25 3.25H10' />
          <path d='M6.28576 4.0894L5.75 2.75H10.25L9.71424 4.0894C9.43385 4.79036 8.75496 5.25 8 5.25C7.24504 5.25 6.56615 4.79036 6.28576 4.0894Z' />
        </>
      )}
    </>
  );
}
