import { SUPPORTED_FEATURES_TITLES } from '@constants';
import {
  GROUPED_FEATURES,
  planFeatures,
  BUSINESS_PLAN_ID,
  proFeatures,
  addonFeatures,
} from './constants';
import {
  Addon,
  CurrentPlan,
  CurrentSubscription,
  SubscriptionType,
} from '@dapi/products/types';
import { priceFromCentsToDollars } from '../utils';
import {
  AddonWithDisplayFeatures,
  PlanWithDisplayFeatures,
  CurrentSerializedSubscription,
} from './types';
import { format } from 'date-fns';

export const missingFeaturesAfterDowngrade = (
  defaultFeatures = [],
  unsupportedFeatures = [],
) => {
  const features = [...defaultFeatures];

  for (const feature of unsupportedFeatures) {
    if (feature in GROUPED_FEATURES) {
      features.unshift(GROUPED_FEATURES[feature]);
    } else {
      features.unshift(SUPPORTED_FEATURES_TITLES[feature]);
    }
  }
  const uniqueFeatures = new Set(features);

  return Array.from(uniqueFeatures);
};

export const makeProFeatures = () => {
  return {
    display_features: planFeatures.pro,
    pro_display_features: proFeatures,
  };
};
export const makeFreeFeatures = () => {
  return {
    display_features: planFeatures.free,
    pro_display_features: proFeatures,
  };
};
export const makeLegacyPlanFeatures = ({ plan }) => {
  const planName = plan.id === BUSINESS_PLAN_ID ? 'business' : 'hobby';

  let proFeatures = [
    '10,000 monthly active users free',
    'Access to new features',
    'SOC2 report',
  ];

  if (planName === 'hobby') {
    proFeatures = [
      ...proFeatures.slice(0, 2),
      'Password complexity requirements',
      ...proFeatures.slice(2),
    ];
  }

  return {
    display_features: planFeatures.legacy[planName],
    pro_display_features: proFeatures,
  };
};

export const makeDisplayFeatures = ({
  type,
  id,
}: {
  id: string;
  type: SubscriptionType;
}): { display_features: string[]; pro_display_features: string[] } => {
  if (type === 'legacy') {
    return makeLegacyPlanFeatures({ plan: { id } });
  }
  if (type === 'free') {
    return makeFreeFeatures();
  }
  return makeProFeatures();
};

export const transformAddons = (addons: Addon[]) => {
  if (!addons) {
    return [];
  }
  const transformedAddons: AddonWithDisplayFeatures[] = addons.map(addon => {
    return {
      ...addon,
      base_amount: priceFromCentsToDollars(addon.base_amount),
      display_features: addonFeatures[addon.id],
    };
  });

  return transformedAddons;
};

const transformPlan = (plan: CurrentPlan, type: SubscriptionType) => {
  if (!plan) {
    return null;
  }

  const clonedPlan: PlanWithDisplayFeatures = {
    ...plan,
    base_amount: priceFromCentsToDollars(plan.base_amount),
    ...makeDisplayFeatures({ type: type, id: plan.id }),
  };

  return clonedPlan;
};

const transformBillingCycle = (
  billing_cycle: CurrentSubscription['billing_cycle'],
): CurrentSerializedSubscription['billing_cycle'] => {
  // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
  const productPaymentStartDate = new Date(billing_cycle.start);
  // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
  const productPaymentEnd = new Date(billing_cycle.end);
  const formattedPaymentEndDate = format(productPaymentEnd, 'MMM d, yyyy');
  const formattedPaymentStartDate = format(
    productPaymentStartDate,
    'MMM d, yyyy',
  );

  // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
  return {
    ...billing_cycle,
    formattedPaymentEndDate,
    formattedPaymentStartDate,
  };
};

export const transformSubscription = (
  subscription: CurrentSubscription,
): CurrentSerializedSubscription => {
  if (!subscription) {
    // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
    return null;
  }

  const transformedSubscription: CurrentSerializedSubscription = {
    ...subscription,
    amount_due: priceFromCentsToDollars(subscription.amount_due),
    total_amount: priceFromCentsToDollars(subscription.total_amount),
    total_amount_excluding_tax: priceFromCentsToDollars(
      subscription.total_amount_excluding_tax,
    ),
    tax: priceFromCentsToDollars(subscription.tax),
    credit_balance: priceFromCentsToDollars(subscription.credit_balance),
    addons: transformAddons(subscription.addons),
    // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
    plan: transformPlan(subscription.plan, subscription.type),
    billing_cycle: transformBillingCycle(subscription.billing_cycle),
  };

  return transformedSubscription;
};

export const calculateTaxPercentage = (total: number, tax: number): number =>
  total === 0 ? 0 : Number(Math.round((tax / total) * 100));
