import React from 'react';
import { Badge, Flex, Icon, Text } from '@chakra-ui/react';
import { Button } from '@clerk/ceramic/experimental/components/Button';
import { EnvironmentType } from '@utils/environment';
import { devInstancesFirst } from '@utils/sort';
import { isHTTPError } from '@utils/errors';
import { stripProtocol } from '@utils/url';
import { FormProvider, useForm } from 'react-hook-form';
import { HomeUrlForm } from './HomeUrlForm';
import { useApplication } from '@hooks/useApplication';
import { useLocation } from '@hooks/useLocation';
import { useAnalytics } from '@hooks/useAnalytics';
import { useInstance } from '@hooks/useInstance';
import { BookOpenIcon } from '@heroicons/react/solid';
import { Application } from '@dapi/applications/types';
import {
  useCreateProductionInstance,
  useValidateCloning,
} from '@dapi/applications/mutations';
import { usePaymentRequired } from '@context/PaymentRequired/PaymentRequiredContext';
import { useRouter } from 'next/router';
import * as Dialog from '@clerk/ceramic/experimental/components/Dialog';
import { HelperLink } from '@components/common/HelperLink';
import { RadioButtonCard } from '@components/common/Radio/RadioButtonCard';
import { RadioGroup } from '@components/common/Radio/RadioGroup';
import { Instance } from '@dapi/instances/types';

const FORM_ID = 'create-prod-instance';

enum CreateProdInstanceSteps {
  SelectSetupMethod = 'select-setup-method',
  ConfigProdInstance = 'config-prod-instance',
}

const TEXTS = Object.freeze({
  [CreateProdInstanceSteps.SelectSetupMethod]: {
    subtitle: 'Select setup method',
    ctaSecondary: false,
    trackEvent:
      'Dashboard_Create Production Instance Modal_Cancel Button Clicked',
  },
  [CreateProdInstanceSteps.ConfigProdInstance]: {
    subtitle: '',
    ctaSecondary: 'Back',
    trackEvent:
      'Dashboard_Create Production Instance Modal_Back Button Clicked',
  },
});

export type ProdInstanceForm = {
  clone_instance_id: string;
  home_url: string;
};

interface CreateInstanceDialogProps {
  instances: Instance[];
  modalTrigger: JSX.Element;
}

const initialData: Partial<Application> = {
  instances: [],
};

export function CreateInstanceDialog({
  instances,
  modalTrigger,
}: CreateInstanceDialogProps) {
  const router = useRouter();
  const { data: application = initialData, mutate } = useApplication();
  const [step, setStep] = React.useState<CreateProdInstanceSteps>(
    CreateProdInstanceSteps.SelectSetupMethod,
  );
  const { applicationId } = useLocation();
  const { trigger: validateCloning } = useValidateCloning({
    // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
    id: applicationId,
  });
  const { trigger: createProductionInstance } = useCreateProductionInstance({
    // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
    id: application.id,
  });
  const { showModal } = usePaymentRequired();

  const [isUpdatingInstance, setIsUpdatingInstance] = React.useState(false);
  const { track } = useAnalytics();
  const { instance } = useInstance();

  React.useEffect(() => {
    void track(
      'Dashboard_Instance Overview_Create Production Instance Modal Viewed',
      {
        location: 'Instance Modal',
        surface: 'Dashboard',
      },
    );
  }, [track]);

  const nonProductionInstances = React.useMemo(
    () =>
      instances
        ? instances
            .filter(i => i.environment_type !== EnvironmentType.Production)
            .sort(devInstancesFirst)
        : [],
    [instances],
  );

  const cloneInstanceIdDefaultValue = nonProductionInstances[0]?.id;

  const formMethods = useForm<ProdInstanceForm>({
    defaultValues: {
      clone_instance_id: cloneInstanceIdDefaultValue,
    },
  });
  const {
    handleSubmit,
    formState: { isSubmitting },
    reset,
    control,
    setError,
    getValues,
  } = formMethods;

  const handleClose = () => {
    setStep(CreateProdInstanceSteps.SelectSetupMethod);
    reset();
  };

  const handleSecondaryClick = () => {
    track(TEXTS[step].trackEvent, {
      location: 'Instance Modal',
      surface: 'Dashboard',
    });

    if (step === CreateProdInstanceSteps.SelectSetupMethod) {
      handleClose();
    }
    setStep(CreateProdInstanceSteps.SelectSetupMethod);
    reset();
  };

  const nextStep = async e => {
    e.preventDefault();

    track(
      'Dashboard_Create Production Instance Modal_Continue Button Clicked',
      {
        location: 'Instance Modal',
        surface: 'Dashboard',
        setupMethod:
          instance?.environment_type !== EnvironmentType.Production
            ? `Clone ${instance?.environment_type} instance`
            : 'Create default instance',
      },
    );

    if (getValues('clone_instance_id')) {
      setIsUpdatingInstance(true);

      try {
        //TODO Is there any change that this returns an error?
        await validateCloning(
          {
            clone_instance_id: cloneInstanceIdDefaultValue,
          },
          {
            onError: errors => {
              if (isHTTPError(errors)) {
                if (errors.code === 402) {
                  const unsupportedFeatures = errors.getUnsupportedFeatures();
                  handleClose();
                  showModal({
                    features: unsupportedFeatures,
                    exceptionForUpgradeModal: true,
                  });
                  return;
                }
              }
            },
          },
        );

        setStep(CreateProdInstanceSteps.ConfigProdInstance);
      } catch (err) {
        return;
      } finally {
        setIsUpdatingInstance(false);
      }
    } else {
      setStep(CreateProdInstanceSteps.ConfigProdInstance);
    }
  };

  const handleSave = async (
    data: ProdInstanceForm,
  ): Promise<string | false> => {
    const { clone_instance_id, home_url } = data;

    const homeUrl = `https://${home_url}`;

    try {
      const instance = await createProductionInstance({
        // TODO: when implement integration fix the null hack(backend)
        // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
        clone_instance_id: clone_instance_id === '' ? null : clone_instance_id,
        home_url: homeUrl,
      });

      await mutate();

      if (instance) {
        await router.push(`/apps/${applicationId}/instances/${instance?.id}`);
      }
      return false;
    } catch (err) {
      if (!isHTTPError(err)) {
        throw err;
      }

      const fieldErrorName = err.getFieldErrorsNames()[0];
      const errorMessage =
        err.getFieldErrorByName(fieldErrorName)?.long_message;

      // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
      return errorMessage;
    }
  };

  const onCreateInstance = async ({
    home_url,
    clone_instance_id,
  }: ProdInstanceForm) => {
    const errorMessage = await handleSave({
      home_url: stripProtocol(home_url),
      clone_instance_id,
    });

    if (errorMessage) {
      setError('home_url', {
        type: 'manual',
        message: errorMessage,
      });
      return;
    }
    reset();
  };

  return (
    <Dialog.Root trigger={modalTrigger}>
      <Dialog.Header title='Create production instance'></Dialog.Header>
      <Dialog.Content>
        <form
          id={FORM_ID}
          onSubmit={handleSubmit(onCreateInstance)}
          className='p-4'
        >
          {step === CreateProdInstanceSteps.SelectSetupMethod ? (
            <RadioGroup name='clone_instance_id' control={control}>
              {nonProductionInstances.map(i => {
                return (
                  <RadioButtonCard
                    key={i.id}
                    value={i.id}
                    label={
                      <>
                        Clone {i.environment_type} instance{' '}
                        <Badge size='sm'>Easiest</Badge>
                      </>
                    }
                    hint={`Authentication and theme settings will be copied from the ${i.environment_type} instance. Usage of premium features will require a plan upgrade.`}
                  />
                );
              })}
              <RadioButtonCard
                value=''
                label={'Create default instance'}
                hint={'Use the default authentication and theme settings'}
              />
            </RadioGroup>
          ) : (
            <FormProvider {...formMethods}>
              <div className='mb-6 rounded-lg bg-[#F5F5F5] p-4'>
                <Flex gap={2}>
                  <Icon as={BookOpenIcon} boxSize={4} color='primary.500' />
                  <Text textStyle='sm-normal' color='legacyGray.600'>
                    Learn more about{' '}
                    <HelperLink href='https://clerk.com/docs/deployments/overview'>
                      deploying to production
                    </HelperLink>
                    .
                  </Text>
                </Flex>
              </div>
              <HomeUrlForm />
            </FormProvider>
          )}
        </form>
      </Dialog.Content>
      <Dialog.Footer>
        {TEXTS[step].ctaSecondary && (
          <Button intent='ghost' onClick={handleSecondaryClick}>
            {TEXTS[step].ctaSecondary}
          </Button>
        )}
        {step === CreateProdInstanceSteps.SelectSetupMethod ? (
          <Button
            intent='primary'
            onClick={nextStep}
            loading={isUpdatingInstance}
          >
            Continue
          </Button>
        ) : (
          <Button
            type='submit'
            intent='primary'
            form={FORM_ID}
            loading={isSubmitting}
            onClick={() => {
              track(
                'Dashboard_Create Production Instance Modal_Create Instance Button Clicked',
                {
                  location: 'Instance Modal',
                  surface: 'Dashboard',
                },
              );
            }}
          >
            Create Instance
          </Button>
        )}
      </Dialog.Footer>
    </Dialog.Root>
  );
}
