export function InformationCircleIconPath(props: { size?: 'sm' | 'base' }) {
  const { size = 'base' } = props;
  return (
    <>
      {size === 'base' ? (
        <>
          <path
            d='M10 2.75C14.0041 2.75 17.25 5.99594 17.25 10C17.25 14.0041 14.0041 17.25 10 17.25C5.99594 17.25 2.75 14.0041 2.75 10C2.75 5.99594 5.99594 2.75 10 2.75Z'
            fill='currentColor'
            fillOpacity={0.15}
          />
          <path d='M10 11V13' />
          <path d='M10 7.01V7' />
        </>
      ) : (
        <>
          <circle cx='8' cy='8' r='5.25' />
          <path d='M8 8.75V10.25' />
          <path d='M8 6.25024V6.26024' />
        </>
      )}
    </>
  );
}
