import { SegmentGraph } from '@clerk/ceramic/experimental/components/SegmentGraph';
import { Section } from './Section';
import { Number } from '@clerk/ceramic/experimental/components/Number';
import { priceFromCentsToDollars } from '@components/planAndBilling/utils';
import { Badge } from '@components/planAndBilling/Badge';
import { format, parseISO } from 'date-fns';
import { UpgradeMembershipLimitSection } from './UpgradeMembershipLimitSection';
import { OrganizationSubscriptionMembers } from '@dapi/currentOrganization/types';
import { DATE_FORMAT } from '@components/planAndBilling/constants';

export const MembersSection = ({
  members,
}: {
  members: OrganizationSubscriptionMembers;
}) => {
  const { usage, billing_cycle } = members;
  const { total_units, hard_limit, free_limit, amount_excluding_tax } = usage;

  return (
    <Section title='Members'>
      {members.type === 'paid' ? (
        <div className='flex justify-between'>
          <div className='w-7/12 space-y-1 sm:w-4/6 md:w-[72%]'>
            <span className='mb-2 block'>Total Members: {total_units}</span>

            <SegmentGraph
              freeAllowance={free_limit}
              totalUsage={total_units}
              totalLimit={hard_limit}
              calculation={`${total_units - 3} members x $10.00`}
            />
          </div>

          <div className='flex flex-col items-end justify-center gap-1'>
            <Number
              className='block text-right text-base/[1.125rem] font-semibold tracking-tighter text-legacyGray-800'
              prefix='$'
              aria-label='Total amount owed for additional dashboard seats.'
            >
              {priceFromCentsToDollars(amount_excluding_tax)}
            </Number>

            <Badge color='outlined'>
              Autopay on {format(parseISO(billing_cycle.end), DATE_FORMAT)}
            </Badge>
          </div>
        </div>
      ) : (
        <UpgradeMembershipLimitSection totalMembers={total_units} />
      )}
    </Section>
  );
};
