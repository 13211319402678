import { cx } from 'cva';

interface BadgeProps {
  children: React.ReactNode;
  color?:
    | 'spectrum'
    | 'spectrum-dark'
    | 'black'
    | 'legacyGray'
    | 'outlined'
    | 'danger'
    | 'skyblue'
    | 'purple';
}

export function Badge({ children, color = 'black' }: BadgeProps) {
  return (
    <span
      className={cx(
        'relative isolate inline-flex items-center justify-center gap-xs rounded-[5px] px-1.5 text-2xs font-medium leading-normal initial:text-white',
        {
          'bg-gradient-to-br from-sky-400 to-purple py-px text-purple before:absolute before:inset-px before:-z-10 before:rounded-sm before:bg-white':
            color === 'spectrum',
          'bg-gradient-to-br from-sky-400 to-purple py-px text-sky-300 before:absolute before:inset-px before:-z-10 before:rounded-sm before:bg-legacyGray-900':
            color === 'spectrum-dark',
          'bg-black py-0': color === 'black',
          'border border-legacyGray-100 bg-legacyGray-100 py-0 text-tertiary':
            color === 'legacyGray',
          'border border-sky-300 bg-sky-300 py-0 text-sky-950':
            color === 'skyblue',
          'border border-purple-500 bg-purple-500 py-0 text-purple-50':
            color === 'purple',
          'border border-legacyGray-100 bg-legacyGray-50 py-0 text-legacyGray-500 font-medium':
            color === 'outlined',
          'border border-legacy-red-100 bg-legacy-red-50 py-0 text-legacy-red-500':
            color === 'danger',
        },
      )}
    >
      {children}
    </span>
  );
}
