export const URLS_WITH_SIDE_NAV = [
  '/user-authentication/email-phone-username',
  '/user-authentication/social-connections',
  '/user-authentication/web3',
  '/user-authentication/enterprise-connections',
  '/user-authentication/multi-factor',
  '/user-authentication/restrictions',
  '/user-authentication/attack-protection',
  '/sso-connections',
  '/organizations-settings',
  '/sessions',
  '/account-portal',
  '/customization/avatars',
  '/customization/email',
  '/customization/sms',
  '/integrations',
  '/jwt-templates',
  '/webhooks',
  '/stripe-billing',
  '/ios-application',
  '/api-keys',
  '/paths',
  '/domains',
  '/plan-billing',
  '/settings',
];
