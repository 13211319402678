import { Number as NumberComp } from './Number';
import { cx } from 'cva';

// Copied verbatim from `apps/dashboard/utils/string.ts` to ease with initial
// isolation of `@clerk/ceramic` from `apps/dashboard`.
function pluralize(
  value: number | string,
  noun: string,
  showValue: boolean = true,
  suffix = 's',
): string {
  return showValue
    ? `${value} ${noun}${Number(value) !== 1 ? suffix : ''}`
    : `${noun}${Number(value) !== 1 ? suffix : ''}`;
}

interface SegmentGraphProps {
  totalLimit: number;
  freeAllowance: number;
  totalUsage: number;
  calculation?: string;
}

export function calculateUsagePercentage({
  totalLimit,
  freeAllowance,
  totalUsage,
}: SegmentGraphProps): number {
  if (totalLimit > 0) {
    return Math.min(1, totalUsage / totalLimit);
  }

  if (freeAllowance > 0) {
    if (totalUsage >= freeAllowance) {
      return freeAllowance / totalUsage;
    }
    return totalUsage / freeAllowance;
  }

  return 0;
}

export function SegmentGraph(props: SegmentGraphProps) {
  const { totalLimit, freeAllowance, totalUsage, calculation } = props;
  const percentageUsed = calculateUsagePercentage(props) * 100;

  return (
    <div className='max-w-2xl space-y-2'>
      <div
        className={cx(
          'isolate h-xs overflow-hidden rounded-full initial:bg-legacyGray-200',
          {
            'bg-purple': !totalLimit && totalUsage > freeAllowance,
          },
        )}
      >
        <div
          style={{ width: `${percentageUsed}%` }}
          className={cx(
            'relative h-full w-0 rounded-full before:absolute before:-right-xs before:-top-1/2 before:-z-10 before:h-[200%] before:w-1 before:rounded-full before:bg-white initial:bg-black',
            {
              'bg-yellow-400':
                totalLimit && percentageUsed >= 75 && percentageUsed < 90,
              'bg-legacy-red-500': totalLimit && percentageUsed >= 90,
            },
          )}
        />
      </div>

      {totalLimit ? (
        <div className='flex justify-between'>
          <div className='text-sm text-secondary'>
            <NumberComp className='font-semibold'>
              {Math.max(0, totalLimit - totalUsage)}
            </NumberComp>{' '}
            <span className='text-xs text-tertiary'>free remaining</span>
          </div>

          {totalUsage > totalLimit && (
            <div className='text-sm text-legacy-red-500'>
              <NumberComp className='font-semibold'>
                {totalUsage - totalLimit}
              </NumberComp>{' '}
              <span className='text-xs text-tertiary'>
                {pluralize(totalUsage - totalLimit, 'usage overage', false)}
              </span>
            </div>
          )}
        </div>
      ) : (
        <div className='flex justify-between'>
          <div className='text-sm text-secondary'>
            <NumberComp className='font-semibold'>
              {Math.min(totalUsage, freeAllowance)}
            </NumberComp>{' '}
            /{' '}
            <NumberComp className='font-medium text-tertiary'>
              {freeAllowance}
            </NumberComp>{' '}
            <span className='text-xs text-tertiary'>free</span>
          </div>

          {totalUsage > freeAllowance && (
            <div className='text-sm text-secondary'>
              {calculation ? (
                <span className='font-book text-secondary'>{calculation}</span>
              ) : (
                <>
                  <NumberComp className='font-semibold'>
                    {totalUsage - freeAllowance}
                  </NumberComp>{' '}
                  <span className='text-xs text-tertiary'>paid</span>
                </>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
