import React, { useEffect, useRef } from 'react';
import { stripProtocol } from '@utils/url';

export interface TLDData {
  domain: string;
  domainWithoutSuffix: string;
  hostname: string;
  isIcann: boolean;
  isIp: boolean;
  isPrivate: boolean;
  publicSuffix: string;
  subdomain: string;
  isETLD: boolean;
}

// More info at https://web.dev/same-site-same-origin/
function isDomainETLD(result: TLDData) {
  if (result.domain === null) {
    return false;
  }
  return result.domain?.length > 0 && result.domain === result.hostname;
}

// fallback to a custom URL parser if the CDN fails
function fallbackParser(url: string) {
  const parts = stripProtocol(url).split('.');

  if (parts.length > 2) {
    const [subdomain, ...domainParts] = parts;

    return {
      subdomain: subdomain || '',
      domain: domainParts.join('.'),
      isETLD: false,
    };
  }

  return {
    subdomain: '',
    domain: url,
    isETLD: true,
  };
}

export function useTLDParser(): { parse: (url: string) => Partial<TLDData> } {
  const tldParser = useRef(null);

  useEffect(() => {
    import(
      /* webpackIgnore: true */
      // @ts-ignore
      'https://unpkg.com/tldts/dist/es6/index.js?module'
    )
      .then(mod => {
        tldParser.current = mod;
      })
      .catch(() => {
        tldParser.current = null;
      });
  }, []);

  const parse = React.useCallback(function parse(
    url: string,
  ): Partial<TLDData> {
    url = stripProtocol(url);

    if (tldParser.current) {
      // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
      const result: TLDData = tldParser.current.parse(url, {
        allowPrivateDomains: true,
      });

      result.isETLD = isDomainETLD(result);
      result.subdomain = result.subdomain || '';

      return result;
    }

    return fallbackParser(url);
  }, []);

  return { parse };
}
