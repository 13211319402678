const LAST_SESSION_SIGN_UP = 'clerk-last-session-sign-up';
const LAST_SESSION_SIGN_IN = 'clerk-last-session-sign-in';

type UseSessionTracking = {
  hasSignedIn: (sessionId: string) => boolean;
  updateSignIn: (sessionId: string) => void;
  hasSignedUp: (sessionId: string) => boolean;
  updateSignUp: (sessionId: string) => void;
  clearTracking: () => void;
};

export const useSessionTracking = (): UseSessionTracking => {
  const hasSignedUp = (sessionId: string): boolean => {
    const lastTrackedSessionId = localStorage.getItem(LAST_SESSION_SIGN_UP);
    return sessionId === lastTrackedSessionId;
  };

  const updateSignUp = (sessionId: string): void => {
    localStorage.setItem(LAST_SESSION_SIGN_UP, sessionId);
  };

  const hasSignedIn = (sessionId: string): boolean => {
    const lastTrackedSessionId = localStorage.getItem(LAST_SESSION_SIGN_IN);
    return sessionId === lastTrackedSessionId;
  };

  const updateSignIn = (sessionId: string): void => {
    localStorage.setItem(LAST_SESSION_SIGN_IN, sessionId);
  };

  const clearTracking = (): void => {
    localStorage.removeItem(LAST_SESSION_SIGN_UP);
    localStorage.removeItem(LAST_SESSION_SIGN_IN);
  };

  return {
    hasSignedIn,
    updateSignIn,
    hasSignedUp,
    updateSignUp,
    clearTracking,
  };
};
