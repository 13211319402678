export const BACKEND_API =
  typeof window !== 'undefined' &&
  `api.${window.location.host.split('.').slice(-2).join('.')}`;

export function applicationInstancePath({
  applicationId,
  instanceId,
  postfix,
}: {
  applicationId: string;
  instanceId: string;
  postfix?: string;
}): string {
  const instancePath = `/apps/${applicationId}/instances/${instanceId}`;

  if (postfix) {
    return instancePath + postfix;
  }

  return instancePath;
}

export function billingPath(applicationId: string, instanceId: string): string {
  return `/apps/${applicationId}/instances/${instanceId}/account/plan-billing`;
}
