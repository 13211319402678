import { DELETE, GET, PATCH, POST } from '@utils/api';

import {
  Application,
  CreateApplicationParams,
  CreateProductionInstance,
  ValidateCloning,
  TransferToOrganizationParams,
} from './types';
import { Instance } from '@dapi/instances/types';

export const getApplication = ({
  path,
  token,
}: {
  path: string;
  token: string;
}): Promise<Application> => {
  return GET<Application>({ path, token });
};

export const listApplications = ({
  path,
  token,
}: {
  path: string;
  token: string;
}): Promise<Application[]> => {
  return GET<Application[]>({ path, token });
};

export const updateApplication = ({
  path,
  token,
  data,
}: {
  path: string;
  token: string;
  data: Partial<Application>;
}): Promise<Application> => {
  return PATCH<Application>({ path, token, data });
};

export const deleteApplication = ({
  path,
  token,
}: {
  path: string;
  token: string;
}): Promise<never> => {
  return DELETE<never>({ path, token, data: {} });
};

export const transferToOrganization = ({
  path,
  token,
  data,
}: {
  path: string;
  token: string;
  data: TransferToOrganizationParams;
}): Promise<never> => {
  return POST<never>({ path, token, data });
};

export const transferToPersonalWorkspace = ({
  path,
  token,
}: {
  path: string;
  token: string;
}): Promise<never> => {
  return POST<never>({ path, token, data: {} });
};

export const uploadImageResource = ({
  path,
  token,
  data,
}: {
  path: string;
  token: string;
  data: FormData;
}): Promise<never> => {
  return POST<never>({ path, token, data });
};

export const createApplication = ({
  path,
  token,
  data,
}: {
  path: string;
  token: string;
  data: Partial<CreateApplicationParams>;
}): Promise<Application> => {
  return POST<Application>({ path, token, data });
};

export const deleteLogo = ({
  path,
  token,
}: {
  path: string;
  token: string;
}): Promise<never> => {
  return DELETE<never>({ path, token, data: {} });
};

export const deleteFavicon = ({
  path,
  token,
}: {
  path: string;
  token: string;
}): Promise<never> => {
  return DELETE<never>({ path, token, data: {} });
};

export const listInstances = ({
  path,
  token,
}: {
  path: string;
  token: string;
}): Promise<Instance[]> => {
  return GET<Instance[]>({ path, token });
};

export const createProductionInstance = ({
  path,
  token,
  data,
}: {
  path: string;
  token: string;
  data: CreateProductionInstance;
}): Promise<Instance> => {
  return POST<Instance>({ path, token, data });
};

export const validateCloning = ({
  path,
  token,
  data,
}: {
  path: string;
  token: string;
  data: ValidateCloning;
}): Promise<never> => {
  return POST<never>({ path, token, data });
};
