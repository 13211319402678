import { selectAnatomy as parts } from '@chakra-ui/anatomy';
import type { PartsStyleObject } from '@chakra-ui/theme-tools';
import { Input } from './input';

const baseStyle: PartsStyleObject<typeof parts> = {
  field: { ...Input.baseStyle.field },
};

const sizes: Record<string, PartsStyleObject<typeof parts>> = {
  md: {
    field: { ...Input.sizes.md.field },
  },
};

export const Select = {
  parts: parts.keys,
  baseStyle,
  defaultProps: Input.defaultProps,
  sizes,
};
