export const setCookie = (name: string, value: string, days: number) => {
  const expirationDate = new Date();
  expirationDate.setDate(expirationDate.getDate() + days);
  document.cookie = `${name}=${value}; expires=${expirationDate.toUTCString()}; path=/`;
};

export const getCookie = (name: string) => {
  const cookies = document.cookie
    .split('; ')
    .find(row => row.startsWith(`${name}=`));
  return cookies ? cookies.split('=')[1] : null;
};
