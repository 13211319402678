export function ExclamationTriangleIconPath(props: { size?: 'sm' | 'base' }) {
  const { size = 'base' } = props;
  return (
    <>
      {size === 'base' ? (
        <>
          <path
            d='M7.75095 4.14062L3.01871 13.6093C2.18276 15.282 3.39851 17.25 5.26776 17.25H14.7322C16.6015 17.25 17.8172 15.282 16.9813 13.6093L12.2491 4.14061C11.3224 2.28646 8.67761 2.28646 7.75095 4.14062Z'
            fill='currentColor'
            fillOpacity={0.15}
          />
          <path d='M10 7.78497V10.25' />
          <path d='M10 13V13.25' />
        </>
      ) : (
        <>
          <path d='M8 6.75V8.25' />
          <path d='M8 10.5V10.51' />
          <path d='M2.95652 10.4518L6.27146 3.8188C6.98366 2.39373 9.01635 2.39373 9.72855 3.81881L13.0435 10.4518C13.686 11.7374 12.7516 13.25 11.3149 13.25H4.68506C3.24842 13.25 2.31404 11.7374 2.95652 10.4518Z' />
        </>
      )}
    </>
  );
}
