import { buildRestPath } from '@utils/api';
import { BASE_PATH } from './constants';

type BuildCurrentOrganizationsPath = {
  resourceId?: string;
  action?: string;
};

export function buildCurrentOrganizationPath({
  resourceId,
  action,
}: BuildCurrentOrganizationsPath = {}): string {
  return buildRestPath({
    basePath: BASE_PATH,
    resourceId,
    action,
  });
}
