import { useMutation } from '@hooks/useMutation';

import {
  refreshPaymentStatus,
  organizationCheckoutSession,
  customerPortalSession,
  completeSubscription,
  checkoutSubscription,
} from './api';

import { APIMutationHook } from '@typeDefs/API';
import {
  CreateCheckoutSessionParams,
  CreateCheckoutSessionResponse,
  RefreshPaymentStatusResponse,
  OrganizationRefreshPaymentStatusParams,
  CreateCustomerPortalSessionResponse,
  CreateCustomerPortalSessionParams,
} from './types';

import { buildCurrentOrganizationPath } from './utils';
import {
  CheckoutSubscriptionParams,
  CheckoutSubscriptionResponse,
} from '@dapi/products/types';

type UseRefreshPaymentStatusProps = {
  id: string;
};

export const useOrganizationRefreshPaymentStatus: APIMutationHook<
  RefreshPaymentStatusResponse,
  OrganizationRefreshPaymentStatusParams,
  UseRefreshPaymentStatusProps
> = params => {
  const { id } = params;
  const path = buildCurrentOrganizationPath({
    resourceId: id,
    action: 'refresh_payment_status',
  });

  return useMutation({ path }, refreshPaymentStatus, { pathPrefix: 'v1' });
};

type UseCheckoutPortalSessionProps = {
  id: string;
  planId: string;
};

export const useOrganizationCheckoutPortalSession: APIMutationHook<
  CreateCheckoutSessionResponse,
  CreateCheckoutSessionParams,
  UseCheckoutPortalSessionProps
> = params => {
  const { id, planId } = params;
  const path = buildCurrentOrganizationPath({
    resourceId: id,
    action: `checkout/${planId}/session`,
  });

  return useMutation({ path }, organizationCheckoutSession, {
    pathPrefix: 'v1',
  });
};

type UseCustomerPortalSessionProps = {
  id: string;
};

export const useOrganizationCustomerPortalSession: APIMutationHook<
  CreateCustomerPortalSessionResponse,
  CreateCustomerPortalSessionParams,
  UseCustomerPortalSessionProps
> = params => {
  const { id } = params;
  const path = buildCurrentOrganizationPath({
    resourceId: id,
    action: 'customer_portal_session',
  });

  return useMutation({ path }, customerPortalSession, {
    pathPrefix: 'v1',
  });
};

export const useOrganizationCheckoutSubscription: APIMutationHook<
  CheckoutSubscriptionResponse,
  CheckoutSubscriptionParams,
  UseCustomerPortalSessionProps
> = params => {
  const { id } = params;

  const path = buildCurrentOrganizationPath({
    resourceId: id,
    action: 'checkout_subscription',
  });

  return useMutation({ path }, checkoutSubscription, {
    pathPrefix: 'v1',
  });
};

export const useOrganizationCompleteSubscription: APIMutationHook<
  {
    subscription_id: string;
    payment_status: string;
  },
  never,
  UseCustomerPortalSessionProps
> = params => {
  const { id } = params;
  const path = buildCurrentOrganizationPath({
    resourceId: id,
    action: 'complete_subscription',
  });

  return useMutation({ path }, completeSubscription, {
    pathPrefix: 'v1',
  });
};
