export function EllipsisHorizontalIconPath(props: { size?: 'sm' | 'base' }) {
  const { size = 'base' } = props;
  return (
    <>
      {size === 'base' ? (
        <>
          <path d='M6 10V10.01M10 10V10.01M14 10V10.01' />
        </>
      ) : (
        <>
          <path d='M4 8V8.01' />
          <path d='M8 8V8.01' />
          <path d='M12 8V8.01' />
        </>
      )}
    </>
  );
}
