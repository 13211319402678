export const semanticTokens = {
  colors: {
    borders: {
      base: {
        default: 'legacyGray.200',
      },
      light: {
        default: 'legacyGray.100',
      },
      invalid: {
        default: 'danger.500',
      },
    },
    text: {
      textArea: {
        _readOnly: 'blackAlpha.700',
      },
      addOn: {
        default: 'blackAlpha.700',
      },
      code: {
        envVar: 'info.400',
      },
    },
  },
  shadows: {
    dropdown: {
      default: '0px 24px 48px var(--chakra-colors-blackAlpha-400)',
    },
    card: {
      default: '0px 4px 4px var(--chakra-colors-blackAlpha-100)',
    },
    ring: {
      default: 'inset 0px 0px 0px 1px var(--chakra-colors-blackAlpha-400)',
    },
    circular: {
      default:
        '0px 0px 0px 4px #F5F5F5, 0px 1px 2px 0px rgba(20, 20, 20, 0.05)',
    },
    invalid: {
      default: '0 0 0 1px var(--chakra-colors-borders-invalid)',
    },
  },
};

export type SemanticTokens = typeof semanticTokens;
