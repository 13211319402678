import {
  ComponentDefaultProps,
  ComponentStyleConfig,
  SystemStyleObject,
} from '@chakra-ui/react';

const baseStyle: SystemStyleObject = {
  color: 'legacyGray.500',
};

const defaultProps: ComponentDefaultProps = {
  size: 'xl',
};

const variants: SystemStyleObject = {
  white: {
    color: 'white',
  },
  blue: {
    color: 'primary.500',
  },
};

export const Spinner: ComponentStyleConfig = {
  baseStyle,
  defaultProps,
  variants,
};
