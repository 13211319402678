export function BoxIconPath(props: { size?: 'sm' | 'base' }) {
  const { size = 'base' } = props;
  return (
    <>
      {size === 'base' ? (
        <>
          <path
            d='M10 17.25L3.35608 14.4026C2.9884 14.245 2.75 13.8835 2.75 13.4835V5.85714L9.60608 2.91882C9.85763 2.81102 10.1424 2.81102 10.3939 2.91882L17.25 5.85714V13.4835C17.25 13.8835 17.0116 14.245 16.6439 14.4026L10 17.25Z'
            fill='currentColor'
            fillOpacity={0.15}
          />
          <path d='M2.75 5.85714V13.4835C2.75 13.8835 2.9884 14.245 3.35608 14.4026L10 17.25M2.75 5.85714L9.60608 2.91882C9.85763 2.81102 10.1424 2.81102 10.3939 2.91882L17.25 5.85714M2.75 5.85714L10 9M10 17.25L16.6439 14.4026C17.0116 14.245 17.25 13.8835 17.25 13.4835V5.85714M10 17.25V9M17.25 5.85714L10 9' />
        </>
      ) : (
        <>
          <path d='M2.75 5.14062L8 2.75L13.25 5M2.75 5.14062L8 7.25M2.75 5.14062V11.1406L8 13.25M8 7.25L13.25 5M8 7.25V13.25M13.25 5V11L8 13.25' />
        </>
      )}
    </>
  );
}
