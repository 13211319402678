import type {
  ComponentDefaultProps,
  ComponentStyleConfig,
  SystemStyleObject,
} from '@chakra-ui/react';
import { shadows } from '@theme/foundations/shadows';

const baseStyle: SystemStyleObject = {
  fontWeight: '500',
  py: 0,
  px: 3,
  _focus: {
    boxShadow: shadows.focusRing,
  },
};

const sizes = {
  sm: {
    fontSize: 'xxs',
  },
  md: {
    fontSize: 'xs',
    height: 8,
    px: 3,
    fontWeight: '500',
  },
  lg: {
    fontSize: 'sm',
  },
};

const defaultProps: ComponentDefaultProps = {
  size: 'md',
};

const variants: SystemStyleObject = {
  link: {
    p: 0,
    _focus: {
      boxShadow: 'none',
    },
    _hover: {
      textDecoration: 'none',
      color: 'primary.700',
    },
    _focusVisible: {
      padding: 0,
      boxShadow: '0 0 0 1px var(--chakra-colors-primary-500)',
    },
  },

  legacyGray: {
    fontSize: 'xs',
    fontWeight: '500',
    letterSpacing: 'normal',
    p: 3,

    _hover: {
      bg: 'legacyGray.50',
    },
    _focus: {
      boxShadow: 'none',
    },
  },

  select: {
    width: '100%',
    border: '1px solid rgba(0, 0, 0, 0.16)',
    borderRadius: 'md',
    color: '#000000',
    _active: {
      border: '1px solid rgba(0, 0, 0, 0.16)',
    },
    _focus: {
      border: '1px solid rgba(0, 0, 0, 0.16)',
      boxShadow: 'none',
    },
    _hover: { bg: 'rgba(0, 0, 0, 0.04)' },
  },

  selectGhost: {
    borderRadius: 'md',
    color: 'rgba(0, 0, 0, 0.65)',
    _active: { border: 'none', boxShadow: 'none' },
    _focus: { border: 'none', boxShadow: 'none' },
    _hover: { bg: 'rgba(0, 0, 0, 0.04)' },
  },

  icon: {
    color: 'legacyGray.300',
    _focus: { bg: 'none' },
    _hover: { bg: 'none' },
  },
};

export const Button: ComponentStyleConfig = {
  baseStyle,
  sizes,
  defaultProps,
  variants,
};
