import type { SystemStyleFunction } from '@chakra-ui/theme-tools';
import { cssVar, mode } from '@chakra-ui/theme-tools';
import { textStyles } from '../foundations/textStyles';
import { ComponentStyleConfig, StyleFunctionProps } from '@chakra-ui/react';

const $bg = cssVar('tooltip-bg');

const baseStyle: SystemStyleFunction = props => {
  const bg = mode('legacyGray.700', 'legacyGray.300')(props);
  return {
    [$bg.variable]: `colors.${bg}`,
    color: mode('whiteAlpha.900', 'legacyGray.900')(props),
    borderRadius: 'base',
    ...textStyles['sm-medium'],
    boxShadow: 'md',
  };
};

const defaultProps = {
  gutter: 6,
};

const variants = {
  darker: (props: StyleFunctionProps) => {
    const bg = mode('legacyGray.900', 'legacyGray.300')(props);

    return {
      [$bg.variable]: `colors.${bg}`,
      color: mode('white', 'legacyGray.900')(props),
      p: 3,
      rounded: 'md',
    };
  },
};

export const Tooltip: ComponentStyleConfig = {
  baseStyle,
  defaultProps,
  variants,
};
