/*
 * This provider offers a solution to the issue with the state of the Billing custom profile page,
 * which gets reset each time the clerk context changes. This occurs due to the remounting of clerkjs
 * custom pages whenever there is a context switch. To replicate this issue, simply open the organization
 * billing tab, select an application, and then switch browser tabs.
 */

import { createContext, useContext, useEffect, useState } from 'react';
import { ApplicationSubscription } from '@dapi/currentOrganization/types';

type BillingContextProps = {
  selectedApplication: ApplicationSubscription;
  setSelectedApplication: (application: ApplicationSubscription) => void;
};
// @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
const BillingContext = createContext<BillingContextProps>(null);

export const useBilling = () => {
  const context = useContext(BillingContext);
  if (!context) {
    throw new Error('useCurrentBilling must be used within a BillingProvider');
  }
  return context;
};

export const BillingProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [selectedApplication, _setSelectedApplication] =
    // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
    useState<ApplicationSubscription>(null);

  useEffect(() => {
    /**
     * Due to the provider we are using to store the selected application,
     * the selection is never cleared when switching tabs.
     * Consequently, if we have a selected application, navigate to another route,
     * and return to the billing route, we will still see the previously selected application.
     * With this code, we are ensuring that the selected application is cleaned
     * every time we enter the billing route.
     */
    const onHashChange = () => {
      const hash = decodeURIComponent(window.location.hash.replace('#', ''));

      if (hash === '/billing') {
        if (selectedApplication) {
          // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
          _setSelectedApplication(null);
        }
      }
    };

    window.addEventListener('hashchange', onHashChange);
    return () => {
      window.removeEventListener('hashchange', onHashChange);
    };
  }, [selectedApplication]);

  const setSelectedApplication = (application: ApplicationSubscription) => {
    _setSelectedApplication(application);
  };
  return (
    <BillingContext.Provider
      value={{ selectedApplication, setSelectedApplication }}
    >
      {children}
    </BillingContext.Provider>
  );
};
