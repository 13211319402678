import React from 'react';
import { Skeleton } from '@chakra-ui/react';

const InputPreviewSkeleton = () => {
  return (
    <div className='flex flex-col gap-2'>
      <Skeleton borderRadius='md' height={10} />
    </div>
  );
};

export default InputPreviewSkeleton;
