import { Button } from '@clerk/ceramic/experimental/components/Button';
import { useFetchApplications } from '@dapi/applications/queries';
import { isProduction } from '@utils/environment';
import { applicationInstancePath } from '@utils/routes';

import Link from 'next/link';
import { memo } from 'react';

export const ManagePlan = memo(
  ({ applicationId }: { applicationId: string }) => {
    const { data, isLoading: isLoadingApplications } = useFetchApplications();

    const application = data?.find(({ id }) => id === applicationId);
    const instance =
      application?.instances.find(i => isProduction(i.environment_type)) ||
      application?.instances[0];

    const planAndBillingUrl = `${applicationInstancePath({
      // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
      instanceId: instance?.id,
      // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
      applicationId: application?.id,
    })}/plan-billing`;

    if (isLoadingApplications) {
      return null;
    }

    return (
      <div className='self-end'>
        {/* TODO: Need this icon redrawn using stroke rather than fill will be added to icon spritesheet */}
        <Link
          aria-label='Manage plan external link'
          href={planAndBillingUrl}
          target='_blank'
        >
          <Button intent='secondary' icon='external-link-legacy'>
            Manage plan
          </Button>
        </Link>
      </div>
    );
  },
);
ManagePlan.displayName = 'ManagePlan';
