import { ApplicationSubscription } from '@components/planAndBilling/organization/ApplicationSubscription';
import { Tooltip } from '@components/planAndBilling/organization/Tooltip';
import { format, parseISO } from 'date-fns';
import { Section } from '@components/planAndBilling/organization/Section';
import { Number } from '@clerk/ceramic/experimental/components/Number';
import { priceFromCentsToDollars } from '@components/planAndBilling/utils';
import { Icon } from '@clerk/ceramic/experimental/components/Icon';
import { useFetchCurrentOrganizationSubscription } from '@dapi/currentOrganization/queries';
import {
  OrganizationCurrentSubscriptionResponse,
  ApplicationSubscription as TApplicationSubscription,
} from '@dapi/currentOrganization/types';
import { useOrganization } from '@clerk/nextjs';
import { MembersSection } from './MembersSection';
import { ApplicationsSection } from './ApplicationsSection';
import { useBilling } from '@components/planAndBilling/organization/BillingProvider';
import { ManageBillingInfo } from './ManageBillingInfo';
import Balancer from 'react-wrap-balancer';
import { motion } from 'framer-motion';
import { Button } from '@clerk/ceramic/experimental/components/Button';
import { Badge } from '@components/planAndBilling/Badge';
import { cx } from 'cva';
import { calculateTaxPercentage } from '@components/planAndBilling/currentSubscription/utils';

export function Billing() {
  const { selectedApplication, setSelectedApplication } = useBilling();

  const { organization } = useOrganization();

  const { data: currentOrganizationSubscription, isLoading } =
    useFetchCurrentOrganizationSubscription({
      // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
      id: organization?.id,
    });

  const onSelectApplication = (application: TApplicationSubscription) => {
    setSelectedApplication(application);
  };

  const clearSelectedApplication = () => {
    // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
    setSelectedApplication(null);
  };

  return (
    <div>
      {isLoading ? (
        <BillingOverviewSkeleton />
      ) : selectedApplication === null ? (
        <BillingOverview
          // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
          currentOrganizationSubscription={currentOrganizationSubscription}
          onSelectApplication={onSelectApplication}
        />
      ) : (
        <ApplicationSubscription
          clearSelectedApplication={clearSelectedApplication}
          application={selectedApplication}
        />
      )}
    </div>
  );
}

interface BillingOverviewProps {
  currentOrganizationSubscription: OrganizationCurrentSubscriptionResponse;
  onSelectApplication: (application: TApplicationSubscription) => void;
}

const BillingOverview = ({
  currentOrganizationSubscription,
  onSelectApplication,
}: BillingOverviewProps) => {
  const amounts = currentOrganizationSubscription?.applications.reduce(
    (acc, app) => {
      acc.total += app.total_amount;
      acc.totalExcludingTax += app.total_amount_excluding_tax;
      acc.tax += app.tax;
      return acc;
    },
    {
      total: currentOrganizationSubscription?.members.usage.amount_due || 0,
      totalExcludingTax:
        currentOrganizationSubscription?.members.usage.amount_excluding_tax ||
        0,
      tax: currentOrganizationSubscription?.members.usage.tax || 0,
    },
  );

  const amountDueAfterCredit = Math.max(
    amounts?.total - currentOrganizationSubscription.total_credit,
    0,
  );

  const creditApplied = Math.min(
    currentOrganizationSubscription.total_credit,
    amounts?.total,
  );

  return (
    <div className='space-y-10'>
      <Section title='Billing details'>
        <div className='space-y-1'>
          <div className='flex justify-between'>
            <Tooltip
              content={
                <Balancer>
                  <span className='text-legacy-orange-500'>
                    * This billing amount is not final;
                  </span>{' '}
                  rather, it is the total sum of current active plans, add-ons,
                  and metered services. This amount may vary based on usage and
                  may not all have the same billing cycle.
                </Balancer>
              }
            >
              <span className='inline-flex items-center gap-1 mt-2'>
                <span className='font-book text-secondary'>
                  {format(
                    parseISO(
                      currentOrganizationSubscription.members.billing_cycle
                        .start,
                    ),
                    'MMMM yyyy',
                  )}
                </span>
                <span className='text-tertiary'>
                  <Icon name='information-square-legacy' size='sm' />
                </span>
              </span>
            </Tooltip>
            <ManageBillingInfo />
          </div>

          <div className='flex justify-between'>
            <div>
              <Number
                className='text-2xl font-medium'
                prefix='$'
                aria-label='Total amount owed across all apps.'
              >
                {priceFromCentsToDollars(amountDueAfterCredit)}
              </Number>
              <span className='text-2xl text-tertiary'> * </span>
            </div>
          </div>

          {creditApplied > 0 && (
            <span className='text-legacyGray-500'>
              (
              <Number
                prefix='$'
                className='font-medium'
                aria-label='Total amount of credit applied to the current billing cycle.'
              >
                {priceFromCentsToDollars(creditApplied)}
              </Number>{' '}
              applied credit)
            </span>
          )}

          {amounts?.tax > 0 && (
            <span className='text-book font-medium text-tertiary'>
              <Number prefix='$'>
                {priceFromCentsToDollars(amounts?.totalExcludingTax)}
              </Number>
              {' + '}
              <Number suffix='%'>
                {calculateTaxPercentage(
                  amounts?.totalExcludingTax,
                  amounts?.tax,
                )}
              </Number>
              {' VAT'}
            </span>
          )}
        </div>
      </Section>

      <MembersSection members={currentOrganizationSubscription.members} />

      <ApplicationsSection
        onSelectApplication={onSelectApplication}
        applications={currentOrganizationSubscription.applications}
      />
    </div>
  );
};

const BillingOverviewSkeleton = () => {
  const SKELETON_DATA = {
    amountDue: '$375.00',
    amountDueAfterCredit: '$0.00',
    creditApplied: 0,
    totalMembers: 2,
    billingCycle: {
      start: 'January 2024',
      end: '2021-01-31',
    },
    applications: [
      {
        name: 'Never gonna',
        total_amount: '$0.00',
        plan: {
          id: 'free',
          name: 'Free',
        },
        addons: [],
        billing_cycle: {
          end: '',
        },
      },
      {
        name: 'Give you up',
        total_amount: '$25.00',
        plan: {
          id: 'pro',
          name: 'Pro',
        },
        addons: [],
        billing_cycle: {
          end: 'Feb 29, 2024',
        },
      },
      {
        name: 'Never gonna',
        total_amount: '$125.00',
        plan: {
          id: 'pro',
          name: 'Pro',
        },
        addons: [1],
        billing_cycle: {
          end: 'Feb 29, 2024',
        },
      },
      {
        name: 'Let you down',
        total_amount: '$225.00',
        plan: {
          id: 'pro',
          name: 'Pro',
        },
        addons: [1, 2],
        billing_cycle: {
          end: 'Feb 29, 2024',
        },
      },
    ],
  };

  return (
    <div aria-label='Loading billing overview' className='isolate'>
      <div
        aria-hidden
        className={cx(
          'relative',
          'before:absolute before:inset-0 before:z-40 before:-m-8 before:backdrop-blur-[6px]',
        )}
      >
        <motion.div
          className='absolute inset-0 z-50 -m-8 bg-white'
          aria-hidden
          animate={{
            opacity: [0, 0.5],
          }}
          transition={{
            ease: 'easeOut',
            duration: 1,
            repeat: Infinity,
            repeatType: 'mirror',
          }}
        />

        <div className='pointer-events-none space-y-10'>
          <Section
            title='Billing details'
            actionComponent={
              <Button intent='secondary' icon='external-link-legacy' size='sm'>
                Manage billing info
              </Button>
            }
          >
            <div className='space-y-1'>
              <span className='text-2xl font-medium'>
                {SKELETON_DATA.amountDue}
              </span>
              <span className='text-2xl text-tertiary'> * </span>
              <span className='flex items-center gap-1'>
                <span className='text-secondary'>
                  {SKELETON_DATA.billingCycle.start}
                </span>
                <span className='text-tertiary'>
                  <Icon name='information-square-legacy' size='sm' />
                </span>
              </span>
            </div>
          </Section>

          <Section title='Members'>
            <div className='flex items-center gap-4 rounded-lg bg-legacyGray-50 px-4 py-3'>
              <div className='relative aspect-square h-6 rounded-full bg-legacyGray-300'>
                <svg
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                  className='absolute inset-0 h-full w-full -rotate-90 stroke-legacyGray-800'
                >
                  <circle
                    cx='12'
                    cy='12'
                    r='10'
                    strokeWidth='4'
                    strokeDasharray={62.8}
                    strokeDashoffset={20.9333333333}
                    strokeLinecap='round'
                  />
                </svg>

                <span className='absolute inset-1 inline-flex items-center justify-center rounded-full bg-white text-xs font-medium text-legacyGray-700'>
                  2
                </span>
              </div>
              <span className='text-legacyGray-700'>
                You can invite up to 3 members to access the app.{' '}
                <span className='inline-flex items-center font-medium text-legacyGray-950'>
                  Upgrade to unlimited members&nbsp;
                  <Icon name='arrow-right-legacy' size='sm' />
                </span>
              </span>
            </div>
          </Section>

          <Section title='Applications'>
            <div
              className={cx(
                'relative',
                'before:absolute before:-inset-x-px before:-bottom-px before:top-[calc(theme(fontSize.base[1].lineHeight)+theme(spacing.2))] before:-z-1 before:rounded-lg before:border before:border-black/[0.07] before:bg-white before:bg-clip-padding before:shadow-[0_1px_0_-1px,0_1px_1px] before:shadow-black/3 before:transition-transform before:duration-200 before:ease-in-out',
              )}
            >
              <table className='w-full border-separate border-spacing-0'>
                <thead className='[&_th]:px-4 [&_th]:pb-2'>
                  <tr>
                    <th className='text-left font-normal text-legacyGray-500'>
                      Name
                    </th>
                    <th className='text-left font-normal text-legacyGray-500'>
                      Next bill date
                    </th>
                    <th className='text-right font-normal text-legacyGray-500'>
                      <span className='inline-flex items-center gap-1'>
                        <span>Amount</span>
                        <span className='text-tertiary'>
                          <Icon name='information-square-legacy' size='sm' />
                        </span>
                      </span>
                    </th>
                  </tr>
                </thead>

                <tbody className='first:[&>td]:first:[&>tr]:rounded-tl-lg last:[&>td]:last:[&>tr]:rounded-br-lg first:[&>td]:last:[&>tr]:rounded-bl-lg last:[&>td]:first:[&>tr]:rounded-tr-lg'>
                  {SKELETON_DATA.applications.map((application, index) => (
                    <tr
                      key={index}
                      className='group isolate hover:cursor-pointer [&>td]:border-b [&>td]:border-b-legacyGray-100 [&>td]:px-4 [&>td]:py-2.5 [&>td]:last:border-none'
                    >
                      <td className='group-hover:bg-legacyGray-25'>
                        <div className='inline-flex items-center gap-2 text-base/[1.125rem] font-medium text-legacyGray-950'>
                          <div className='relative aspect-square h-2.5 rounded-full bg-purple-500 before:absolute before:inset-0 before:rounded-full before:border before:border-black/12' />
                          {application.name}
                          <div className='hidden items-center gap-2 sm:flex'>
                            <Badge
                              color={
                                application.plan.id !== 'free'
                                  ? 'skyblue'
                                  : 'legacyGray'
                              }
                            >
                              {application.plan.name}
                            </Badge>
                            {application.addons.length > 0 && (
                              <Badge color='spectrum'>{`+ ${application.addons.length} add-ons`}</Badge>
                            )}
                          </div>
                        </div>
                      </td>
                      <td className='text-left text-secondary group-hover:bg-legacyGray-25'>
                        {application.plan.id === 'free'
                          ? '-'
                          : application.billing_cycle.end}
                      </td>
                      <td className='text-right group-hover:bg-legacyGray-25'>
                        <span className='text-secondary'>
                          {application.total_amount}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Section>
        </div>
      </div>
    </div>
  );
};
