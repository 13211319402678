import type {
  ComponentDefaultProps,
  ComponentStyleConfig,
  SystemStyleObject,
} from '@chakra-ui/react';

const baseStyle: SystemStyleObject = {
  textTransform: 'none',
  borderRadius: 'base',
  fontWeight: '400',
};

const sizes = {
  sm: {
    py: '1',
    px: '1.5',
    fontSize: 'xxs',
    lineHeight: 'xxs',
  },
  md: {
    py: '0.5',
    px: '1.5',
    fontSize: 'xs',
    lineHeight: 'xs',
  },
};

const defaultProps: ComponentDefaultProps = {
  variant: 'subtle',
  size: 'md',
};

const variants: SystemStyleObject = {
  blue: {
    bg: 'primary.100',
    color: 'primary.500',
  },
  success: {
    bg: 'success.50',
    color: 'success.500',
  },
  danger: {
    bg: 'danger.100',
    color: 'danger.500',
  },
  validating: {
    bg: 'blackAlpha.50',
    color: 'blackAlpha.700',
  },
  invalid: {
    bg: 'danger.100',
    color: 'danger.500',
  },
  valid: {
    bg: 'green.100',
    color: 'blackAlpha.800',
  },
};

export const Badge: ComponentStyleConfig = {
  baseStyle,
  sizes,
  defaultProps,
  variants,
};
