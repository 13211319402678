export function MoonIconPath(props: { size?: 'sm' | 'base' }) {
  const { size = 'base' } = props;
  return (
    <>
      {size === 'base' ? (
        <>
          <path
            d='M17.5 11.1878C16.8118 11.4422 16.0676 11.5811 15.2909 11.5811C11.7719 11.5811 8.91915 8.72839 8.91915 5.20938C8.91915 4.43262 9.05814 3.68832 9.31261 3C5.74916 3.474 3 6.52496 3 10.218C3 14.2397 6.26026 17.5 10.282 17.5C13.975 17.5 17.0258 14.751 17.5 11.1878Z'
            className='fill-[--icon-fill,currentColor]'
            fillOpacity={0.15}
            strokeWidth='1.875'
          />
        </>
      ) : (
        <>
          <path
            d='M13.25 8.67908C12.7516 8.86331 12.2127 8.96393 11.6503 8.96393C9.10205 8.96393 7.03628 6.89816 7.03628 4.3499C7.03628 3.78742 7.13693 3.24844 7.3212 2.75C4.74077 3.09325 2.75 5.30257 2.75 7.97686C2.75 10.8892 5.11088 13.25 8.02317 13.25C10.6974 13.25 12.9066 11.2594 13.25 8.67908Z'
            strokeWidth='1.5'
          />
        </>
      )}
    </>
  );
}
