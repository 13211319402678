export function FunnelIconPath(props: { size?: 'sm' | 'base' }) {
  const { size = 'base' } = props;
  return (
    <>
      {size === 'base' ? (
        <>
          <path
            d='M7.75 10L2.75 2.75H17.25L12.25 10V16.25C12.25 16.8023 11.8023 17.25 11.25 17.25H8.75C8.19772 17.25 7.75 16.8023 7.75 16.25V10Z'
            fill='currentColor'
            fillOpacity={0.15}
          />
        </>
      ) : (
        <>
          <path d='M5.75 12.25V8.53113C5.75 8.18307 5.65917 7.84105 5.48649 7.53885L2.75 2.75H13.25L10.5135 7.53885C10.3408 7.84105 10.25 8.18307 10.25 8.53113V12.25C10.25 12.8023 9.80228 13.25 9.25 13.25H6.75C6.19772 13.25 5.75 12.8023 5.75 12.25Z' />
        </>
      )}
    </>
  );
}
