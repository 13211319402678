import useSWR from 'swr';

import { useSession } from '@clerk/nextjs';

import {
  QueryResponse,
  QueryFetcher,
  QueryConfigurationOptions,
} from '@typeDefs/API';
import { useLocation } from '@hooks/useLocation';
import { HTTPError } from '@/utils/errors';

const makeEnhancedPath = ({
  session,
  pathPrefix,
  instanceId,
  applicationId,
  path,
  skipRequest,
}) => {
  if (!session || skipRequest) {
    return null;
  }

  if (pathPrefix) {
    return `${pathPrefix}${path}`;
  }

  if (applicationId) {
    return `v1/applications/${applicationId}${path}`;
  }

  if (instanceId) {
    return `v1/instances/${instanceId}${path}`;
  }

  return null;
};

export const useQuery = <Data>(
  path: string,
  fetcher: QueryFetcher<Data>,
  options?: QueryConfigurationOptions<Data>,
): QueryResponse<Data> => {
  const { session } = useSession();
  const { instanceId, applicationId } = useLocation();
  const {
    pathPrefix,
    skipRequest,
    withApplicationBasePath,
    abortControllerRef,
  } = options || {};

  const enhancedPath = makeEnhancedPath({
    session,
    instanceId,
    pathPrefix,
    path,
    skipRequest,
    applicationId: withApplicationBasePath ? applicationId : undefined,
  });

  const extendedFetcher = async (path: string, ...args) => {
    const sessionToken = await session?.getToken();
    return fetcher({ path, token: sessionToken!, ...args, abortControllerRef });
  };

  return useSWR(enhancedPath, extendedFetcher, {
    revalidateOnFocus: false,
    shouldRetryOnError: (error: unknown) => {
      // If it's a known client error retrying wont help
      if (error instanceof HTTPError && error.code >= 400 && error.code < 500) {
        return false;
      }

      return true;
    },
    ...options,
  });
};
