export function SunIconPath(props: { size?: 'sm' | 'base' }) {
  const { size = 'base' } = props;
  return (
    <>
      {size === 'base' ? (
        <>
          <path
            d='M13.77 10C13.77 12.0821 12.0821 13.77 10 13.77C7.91789 13.77 6.23 12.0821 6.23 10C6.23 7.91789 7.91789 6.23 10 6.23C12.0821 6.23 13.77 7.91789 13.77 10Z'
            className='fill-[--icon-fill,currentColor]'
            fillOpacity={0.15}
            stroke='none'
          />
          <path
            d='M10 2.75V3.33M15.1265 4.87346L14.7164 5.28358M17.25 10H16.67M15.1265 15.1265L14.7164 14.7164M10 16.67V17.25M5.28354 14.7164L4.87341 15.1265M3.33 10H2.75M5.28354 5.28361L4.87341 4.87348M13.77 10C13.77 12.0821 12.0821 13.77 10 13.77C7.91789 13.77 6.23 12.0821 6.23 10C6.23 7.91789 7.91789 6.23 10 6.23C12.0821 6.23 13.77 7.91789 13.77 10Z'
            strokeWidth='1.5'
          />
        </>
      ) : (
        <>
          <circle cx='8' cy='8' r='3.25' strokeWidth='1.5' />
          <path d='M8 1.75V2.25' strokeWidth='1.5' />
          <path d='M12.4194 3.58057L12.0659 3.93412' strokeWidth='1.5' />
          <path d='M14.25 8L13.75 8' strokeWidth='1.5' />
          <path d='M12.4194 12.4194L12.0659 12.0659' strokeWidth='1.5' />
          <path d='M8 13.75V14.25' strokeWidth='1.5' />
          <path d='M3.93408 12.0659L3.58053 12.4194' strokeWidth='1.5' />
          <path d='M2.25 8L1.75 8' strokeWidth='1.5' />
          <path d='M3.93408 3.93414L3.58053 3.58059' strokeWidth='1.5' />
        </>
      )}
    </>
  );
}
