export function StackedRectangleIconPath(props: { size?: 'sm' | 'base' }) {
  const { size = 'base' } = props;
  return (
    <>
      {size === 'base' ? (
        <>
          <path
            d='M2.75 5.75V15.25L7.75 13.6711V17.25L17.25 14.25V4.75L12.25 6.32895V2.75L2.75 5.75Z'
            fill='currentColor
							'
            fillOpacity={0.15}
          />
          <path d='M12.25 6.32895L17.25 4.75V14.25L7.75 17.25V13.6711M12.25 6.32895L7.75 7.75V13.6711M12.25 6.32895V2.75L2.75 5.75V15.25L7.75 13.6711' />
        </>
      ) : (
        <>
          <path d='M9.25 5.59615V2.75L2.75 5.75V12.25L6.75 10.4038M9.25 5.59615L13.25 3.75V10.25L6.75 13.25V10.4038M9.25 5.59615L6.75 6.75V10.4038' />
        </>
      )}
    </>
  );
}
