import { buildRestPath } from '@utils/api';
import { SYSTEM_BASE_PATH, PREFERENCES_BASE_PATH } from './constants';

type BuildSystemPath = {
  resourceId?: string;
  action?: string;
};

export function buildSystemPath({
  resourceId,
  action,
}: BuildSystemPath = {}): string {
  return buildRestPath({
    basePath: SYSTEM_BASE_PATH,
    resourceId,
    action,
  });
}

export function buildPreferencesPath({
  resourceId,
  action,
}: BuildSystemPath = {}): string {
  return buildRestPath({
    basePath: PREFERENCES_BASE_PATH,
    resourceId,
    action,
  });
}
