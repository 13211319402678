export function BlockIconPath(props: { size?: 'sm' | 'base' }) {
  const { size = 'base' } = props;
  return (
    <>
      {size === 'base' ? (
        <>
          <path
            d='M10 2.75C14.0041 2.75 17.25 5.99594 17.25 10C17.25 12.002 16.4385 13.8145 15.1265 15.1265C13.8145 16.4385 12.002 17.25 10 17.25C5.99594 17.25 2.75 14.0041 2.75 10C2.75 7.99797 3.56148 6.18547 4.87348 4.87348C6.18547 3.56148 7.99797 2.75 10 2.75Z'
            fill='currentColor'
            fillOpacity={0.15}
          />
          <path d='M4.87348 4.87348C3.56148 6.18547 2.75 7.99797 2.75 10C2.75 14.0041 5.99594 17.25 10 17.25C12.002 17.25 13.8145 16.4385 15.1265 15.1265M4.87348 4.87348C6.18547 3.56148 7.99797 2.75 10 2.75C14.0041 2.75 17.25 5.99594 17.25 10C17.25 12.002 16.4385 13.8145 15.1265 15.1265M4.87348 4.87348L15.1265 15.1265' />
        </>
      ) : (
        <>
          <path d='M4.28769 4.28769L11.7123 11.7123M13.25 8C13.25 10.8995 10.8995 13.25 8 13.25C5.10051 13.25 2.75 10.8995 2.75 8C2.75 5.10051 5.10051 2.75 8 2.75C10.8995 2.75 13.25 5.10051 13.25 8Z' />
        </>
      )}
    </>
  );
}
