import { useCheckout } from '@components/checkout/checkoutContext';
import { useLocation } from '@hooks/useLocation';
import { applicationInstancePath, billingPath } from '@utils/routes';
import { useInstance } from '@hooks/useInstance';
import { useApplication } from '@hooks/useApplication';
import { Icon } from '@clerk/ceramic/experimental/components/Icon';
import { UnsupportedFeatures } from './UnsupportedFeatures';
import { useCurrentSubscription } from '@components/planAndBilling/currentSubscription/context/CurrentSubscriptionProvider';
import { Banner } from '@/app/components/ui/Banner';
import Link from 'next/link';

const GlobalBanner = () => {
  //THIS IS GOING TO BE USED FOR BANNERS THAT WE WOULD
  //LIKE TO SHOW ALSO IN THE HOME LAYOUT
  return null;
};

const ApplicationBanner = () => {
  const { state } = useCheckout();
  const { data: currentSubscription } = useCurrentSubscription();
  const { data: instance } = useInstance();
  const { data: application } = useApplication();

  const hardLimit = currentSubscription?.usage?.mau?.hard_limit ?? -1;
  const totalUnits = currentSubscription?.usage?.mau?.total_units ?? 0;
  const hasExceededMAU = hardLimit === 0 ? false : totalUnits > hardLimit;
  const hasPasswordEndpoint = instance?.patch_me_password_state === 'enabled';
  const appInGracePeriod = currentSubscription?.in_grace_period;

  if (!(application && instance) || state.isCheckoutVisible) {
    return null;
  }

  if (appInGracePeriod) {
    return (
      <Banner
        type='warning'
        message={<UnsupportedFeatures />}
        actions={
          <Link
            href={billingPath(application?.id, instance?.id)}
            className='flex shrink-0 items-center text-[--banner-text-color] font-semibold'
          >
            Upgrade your plan <Icon name='chevron-right-legacy' size='base' />
          </Link>
        }
      />
    );
  }

  if (hasExceededMAU) {
    return (
      <Banner
        type='warning'
        message={
          <>
            <span className='font-semibold'>User count exceeded.</span> Upgrade
            plan to prevent service interruption.
          </>
        }
        actions={
          <Link
            href={billingPath(application?.id, instance?.id)}
            className='flex shrink-0 items-center text-[--banner-text-color] font-semibold'
          >
            View plans
          </Link>
        }
      />
    );
  }

  if (hasPasswordEndpoint) {
    return (
      <Banner
        type='warning'
        message={
          <>
            Security update: Update your application to use the new{' '}
            <span className='font-mono'>updatePassword()</span>.
          </>
        }
        actions={
          <Link
            href={applicationInstancePath({
              applicationId: application?.id,
              instanceId: instance?.id,
              postfix: '/settings',
            })}
            className='flex shrink-0 items-center text-[--banner-text-color] font-semibold'
          >
            Go to Settings
          </Link>
        }
      />
    );
  }

  return <GlobalBanner />;
};

export const BannerManager = () => {
  const { isHome, pathname } = useLocation();

  if (pathname.startsWith('/vercel')) {
    return null;
  }

  if (!isHome) {
    return <ApplicationBanner />;
  }

  return <GlobalBanner />;
};
