import { useQuery } from '@hooks/useQuery';

import { APIQueryHook } from '@typeDefs/API';
import { SystemConfigResponse } from './types';

import { getSystemConfig } from './api';
import { buildSystemPath } from './utils';

export const useSystemConfig: APIQueryHook<SystemConfigResponse> = () => {
  const path = buildSystemPath();
  return useQuery(path, getSystemConfig, { pathPrefix: 'v1' });
};
