import { createContext, useContext } from 'react';
import {
  CheckoutActionTypes,
  CheckoutActions,
  CheckoutContextProps,
  OptionsActions,
  CheckoutState,
} from './types';

export const checkoutReducer = (
  state: CheckoutState,
  action: CheckoutActionTypes,
): CheckoutState => {
  switch (action.type) {
    case CheckoutActions.SetCheckout:
      return {
        state: {
          ...action.payload,
          ...state?.state,
        },
      };
    case CheckoutActions.ClearCheckout:
      // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
      return { state: null };
    case CheckoutActions.SetCheckoutVisible:
      return { state: { ...state.state, isCheckoutVisible: true } };
    case CheckoutActions.SetPaymentElementInitialized:
      return { state: { ...state.state, isPaymentElementInitialized: true } };
    case OptionsActions.SetOptions:
      return { state: { ...state.state, ...action.payload } };
    default:
      return state;
  }
};

export const CheckoutContext = createContext<CheckoutContextProps>({
  // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
  state: null,
  clearCheckout: () => null,
  setCheckout: () => null,
  showCheckout: () => null,
  initializePaymentElement: () => null,
  setOptions: () => null,
});

export const useCheckout = () => {
  const context = useContext(CheckoutContext);
  if (!context) {
    throw new Error('useCheckout must be used within a CheckoutProvider');
  }
  return context;
};
