export function DocumentIconPath(props: { size?: 'sm' | 'base' }) {
  const { size = 'base' } = props;
  return (
    <>
      {size === 'base' ? (
        <>
          <path
            d='M15.56 7.452h-3.938v-3.94m-3.707 9.5h4.17m-4.17-2.78h4.17M6.06 16.719h7.877c1.024 0 1.854-.83 1.854-1.854V7.22L11.853 3.28H6.061c-1.023 0-1.853.83-1.853 1.854v9.73c0 1.024.83 1.854 1.853 1.854Z'
            strokeWidth='1.563'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </>
      ) : (
        <></>
      )}
    </>
  );
}
