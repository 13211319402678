import * as RadixTooltip from '@radix-ui/react-tooltip';
import { motion, AnimatePresence } from 'framer-motion';
import { useState } from 'react';

interface Props {
  children: React.ReactNode;
  content: React.ReactNode;
  arrowSide?: 'top' | 'right' | 'bottom' | 'left';
}

export function Tooltip({ children, content, arrowSide = 'right' }: Props) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <RadixTooltip.Provider>
      <RadixTooltip.Root
        onOpenChange={setIsOpen}
        open={isOpen}
        delayDuration={300}
      >
        <RadixTooltip.Trigger asChild>
          <span>{children}</span>
        </RadixTooltip.Trigger>
        <AnimatePresence>
          {isOpen && (
            <RadixTooltip.Portal forceMount>
              <RadixTooltip.Content
                asChild
                sideOffset={5}
                side={arrowSide}
                align='end'
                alignOffset={-20}
                // TODO: Replace <Balancer /> with 'text-balance' class when its fully supported by all browsers.
                className='relative isolate z-[99999] w-[22.8125rem] rounded-md bg-white px-4 py-3 text-base/[1.125rem] font-medium text-legacyGray-700 shadow-[0_0_1px_theme(colors.black/0.2),0_15px_35px_-5px_theme(colors.black/0.14),0_5px_15px_-3px_theme(colors.black/0.08)] will-change-transform'
              >
                <motion.div
                  initial={{ opacity: 0, y: 4, scale: 0.96 }}
                  animate={{ opacity: 1, y: 0, scale: 1 }}
                >
                  {content}
                  <RadixTooltip.Arrow
                    width={14}
                    height={8}
                    className='fill-white'
                  />
                </motion.div>
              </RadixTooltip.Content>
            </RadixTooltip.Portal>
          )}
        </AnimatePresence>
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  );
}
