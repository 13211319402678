import React, { Component, ReactNode } from 'react';
import * as Sentry from '@sentry/nextjs';
import { Flex, Text } from '@chakra-ui/react';
import { Heading } from '@components/common/Heading';
import { ErrorLayout } from '@components/layouts/ErrorLayout';

type ErrorBoundaryProps = {
  children: ReactNode;
};

type ErrorBoundaryState = {
  hasError: boolean;
};

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI
    return { hasError: true };
  }

  componentDidCatch(error: any, info: any) {
    Sentry.captureException(error, { extra: info });
  }

  render() {
    if (this.state.hasError) {
      return (
        <Flex
          width='100%'
          flex={1}
          pt={12}
          pb={12}
          px={{ base: 6, md: 16 }}
          height='100%'
          flexDirection='column'
          alignItems='center'
          maxHeight='100%'
        >
          <Flex
            flex={1}
            flexDirection='column'
            maxHeight='100%'
            width='100%'
            as='main'
            height='100%'
            p={0}
            position='relative'
            maxW='container.xxl'
          >
            <ErrorLayout
              onGoBack={() => {
                this.setState({
                  hasError: false,
                });
                this.forceUpdate();
              }}
            >
              <Heading as='h1' size='2xl' mb={2}>
                500
              </Heading>
              <Text mb={8}>
                Clerk has encountered an unexpected error. We&apos;re on it,
                please refresh or try again later.
              </Text>
            </ErrorLayout>
          </Flex>
        </Flex>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
