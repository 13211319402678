import { Icon } from '@clerk/ceramic/experimental/components/Icon';
import { useStaffMode } from './StaffMode';
import Link from 'next/link';

export default function CeramicSandboxLink() {
  const { staffModeEnabled: isCeramicSandboxEnabled } = useStaffMode({
    feature: 'color-scheme-toggle',
  });

  if (!isCeramicSandboxEnabled) {
    return null;
  }

  return (
    <Link
      href='/ceramic-sandbox'
      className='inline-grid grid-flow-col items-center gap-3 rounded py-1.5 font-book text-secondary hover:text-primary [&>svg]:text-primary'
    >
      <Icon name='color-switch' size='sm' />
      Ceramic Sandbox
    </Link>
  );
}
