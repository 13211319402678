import type { ActiveSessionResource } from '@clerk/types';
import { HTTPError } from '@utils/errors';
import { MutableRefObject } from 'react';

type BuildURLOptions = {
  query?: string;
  session?: ActiveSessionResource;
};

type BuildUrlOptions = {
  basePath: string;
  resourceId?: string;
  action?: string;
  queryParams?: {
    [key: string]: string | string[];
  };
};

type Expand<T> = T extends infer O ? { [K in keyof O]: O[K] } : never;

type DAPIParamsPathTokenAbortControllerAndData<Data = undefined> = {
  path: string;
  token: string;
  abortControllerRef?: MutableRefObject<AbortController>;
} & (Data extends undefined ? object : { data: Data });

export type HTTPMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

export type DAPIParams<Data = undefined> = Expand<
  DAPIParamsPathTokenAbortControllerAndData<Data>
>;

/** @deprecated use buildRestPath instead*/

export function buildURL(path = '', options: BuildURLOptions = {}) {
  const { query, session } = options;

  const url = new URL(path, process.env.NEXT_PUBLIC_DASHBOARD_API);

  if (session) {
    url.searchParams.append('_clerk_session_id', session.id);
  }

  if (query) {
    for (const [key, value] of Object.entries(query)) {
      url.searchParams.append(key, value as string);
    }
  }

  return url.toString();
}

/** @deprecated in favour of makeHttpClient */

export function request(
  url: RequestInfo,
  { method = 'GET', token = '', data = {} } = {},
): Promise<Response> {
  let body;
  const headers: HeadersInit = {};

  if (token) {
    headers['Authorization'] = token;
  }

  // Binary fields need to be left alone, in order to properly set the correct
  // Content-Type headers.
  if (data instanceof FormData) {
    body = data;
  } else {
    headers['Content-Type'] = 'application/json';
    body = method === 'GET' ? null : JSON.stringify(data);
  }

  return fetch(url, {
    headers,
    method,
    body,
  });
}

export const buildCacheKey = ({
  path,
  session,
  id,
}: {
  path: string;
  session: ActiveSessionResource;
  id?: string;
}): string => {
  const url = buildURL(path, { session: session });

  // This is a temporary fix that is being tracked here
  // https://linear.app/clerk/issue/JS-30/investigate-why-swr-does-not-reload-applications-on-org-switch
  const newUrl = id ? `${url}&organization_id=${id}` : url;

  return newUrl;
};

const makeUrlSearchParams = (queryParams: Record<string, any>) => {
  const urlSearchParams = new URLSearchParams();

  Object.keys(queryParams).forEach(key => {
    const currentQueryParamValue = queryParams[key];
    /**
     * We have cases that we have an array of values for a query param e.x role=member&role=admin
     * in this situation we want to iterate on that array and append the values to the search params
     * one by one.
     */
    if (Array.isArray(currentQueryParamValue)) {
      currentQueryParamValue.forEach(value => {
        urlSearchParams.append(key, value);
      });
    } else {
      urlSearchParams.append(key, currentQueryParamValue);
    }
  });

  return urlSearchParams;
};

export function buildRestPath({
  basePath,
  resourceId,
  action,
  queryParams,
}: BuildUrlOptions): string {
  let path = basePath;

  if (resourceId) {
    path += `/${resourceId}`;
  }

  if (action) {
    path += `/${action}`;
  }

  if (queryParams && Object.keys(queryParams).length > 0) {
    path += '?' + makeUrlSearchParams(queryParams).toString();
  }

  // Remove double slashes
  path = path.replace(/\/\/+/g, '/');

  return path;
}
function makeHttpClient(method: HTTPMethod) {
  return async <Return>({
    path,
    data,
    token,
    abortControllerRef,
  }: {
    path: string;
    data?: any;
    token?: string | null;
    abortControllerRef?: MutableRefObject<AbortController>;
  }): Promise<Return> => {
    const controller = new AbortController();
    if (abortControllerRef) {
      abortControllerRef.current = controller;
    }

    const url = new URL(path, process.env.NEXT_PUBLIC_DASHBOARD_API).toString();
    let body;
    const headers: HeadersInit = {};

    if (token) {
      headers['Authorization'] = token;
    }

    // Binary fields need to be left alone, in order to properly set the correct
    // Content-Type headers.
    if (data instanceof FormData) {
      body = data;
    } else {
      headers['Content-Type'] = 'application/json';
      body = method === 'GET' ? null : JSON.stringify(data);
    }

    const res = await fetch(url, {
      headers,
      method,
      body,
      signal: controller.signal,
    });

    if (!res.ok) {
      const { message, errors } = await res.json();

      const httpError = new HTTPError(
        message || res.statusText || 'An error occurred',
        res.status,
        errors,
      );

      throw httpError;
    }

    return res.status === 204
      ? Promise.resolve({})
      : res.json().catch(() => Promise.resolve({}));
  };
}

export const GET = makeHttpClient('GET');
export const POST = makeHttpClient('POST');
export const PUT = makeHttpClient('PUT');
export const DELETE = makeHttpClient('DELETE');
export const PATCH = makeHttpClient('PATCH');
