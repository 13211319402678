import {
  useFeatureFlagContext,
  FeatureFlagsContextType,
} from '@context/vitals/featureFlags/Provider';
import { FeatureFlags } from '@dapi/featureFlags/types';

type UseFeatureFlagsReturn = {
  featureFlags: FeatureFlags;
} & Omit<FeatureFlagsContextType, 'data'>;

export const useFeatureFlags = (): UseFeatureFlagsReturn => {
  const { data, ...rest } = useFeatureFlagContext();

  const featureFlags = data || {
    allow_new_pricing_checkout: false,
    allow_pii_update: false,
    prevent_deletion_of_active_prod_instance: true,
    auto_refund_canceled_subscriptions: false,
    enable_email_link_require_same_client: false,
    update_secret_key_last_used_at: false,
    allow_custom_oauth_providers_domain: false,
    new_apple_app_site_association_response: false,
    use_suffixed_cookies: false,
    allow_invite_only: false,
    allow_coinbase_wallet: false,
    // Add any default feature flag values here
    // until the actual values are retrieved from
    // the backend
  };
  return { featureFlags, ...rest };
};
