interface BillingSectionProps {
  title: string;
  children: React.ReactNode;
  showDivider?: boolean;
  actionComponent?: React.ReactNode;
}

export function Section({
  title,
  children,
  showDivider = true,
  actionComponent,
}: BillingSectionProps) {
  return (
    <section>
      <div className='flex items-center justify-between'>
        <h2 className='text-xl font-semibold text-primary'>{title}</h2>
        {actionComponent && actionComponent}
      </div>
      {showDivider && <div className='mb-6 mt-4 border-b border-primary' />}
      {children}
    </section>
  );
}
