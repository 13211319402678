import { useQuery } from '@hooks/useQuery';

import type { APIQueryHook } from '@typeDefs/API';
import type { FeatureFlags } from './types';

import { getFeatureFlags } from './api';
import { buildFeatureFlagPath } from './utils';

export const useFetchFeatureFlags: APIQueryHook<FeatureFlags> = () => {
  const path = buildFeatureFlagPath();
  return useQuery(path, getFeatureFlags);
};
