import React from 'react';
import { useFormContext } from 'react-hook-form';
import type { ProdInstanceForm } from './CreateProdInstanceModal';
import { useTLDParser } from '@hooks/useTLDParser';
import { InputBoxPreview } from './InputBoxPreview';
import { InputPreview } from '@components/common/InputPreview/InputPreview';

export function PreviewInstanceDetails() {
  const { parse } = useTLDParser();
  const { watch } = useFormContext<ProdInstanceForm>();
  const { home_url } = watch();

  const tldResult = parse(home_url);
  const showDomain = tldResult && home_url;

  return (
    <div className='rounded-lg bg-[#F5F5F5] p-4'>
      <InputPreview
        label='Domain'
        description='This forms the basis for your DNS records, your Frontend API URL and is used by Clerk to set cookies for your application.'
        info={`Please ensure you have access to set the necessary CNAME records for this root domain.
              For example, preview deployment URLs using vercel.app will not work.`}
        value={(showDomain ? tldResult.domain : tldResult.hostname) || ''}
        disableCopy
        disableExternalLink
        color='legacyGray.800'
      />
      <InputBoxPreview
        domain={tldResult.domain || ''}
        subdomain={tldResult.subdomain || ''}
        homeUrl={home_url}
      />
    </div>
  );
}
