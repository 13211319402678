import React from 'react';

import { QueryResponse, PartialQueryResponse } from '@typeDefs/API';
import { useFetchInstance } from '@dapi/instances/queries';
import { Instance } from '@dapi/instances/types';

export type InstanceContextType = QueryResponse<Instance>;
export type PartialInstanceContextType = PartialQueryResponse<Instance>;

// @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
export const InstanceContext = React.createContext<InstanceContextType>(null);

export const useInstanceContext = (): InstanceContextType => {
  const context = React.useContext(InstanceContext);

  if (!context) {
    throw new Error(
      'useInstanceContext must be used within a InstanceContextProvider',
    );
  }
  return context;
};

const Provider = ({ children }: { children: React.ReactNode }) => {
  const swr = useFetchInstance({ options: { revalidateOnFocus: true } });

  return (
    <InstanceContext.Provider value={swr}>{children}</InstanceContext.Provider>
  );
};

export default Provider;
