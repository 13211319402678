import { useQuery } from '@hooks/useQuery';

import type { APIQueryHook } from '@typeDefs/API';
import { CurrentSubscription, ListProductResponse } from './types';
import { buildQueryParams } from './utils';
import { listProducts, currentSubscription } from './api';
import { buildRestPath } from '@utils/api';

type BaseProductHookProps = {
  limit?: number;
  page?: number;
};

export const useFetchProducts: APIQueryHook<
  ListProductResponse,
  BaseProductHookProps
> = (props = {}) => {
  const { page, limit } = props;
  const queryParams = buildQueryParams({
    page,
    limit,
  });

  const path = buildRestPath({
    //TODO:AlexNti-pricing this path is temporary and shall be replaced by buildProductsPath
    basePath: '',
    queryParams,
    action: 'subscription_plans',
  });
  const res = useQuery(path, listProducts, {
    withApplicationBasePath: true,
  });

  return res;
};

export const useFetchCurrentSubscription: APIQueryHook<
  CurrentSubscription
> = () => {
  const path = buildRestPath({
    //TODO:AlexNti-pricing this path is temporary and shall be replaced by buildProductsPath
    basePath: '',
    action: 'current_subscription',
  });
  const res = useQuery(path, currentSubscription, {
    withApplicationBasePath: true,
  });

  return res;
};
