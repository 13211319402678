import { useContext, createContext } from 'react';
import { transformAddons, transformSubscription } from '../utils';
import { CurrentSerializedSubscription } from '../types';
import {
  useFetchCurrentSubscription,
  useFetchProducts,
} from '@dapi/products/queries';

export type UseSubscriptionReturn = {
  data: CurrentSerializedSubscription;
} & Omit<ReturnType<typeof useFetchCurrentSubscription>, 'data'>;

// @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
const CurrentSubscriptionContext = createContext<UseSubscriptionReturn>(null);

export const useCurrentSubscription = () => {
  const context = useContext(CurrentSubscriptionContext);
  if (!context) {
    throw new Error(
      'useCurrentSubscriptionContext must be used within a CurrentSubscriptionProvider',
    );
  }
  return context;
};

const useSubscription = (): UseSubscriptionReturn => {
  const { data: currentSubscription, ...rest } = useFetchCurrentSubscription();
  const { data: allPlans } = useFetchProducts({});

  if (currentSubscription?.type !== 'pro') {
    const prodPlanAddons =
      allPlans?.find(plan => plan.name === 'Pro')?.addons || [];

    return {
      data: {
        // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
        ...transformSubscription(currentSubscription),
        addons: transformAddons(
          prodPlanAddons.map(({ ...rest }) => ({
            ...rest,
          })),
        ),
      },
      ...rest,
    };
  }

  return {
    data: transformSubscription(currentSubscription),
    ...rest,
  };
};

export const CurrentSubscriptionProvider = ({
  children,
}: {
  children:
    | React.ReactNode
    | ((currentSubscription: UseSubscriptionReturn) => React.ReactNode);
}) => {
  const currentSubscription = useSubscription();
  return (
    <CurrentSubscriptionContext.Provider value={currentSubscription}>
      {typeof children === 'function'
        ? children(currentSubscription)
        : children}
    </CurrentSubscriptionContext.Provider>
  );
};
