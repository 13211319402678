import {
  // See https://linear.app/clerk/issue/DES-872/remove-chakra
  // eslint-disable-next-line no-restricted-imports
  BoxProps,
  Flex,
  FlexProps,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Tooltip,
} from '@chakra-ui/react';
import { GenericBadge as Badge } from '@components/common/Badges/Badges';
import { DEFAULT_ANIMATION_DURATION, DEFAULT_ANIMATION_TYPE } from '@constants';
import {
  InformationCircleIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/solid';
import { textClassNames } from '@theme/foundations/textStyles';
import { cx } from 'cva';
import { motion } from 'framer-motion';
import { SVGProps } from 'react';
import { FieldError } from 'react-hook-form';

export type InputBoxProps = {
  leftIcon?: (props: SVGProps<SVGSVGElement>) => React.ReactNode;
  label?: string | JSX.Element;
  error?: FieldError | { message: string };
  description?: string;
  optional?: boolean;
  children?: React.ReactNode;
  htmlFor?: string;
  infoTooltip?: string;
  errorMessageProps?: BoxProps;
  info?: string;
} & FlexProps;

export function InputBox({
  leftIcon,
  label,
  error,
  description = '',
  optional = false,
  children,
  htmlFor,
  infoTooltip,
  errorMessageProps,
  info = '',
  ...rest
}: InputBoxProps) {
  return (
    <Flex py={4} pl={4} mb={2} {...rest}>
      {leftIcon && (
        <div>
          <Icon
            as={leftIcon}
            color='legacyGray.300'
            mr={4}
            position='relative'
            top='-2px'
            boxSize={5}
          />
        </div>
      )}

      <div className='w-full'>
        <FormControl isInvalid={!!error}>
          <div className='flex justify-between'>
            <div className='relative flex'>
              {label && (
                <div className='flex flex-col'>
                  <FormLabel
                    htmlFor={htmlFor}
                    fontSize='sm'
                    mb={description ? 1 : 2}
                  >
                    {label}
                  </FormLabel>
                </div>
              )}
              {infoTooltip && (
                <div className='absolute -right-3 -top-px'>
                  <Tooltip label={infoTooltip} placement='bottom'>
                    <span>
                      <Icon
                        as={QuestionMarkCircleIcon}
                        color='legacyGray.300'
                        ml={1}
                        boxSize='18px'
                        mt='-1px'
                      />
                    </span>
                  </Tooltip>
                </div>
              )}
            </div>

            {optional && (
              <div>
                <Badge label='Optional' />
              </div>
            )}
          </div>
          {description && (
            <p
              className={cx(
                textClassNames['sm-normal'],
                'mb-2 text-legacyGray-600',
              )}
            >
              {description}
            </p>
          )}
          {children}

          {info && (
            <div className='mt-2 flex gap-1'>
              <Icon
                as={InformationCircleIcon}
                boxSize={4}
                color='primary.500'
              />
              <p
                className={cx(
                  textClassNames['sm-normal'],
                  'mb-2 text-legacyGray-600',
                )}
              >
                {info}
              </p>
            </div>
          )}

          {error?.message && (
            <motion.div
              layout
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
                transition: {
                  duration: DEFAULT_ANIMATION_DURATION,
                  type: DEFAULT_ANIMATION_TYPE,
                },
              }}
              exit={{
                border: 0,
                gap: 0,
                height: 0,
                padding: 0,
                opacity: 0,
                transition: {
                  duration: DEFAULT_ANIMATION_DURATION,
                  type: DEFAULT_ANIMATION_TYPE,
                },
                margin: 0,
              }}
            >
              <FormErrorMessage {...errorMessageProps}>
                {error?.message}
              </FormErrorMessage>
            </motion.div>
          )}
        </FormControl>
      </div>
    </Flex>
  );
}

type ReadonlyInputBoxProps = Pick<
  InputBoxProps,
  'leftIcon' | 'label' | 'children' | 'description'
> &
  FlexProps;

export function ReadonlyInputBox({
  leftIcon,
  label,
  children,
  description,
  ...rest
}: ReadonlyInputBoxProps) {
  return InputBox({
    leftIcon,
    label,
    children,
    error: undefined,
    description,
    ...rest,
  });
}
