import React from 'react';
import { Button } from '@clerk/ceramic/experimental/components/Button';
import { useRouter } from 'next/router';
import { textClassNames } from '@theme/foundations/textStyles';
import { cx } from 'cva';

export function ErrorLayout({
  children,
  onGoBack,
}: {
  children: React.ReactNode;
  onGoBack?: () => void;
}) {
  const { push } = useRouter();

  const redirectToHome = () => {
    void push('/');
  };

  const handleGoBack = () => {
    redirectToHome();
    // We are doing a setTimeout becasue
    // the state is still hasError: true
    setTimeout(function () {
      onGoBack?.();
    }, 100);
  };

  return (
    <div className='align-stretch mx-auto flex h-full w-full max-w-3xl flex-col'>
      <div className='h-[45%] py-12'>
        <div className='mb-16'>
          {children}
          <Button
            intent='primary'
            icon='arrow-left-legacy'
            iconPosition='left'
            onClick={handleGoBack}
          >
            Go back
          </Button>
        </div>

        <div className='rounded-2xl border px-6 py-8'>
          <h2 className={cx(textClassNames['lg-medium'])}>Need help?</h2>
          <p className='mt-1 text-legacyGray-500'>
            If you have questions or need assistance, our extensive
            documentation is ready for you, and you can also join our community
            Discord channel to talk to us. We’re here to help!
          </p>
          <div className='mt-6 flex items-center gap-4'>
            <a
              href='https://clerk.com/docs'
              target='_blank'
              rel='noopener'
              className={cx(textClassNames['md-medium'], 'text-purple')}
            >
              Read documentation
            </a>

            <a
              href='https://discord.com/invite/b5rXHjAg7A'
              target='_blank'
              rel='noopener'
              className={cx(textClassNames['md-medium'], 'text-purple')}
            >
              Join community Discord
            </a>
          </div>
        </div>
      </div>
      <div className='w-full border-t p-4'>
        <p className={cx(textClassNames['sm-normal'], 'text-legacyGray-500')}>
          Clerk © {new Date().getFullYear()}. All rights reserved
        </p>
      </div>
    </div>
  );
}
