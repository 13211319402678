export function Application2IconPath(props: { size?: 'sm' | 'base' }) {
  const { size = 'base' } = props;
  return (
    <>
      {size === 'base' ? (
        <g fill='currentColor' fillOpacity={0.15}>
          <rect x='2.75' y='2.75' width='5.5' height='5.5' rx='1' />
          <rect
            x='2.75'
            y='11.75'
            width='5.5'
            height='5.5'
            rx='1'
            // fill="#EEEEF0"
          />
          <rect
            x='11.75'
            y='2.75'
            width='5.5'
            height='5.5'
            rx='1'
            // fill="#EEEEF0"
          />
          <path d='M14.5 11.75V17.25M17.25 14.5L11.75 14.5' />
        </g>
      ) : (
        <>
          <rect x='2.75' y='2.75' width='3.5' height='3.5' rx='1' />
          <rect x='2.75' y='9.75' width='3.5' height='3.5' rx='1' />
          <rect x='9.75' y='2.75' width='3.5' height='3.5' rx='1' />
          <path d='M11.5 9.75V13.25M13.25 11.5L9.75 11.5' />
        </>
      )}
    </>
  );
}
