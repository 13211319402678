export function ChevronRightIconPath(props: { size?: 'sm' | 'base' }) {
  const { size = 'base' } = props;
  return (
    <>
      {size === 'base' ? (
        <>
          <path d='M8 14.5L12.5 10.25L8 6' />
        </>
      ) : (
        <>
          <path d='M6.75 11.25L10.25 8L6.75 4.75' />
        </>
      )}
    </>
  );
}
