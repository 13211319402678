'use client';

import { tld } from '@/utils/tld';
import Cookies from 'js-cookie';
import { usePathname, useSearchParams } from 'next/navigation';
import { useEffect } from 'react';
import { AnalyticsContextType } from '@/app/components/analytics/Analytics';

export function Tracker({ page }: Pick<AnalyticsContextType, 'page'>) {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  useEffect(() => {
    const gclid = searchParams?.get('gclid');
    if (gclid) {
      Cookies.set('gclid', gclid, {
        path: '/',
        sameSite: 'Lax',
        domain: tld(window.location.href),
        expires: 30,
      });
    }

    page(
      pathname
        ? pathname.replace(/\/app_\w+/, '').replace(/\/ins_\w+/, '')
        : undefined,
    );
  }, [pathname, searchParams]);

  return null;
}
