import { GET, PATCH } from '@utils/api';
import { OAuthStrategy } from '@clerk/types';

import type {
  InviteOnlyPayload,
  OAuthProviderPayload,
  OAuthProviderSettings,
  RestrictionsPayload,
  UserSettings,
} from './types';

export const getUserSettings = ({
  path,
  token,
}: {
  path: string;
  token: string;
}): Promise<UserSettings> => {
  return GET<UserSettings>({ path, token });
};

export const updateUserSettings = ({
  path,
  token,
  data,
}: {
  path: string;
  token: string;
  data: Partial<UserSettings>;
}): Promise<UserSettings> => {
  return PATCH<UserSettings>({ data, token, path });
};

export const updateUserSocial = ({
  path,
  token,
  data,
}: {
  path: string;
  token: string;
  data: OAuthProviderPayload & { strategy: OAuthStrategy };
}): Promise<OAuthProviderSettings> => {
  const { strategy, ...rest } = data;

  return PATCH<OAuthProviderSettings>({
    data: rest,
    token,
    path: `${path}/${strategy}`,
  });
};

export const updateUserSettingsRestrictions = ({
  path,
  token,
  data,
}: {
  path: string;
  token: string;
  data: Partial<RestrictionsPayload>;
}): Promise<RestrictionsPayload> => {
  return PATCH<RestrictionsPayload>({ data, token, path });
};

export const updateUserSettingInviteOnly = ({
  path,
  token,
  data,
}: {
  path: string;
  token: string;
  data: Partial<InviteOnlyPayload>;
}): Promise<InviteOnlyPayload> => {
  return PATCH<InviteOnlyPayload>({ data, token, path });
};
