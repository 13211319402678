export function BoltIconPath(props: { size?: 'sm' | 'base' }) {
  const { size = 'base' } = props;
  return (
    <>
      {size === 'base' ? (
        <>
          <path
            d='M12.25 8.75C11.6977 8.75 11.25 8.30228 11.25 7.75V2.75L4.75 11.25H7.75C8.30228 11.25 8.75 11.6977 8.75 12.25V17.25L15.25 8.75H12.25Z'
            fill='currentColor'
            fillOpacity={0.15}
          />
        </>
      ) : (
        <>
          <path d='M9.25 7.25V2.75L3.75 9.25H6.75V13.25L12.25 7.25H9.25Z' />
        </>
      )}
    </>
  );
}
