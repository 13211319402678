export function ShieldIconPath(props: { size?: 'sm' | 'base' }) {
  const { size = 'base' } = props;
  return (
    <>
      {size === 'base' ? (
        <>
          <path
            d='M17.25 7C17.25 7 14 5.41667 10 2.75C6 5.41667 2.75 7 2.75 7C2.75 14 8.66667 17.25 10 17.25C11.3333 17.25 17.25 14 17.25 7Z'
            fill='currentColor'
            fillOpacity={0.15}
          />
        </>
      ) : (
        <>
          <path d='M13.25 5.75C13.25 5.75 11 4.75 8 2.75C5 4.75 2.75 5.75 2.75 5.75C2.75 11 7 13.25 8 13.25C9 13.25 13.25 11 13.25 5.75Z' />
        </>
      )}
    </>
  );
}
