import { useRouter } from 'next/router';
import type { NextRouter } from 'next/router';

export type UseLocationReturn = NextRouter & {
  applicationId: string | undefined;
  instanceId: string | undefined;
  currentPage: string;
  isHome: boolean;
  isInstanceHome: boolean;
  isV2: boolean;
};

const V1_PREFIXES = ['/applications', '/instances'];

export const useLocation = (): UseLocationReturn => {
  const { query, pathname, ...rest } = useRouter();
  const { applicationId, instanceId } = query as Record<
    string,
    string | undefined
  >;

  return {
    ...rest,
    query,
    pathname,
    applicationId,
    instanceId,
    currentPage: pathname.split('[instanceId]')[1] || '/',
    isInstanceHome: pathname === '/apps/[applicationId]/instances/[instanceId]',
    isHome: !applicationId || !instanceId,
    isV2: !V1_PREFIXES.find(pre => pathname.startsWith(pre)),
  };
};
