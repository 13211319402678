export function ChevronLeftIconPath(props: { size?: 'sm' | 'base' }) {
  const { size = 'base' } = props;
  return (
    <>
      {size === 'base' ? (
        <>
          <path d='M12.25 14.25L7.75 10L12.25 5.75' />
        </>
      ) : (
        <>
          <path d='M9.25 11.25L5.75 8L9.25 4.75' />
        </>
      )}
    </>
  );
}
