import { useMutation } from '@hooks/useMutation';

import { APIMutationHook } from '@typeDefs/API';
import {
  createApplication,
  deleteLogo,
  updateApplication,
  uploadImageResource,
  createProductionInstance,
  validateCloning,
  deleteApplication,
  transferToOrganization,
  transferToPersonalWorkspace,
  deleteFavicon,
} from './api';
import {
  Application,
  CreateApplicationParams,
  CreateProductionInstance,
  ValidateCloning,
  TransferToOrganizationParams,
} from './types';
import { Instance } from '@dapi/instances/types';

import { buildApplicationPath } from './utils';

type BaseApplicationHookProps = {
  id: string;
};

export const useUpdateApplication: APIMutationHook<
  Application,
  Partial<Application>,
  BaseApplicationHookProps
> = params => {
  const { id } = params;
  const path = buildApplicationPath({ resourceId: id });
  return useMutation({ path }, updateApplication, { pathPrefix: 'v1' });
};

type UpdateLogoTriggerProps = FormData;

export const useUpdateApplicationLogo: APIMutationHook<
  never,
  UpdateLogoTriggerProps,
  BaseApplicationHookProps
> = params => {
  const { id } = params;
  const path = buildApplicationPath({ resourceId: id, action: 'logo' });
  return useMutation({ path }, uploadImageResource, { pathPrefix: 'v1' });
};

export const useDeleteApplicationLogo: APIMutationHook<
  never,
  never,
  BaseApplicationHookProps
> = params => {
  const { id } = params;
  const path = buildApplicationPath({ resourceId: id, action: 'logo' });
  return useMutation({ path }, deleteLogo, { pathPrefix: 'v1' });
};

export const useDeleteApplicationFavicon: APIMutationHook<
  never,
  never,
  BaseApplicationHookProps
> = params => {
  const { id } = params;
  const path = buildApplicationPath({ resourceId: id, action: 'favicon' });
  return useMutation({ path }, deleteFavicon, { pathPrefix: 'v1' });
};

type UpdateApplicationFaviconProps = FormData;

export const useUpdateApplicationFavicon: APIMutationHook<
  never,
  UpdateApplicationFaviconProps,
  BaseApplicationHookProps
> = params => {
  const { id } = params;
  const path = buildApplicationPath({ resourceId: id, action: 'favicon' });
  return useMutation({ path }, uploadImageResource, { pathPrefix: 'v1' });
};

export const useCreateApplication: APIMutationHook<
  Application,
  Partial<CreateApplicationParams>
> = () => {
  const path = buildApplicationPath();
  return useMutation({ path }, createApplication, { pathPrefix: 'v1' });
};

export const useTransferToOrganization: APIMutationHook<
  never,
  TransferToOrganizationParams,
  BaseApplicationHookProps
> = params => {
  const { id } = params;
  const path = buildApplicationPath({
    resourceId: id,
    action: 'transfer_to_organization',
  });
  return useMutation({ path }, transferToOrganization, { pathPrefix: 'v1' });
};

export const useTransferToPersonalWorkspace: APIMutationHook<
  never,
  never,
  BaseApplicationHookProps
> = params => {
  const { id } = params;
  const path = buildApplicationPath({
    resourceId: id,
    action: 'transfer_to_user',
  });
  return useMutation({ path }, transferToPersonalWorkspace, {
    pathPrefix: 'v1',
  });
};

export const useDeleteApplication: APIMutationHook<
  never,
  never,
  BaseApplicationHookProps
> = params => {
  const { id } = params;
  const path = buildApplicationPath({
    resourceId: id,
  });
  return useMutation({ path }, deleteApplication, { pathPrefix: 'v1' });
};

export const useCreateProductionInstance: APIMutationHook<
  Instance,
  CreateProductionInstance,
  BaseApplicationHookProps
> = params => {
  const { id } = params;
  const path = buildApplicationPath({
    resourceId: id,
    action: 'production_instance',
  });
  return useMutation({ path }, createProductionInstance, { pathPrefix: 'v1' });
};

export const useValidateCloning: APIMutationHook<
  never,
  ValidateCloning,
  BaseApplicationHookProps
> = params => {
  const { id } = params;
  const path = buildApplicationPath({
    resourceId: id,
    action: 'validate_cloning',
  });
  return useMutation({ path }, validateCloning, { pathPrefix: 'v1' });
};
