export function NativeAppsIconPath(props: { size?: 'sm' | 'base' }) {
  const { size = 'base' } = props;
  return (
    <>
      {size === 'base' ? (
        <>
          <path
            d='M3 5C3 3.89543 3.89543 3 5 3H16C17.1046 3 18 3.89543 18 5V16C18 17.1046 17.1046 18 16 18H5C3.89543 18 3 17.1046 3 16V5Z'
            fill='currentColor'
            fillOpacity={0.15}
            stroke='none'
          />
          <path d='M17.25 4.25V4.25C17.25 3.42157 16.5784 2.75 15.75 2.75H4.75C3.64543 2.75 2.75 3.64543 2.75 4.75V15.25C2.75 16.3546 3.64543 17.25 4.75 17.25H8.25' />
          <path d='M10.75 7.75V16.25C10.75 16.8023 11.1977 17.25 11.75 17.25H16.25C16.8023 17.25 17.25 16.8023 17.25 16.25V7.75C17.25 7.19772 16.8023 6.75 16.25 6.75H11.75C11.1977 6.75 10.75 7.19772 10.75 7.75Z' />
          <path d='M5.75 5.75H7.25' />
        </>
      ) : (
        <>
          <path d='M6.25 13.25H4.75C3.64543 13.25 2.75 12.3546 2.75 11.25V4.75C2.75 3.64543 3.64543 2.75 4.75 2.75H11.75C12.5784 2.75 13.25 3.42157 13.25 4.25V4.25' />
          <path d='M5.25 5.25H6.25' />
          <rect x='8.75' y='6.75' width='4.5' height='6.5' rx='1' />
        </>
      )}
    </>
  );
}
