export function LifebuoyIconPath(props: { size?: 'sm' | 'base' }) {
  const { size = 'base' } = props;
  return (
    <>
      {size === 'base' ? (
        <>
          <path
            fillRule='evenodd'
            d='M4.87348 4.87348C6.18547 3.56148 7.99797 2.75 10 2.75C14.0041 2.75 17.25 5.99594 17.25 10C17.25 12.002 16.4385 13.8145 15.1265 15.1265C13.8145 16.4385 12.002 17.25 10 17.25C5.99594 17.25 2.75 14.0041 2.75 10C2.75 7.99797 3.56148 6.18547 4.87348 4.87348ZM10 13.25C11.7949 13.25 13.25 11.7949 13.25 10C13.25 8.20507 11.7949 6.75 10 6.75C8.20507 6.75 6.75 8.20507 6.75 10C6.75 11.7949 8.20507 13.25 10 13.25Z'
            fill='currentColor'
            fillOpacity={0.15}
          />
          <path
            d='M10 2C7.79107 2 5.79004 2.89625 4.34315 4.34315L5.40381 5.40381C6.5809 4.22672 8.20487 3.5 10 3.5V2ZM18 10C18 5.58172 14.4183 2 10 2V3.5C13.5899 3.5 16.5 6.41015 16.5 10H18ZM15.6569 15.6569C17.1037 14.21 18 12.2089 18 10H16.5C16.5 11.7951 15.7733 13.4191 14.5962 14.5962L15.6569 15.6569ZM10 18C12.2089 18 14.21 17.1037 15.6569 15.6569L14.5962 14.5962C13.4191 15.7733 11.7951 16.5 10 16.5V18ZM2 10C2 14.4183 5.58172 18 10 18V16.5C6.41015 16.5 3.5 13.5899 3.5 10H2ZM4.34315 4.34315C2.89625 5.79004 2 7.79107 2 10H3.5C3.5 8.20487 4.22672 6.5809 5.40381 5.40381L4.34315 4.34315ZM12.5 10C12.5 11.3807 11.3807 12.5 10 12.5V14C12.2091 14 14 12.2091 14 10H12.5ZM10 7.5C11.3807 7.5 12.5 8.61929 12.5 10H14C14 7.79086 12.2091 6 10 6V7.5ZM7.5 10C7.5 8.61929 8.61929 7.5 10 7.5V6C7.79086 6 6 7.79086 6 10H7.5ZM10 12.5C8.61929 12.5 7.5 11.3807 7.5 10H6C6 12.2091 7.79086 14 10 14V12.5Z'
            fill='currentColor'
            fillOpacity={0.15}
            stroke='none'
          />
          <path d='M5 5L7.5 7.5' />
          <path d='M15 5L12.5 7.5' />
          <path d='M12.5 12.5L15 15' />
          <path d='M7.5 12.5L5 15' />
        </>
      ) : (
        <>
          <path d='M6.76256 6.76256C6.44588 7.07925 6.25 7.51675 6.25 8C6.25 8.9665 7.0335 9.75 8 9.75C8.48325 9.75 8.92075 9.55412 9.23744 9.23744M6.76256 6.76256C7.07925 6.44588 7.51675 6.25 8 6.25C8.48325 6.25 8.92075 6.44588 9.23744 6.76256M6.76256 6.76256L4.28769 4.28769M4.28769 4.28769C3.33763 5.23775 2.75 6.55025 2.75 8C2.75 9.44975 3.33763 10.7622 4.28769 11.7123M4.28769 4.28769C5.23775 3.33763 6.55025 2.75 8 2.75C9.44975 2.75 10.7622 3.33763 11.7123 4.28769M9.23744 6.76256C9.55412 7.07925 9.75 7.51675 9.75 8C9.75 8.48325 9.55412 8.92075 9.23744 9.23744M9.23744 6.76256L11.7123 4.28769M11.7123 4.28769C12.6624 5.23775 13.25 6.55025 13.25 8C13.25 9.44975 12.6624 10.7622 11.7123 11.7123M9.23744 9.23744L11.7123 11.7123M11.7123 11.7123C10.7622 12.6624 9.44975 13.25 8 13.25C6.55025 13.25 5.23775 12.6624 4.28769 11.7123M4.28769 11.7123L6.76256 9.23744' />
        </>
      )}
    </>
  );
}
