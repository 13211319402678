export function CheckCircleIconPath(props: { size?: 'sm' | 'base' }) {
  const { size = 'base' } = props;
  return (
    <>
      {size === 'base' ? (
        <>
          <circle
            cx='10'
            cy='10'
            r='7.25'
            fill='currentColor'
            fillOpacity={0.15}
          />
          <path d='M7.75 10.45L9.55 12.25L12.25 7.75' />
        </>
      ) : (
        <>
          <circle cx='8' cy='8' r='5.25' />
          <path d='M6.25 8.35L7.65 9.75L9.75 6.25' />
        </>
      )}
    </>
  );
}
