export function ChevronUpDownIconPath(props: { size?: 'sm' | 'base' }) {
  const { size = 'base' } = props;
  return (
    <>
      {size === 'base' ? (
        <>
          <path d='M6.75 13.75L10 17.25L13.25 13.75' />
          <path d='M6.75 6.25L10 2.75L13.25 6.25' />
        </>
      ) : (
        <>
          <path d='M5.75 5.25L8 2.75L10.25 5.25' />
          <path d='M5.75 10.75L8 13.25L10.25 10.75' />
        </>
      )}
    </>
  );
}
