import { createFont } from './lib/create-font';

export const suisse = createFont({
  variable: '--font-suisse',
  version: 1,
  fallback: {
    fontFace: {
      src: { local: 'Arial' },
      ascentOverride: '95.68%',
      descentOverride: '30.18%',
      lineGapOverride: '0%',
      sizeAdjust: '103.05%',
    },
  },
  fontFace: [
    {
      src: {
        url: '/fonts/suisse/SuisseIntl-Bold-WebS.woff2',
        format: 'woff2',
      },
      fontDisplay: 'swap',
      fontStyle: 'normal',
      fontWeight: '700',
    },
    {
      src: {
        url: '/fonts/suisse/SuisseIntl-BoldItalic-WebS.woff2',
        format: 'woff2',
      },
      fontDisplay: 'swap',
      fontStyle: 'italic',
      fontWeight: '700',
    },
    {
      src: {
        url: '/fonts/suisse/SuisseIntl-SemiBold-WebS.woff2',
        format: 'woff2',
      },
      fontStyle: 'normal',
      fontDisplay: 'swap',
      fontWeight: '600',
    },
    {
      src: {
        url: '/fonts/suisse/SuisseIntl-SemiBoldItalic-WebS.woff2',
        format: 'woff2',
      },
      fontStyle: 'italic',
      fontDisplay: 'swap',
      fontWeight: '600',
    },
    {
      src: {
        url: '/fonts/suisse/SuisseIntl-Medium-WebS.woff2',
        format: 'woff2',
      },
      fontStyle: 'normal',
      fontDisplay: 'swap',
      fontWeight: '500',
    },
    {
      src: {
        url: '/fonts/suisse/SuisseIntl-MediumItalic-WebS.woff2',
        format: 'woff2',
      },
      fontStyle: 'italic',
      fontDisplay: 'swap',
      fontWeight: '500',
    },
    {
      src: {
        url: '/fonts/suisse/SuisseIntl-Book-WebS.woff2',
        format: 'woff2',
      },
      fontStyle: 'normal',
      fontDisplay: 'swap',
      fontWeight: '450',
    },
    {
      src: {
        url: '/fonts/suisse/SuisseIntl-BookItalic-WebS.woff2',
        format: 'woff2',
      },
      fontStyle: 'italic',
      fontDisplay: 'swap',
      fontWeight: '450',
    },
    {
      src: {
        url: '/fonts/suisse/SuisseIntl-Regular-WebS.woff2',
        format: 'woff2',
      },
      fontStyle: 'normal',
      fontDisplay: 'swap',
      fontWeight: '400',
    },
    {
      src: {
        url: '/fonts/suisse/SuisseIntl-RegularItalic-WebS.woff2',
        format: 'woff2',
      },
      fontStyle: 'italic',
      fontDisplay: 'swap',
      fontWeight: '400',
    },
  ],
});

export const sohneMono = createFont({
  variable: '--font-sohne-mono',
  version: 1,
  fallback: {
    fontFamily: [
      'ui-monospace',
      'SFMono-Regular',
      'Menlo',
      'Monaco',
      'Consolas',
      '"Liberation Mono"',
      '"Courier New"',
      'monospace',
    ],
  },
  fontFace: [
    {
      src: {
        url: '/fonts/sohne-mono/sohne-mono-buch.woff2',
        format: 'woff2',
      },
      fontDisplay: 'swap',
      fontStyle: 'normal',
      fontWeight: '400',
    },
    {
      src: {
        url: '/fonts/sohne-mono/sohne-mono-kraftig.woff2',
        format: 'woff2',
      },
      fontDisplay: 'swap',
      fontStyle: 'normal',
      fontWeight: '500',
    },
  ],
});

export const geistNumbers = createFont({
  variable: '--font-geist-numbers',
  version: 1,
  fontFace: [
    {
      src: {
        url: '/fonts/geist-numbers/Geist-Regular-subset.woff2',
        format: 'woff2',
      },
      fontDisplay: 'swap',
      fontStyle: 'normal',
      fontWeight: '400',
    },
    {
      src: {
        url: '/fonts/geist-numbers/Geist-Medium-subset.woff2',
        format: 'woff2',
      },
      fontDisplay: 'swap',
      fontStyle: 'normal',
      fontWeight: '500',
    },
    {
      src: {
        url: '/fonts/geist-numbers/Geist-SemiBold-subset.woff2',
        format: 'woff2',
      },
      fontDisplay: 'swap',
      fontStyle: 'normal',
      fontWeight: '600',
    },
  ],
});

export const preload = [
  ...suisse.preload,
  ...sohneMono.preload,
  ...geistNumbers.preload,
];
