import React from 'react';
import { stripProtocol } from '@utils/url';
import { useFormContext } from 'react-hook-form';
import { UrlInput } from './UrlInput';
import type { ProdInstanceForm } from './CreateProdInstanceModal';
import { PreviewInstanceDetails } from './PreviewInstanceDetails';
import { useFeatureFlags } from '@hooks/useFeatureFlags';
import { MultipleAppsExtraFields } from './MultipleAppsExtraFields';

export function HomeUrlForm() {
  const { setValue, getValues } = useFormContext<ProdInstanceForm>();
  const { featureFlags, isLoading: featureFlagsIsLoading } = useFeatureFlags();
  const isUseSuffixedCookiesEnabled = featureFlags.use_suffixed_cookies;

  const onHomeUrlBlur = () => {
    setValue('home_url', stripProtocol(getValues('home_url')));
  };

  if (featureFlagsIsLoading) {
    return null;
  }

  return (
    <>
      <UrlInput onBlur={onHomeUrlBlur} />
      {!isUseSuffixedCookiesEnabled ? (
        <PreviewInstanceDetails />
      ) : (
        <MultipleAppsExtraFields />
      )}
    </>
  );
}
