import { UpgradeMembershipLimit } from './UpgradeMembershipLimit';

interface UpgradeMembershipLimitSection {
  totalMembers: number;
}

export function UpgradeMembershipLimitSection({
  totalMembers,
}: UpgradeMembershipLimitSection) {
  return (
    <div className='flex items-center gap-4 rounded-lg bg-legacyGray-50 px-4 py-3'>
      <div className='relative aspect-square h-6 rounded-full bg-legacyGray-300'>
        <svg
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          className='absolute inset-0 h-full w-full -rotate-90 stroke-legacyGray-800'
        >
          <circle
            cx='12'
            cy='12'
            r='10'
            strokeWidth='4'
            strokeDasharray={62.8} // 2 * 3.14 * r
            strokeDashoffset={(62.8 * (3 - totalMembers)) / 3}
            strokeLinecap='round'
          />
        </svg>

        <span className='absolute inset-1 inline-flex items-center justify-center rounded-full bg-white text-xs font-medium text-legacyGray-700'>
          {totalMembers}
        </span>
      </div>

      <UpgradeMembershipLimit />
    </div>
  );
}
