export function MinusIconPath(props: { size?: 'sm' | 'base' }) {
  const { size = 'base' } = props;
  return (
    <>
      {size === 'base' ? (
        <path d='M6.75 10H13.25' />
      ) : (
        <path d='M5.75 8H10.25' />
      )}
    </>
  );
}
