import { DELETE, GET, PATCH, POST } from '@utils/api';

import {
  ActiveDisplayConfig,
  Instance,
  InstanceDeployStatus,
  InstanceKey,
  OrganizationSettings,
  Theme,
  UpdateInstanceParams,
  UpdateOrganizationSettingsParams,
  UpdatePatchMePasswordParams,
  DisplayConfigUrls,
  WebhookSettings,
} from './types';

export const getInstance = ({
  path,
  token,
}: {
  path: string;
  token: string;
}): Promise<Instance> => {
  return GET<Instance>({ path, token });
};

export const updateInstance = ({
  path,
  token,
  data,
}: {
  path: string;
  token: string;
  data: UpdateInstanceParams;
}): Promise<Instance> => {
  return PATCH<Instance>({ path, token, data });
};

export const deleteInstance = ({
  path,
  token,
}: {
  path: string;
  token: string;
}): Promise<never> => {
  return DELETE<never>({ path, token, data: {} });
};

export const getTheme = ({
  path,
  token,
}: {
  path: string;
  token: string;
}): Promise<Theme> => {
  return GET<Theme>({ path, token });
};

export const updateTheme = ({
  path,
  token,
  data,
}: {
  path: string;
  token: string;
  data: Partial<Theme>;
}): Promise<Theme> => {
  return PATCH<Theme>({ path, token, data });
};

export const getDisplayConfig = ({
  path,
  token,
}: {
  path: string;
  token: string;
}): Promise<ActiveDisplayConfig> => {
  return GET<ActiveDisplayConfig>({ path, token });
};

export const getDisplayConfigUrls = ({
  path,
  token,
}: {
  path: string;
  token: string;
}): Promise<DisplayConfigUrls> => {
  return GET<DisplayConfigUrls>({ path, token });
};

export const updateDisplayConfig = ({
  path,
  token,
  data,
}: {
  path: string;
  token: string;
  data: Partial<ActiveDisplayConfig>;
}): Promise<ActiveDisplayConfig> => {
  return PATCH<ActiveDisplayConfig>({ path, token, data });
};

export const updateDisplayConfigUrls = ({
  path,
  token,
  data,
}: {
  path: string;
  token: string;
  data: Partial<DisplayConfigUrls>;
}): Promise<DisplayConfigUrls> => {
  return PATCH<DisplayConfigUrls>({ path, token, data });
};

export const getInstanceKeys = ({
  path,
  token,
}: {
  path: string;
  token: string;
}): Promise<InstanceKey[]> => {
  const res = GET<InstanceKey[]>({ path, token });
  return res;
};

export const createInstanceKey = ({
  path,
  token,
  data,
}: {
  path: string;
  token: string;
  data: { name: string };
}): Promise<InstanceKey> => {
  const res = POST<InstanceKey>({ path, token, data });
  return res;
};

export const deleteInstanceKey = ({
  path,
  token,
  data,
}: {
  path: string;
  token: string;
  data: { id: string };
}): Promise<never> => {
  const { id } = data;
  const dataWithId = `${path}/${id}`;
  const res = DELETE<never>({ path: dataWithId, token, data: {} });
  return res;
};

export const showInstanceKey = ({
  path,
  token,
  data,
}: {
  path: string;
  token: string;
  data: { id: string };
}): Promise<InstanceKey> => {
  const { id } = data;
  const dataWithId = `${path}/${id}`;
  const res = GET<InstanceKey>({ path: dataWithId, token, data: {} });
  return res;
};

export const getWebhookSettings = ({
  path,
  token,
}: {
  path: string;
  token: string;
}): Promise<WebhookSettings> => {
  return GET<WebhookSettings>({ path, token });
};

export const createWebhook = ({
  path,
  token,
}: {
  path: string;
  token: string;
}): Promise<WebhookSettings> => {
  return POST<WebhookSettings>({ path, token, data: {} });
};

export const getDeployStatus = ({
  path,
  token,
}: {
  path: string;
  token: string;
}): Promise<InstanceDeployStatus> => {
  return GET<InstanceDeployStatus>({ path, token });
};

export const getOrganizationSettings = ({
  path,
  token,
}: {
  path: string;
  token: string;
}): Promise<OrganizationSettings> => {
  return GET<OrganizationSettings>({ path, token });
};

export const updateOrganizationSettings = ({
  path,
  token,
  data,
}: {
  path: string;
  token: string;
  data: UpdateOrganizationSettingsParams;
}): Promise<OrganizationSettings> => {
  return PATCH<OrganizationSettings>({ path, token, data });
};

export const patchMePassword = ({
  path,
  token,
  data,
}: {
  path: string;
  token: string;
  data: UpdatePatchMePasswordParams;
}): Promise<never> => {
  return PATCH<never>({ path, token, data });
};

export const updateCommunication = ({ path, token, data }): Promise<never> => {
  return PATCH<never>({ path, token, data });
};

export const bulkUpdateUserActions = ({
  path,
  token,
  data,
}): Promise<never> => {
  return POST<never>({ path, token, data });
};
