import React, { forwardRef } from 'react';
import { Slot, Slottable, type AsChildProp } from '../../utils/Slot';
import { Spinner } from '../../components/Spinner';
import { cva, cx, type VariantProps } from 'cva';
import { Icon, type IconName } from './Icon';
import { AnimatePresence, motion } from 'framer-motion';

export type ButtonRef = HTMLButtonElement;

export interface ButtonProps
  extends Pick<
      React.ComponentProps<'button'>,
      | 'aria-label'
      | 'className'
      | 'id'
      | 'disabled'
      | 'form'
      | 'onBlur'
      | 'onClick'
      | 'onFocus'
      | 'role'
      | 'type'
    >,
    AsChildProp,
    VariantProps<typeof button> {
  children: React.ReactNode;
  prefix?: string;
  icon?: IconName;
  iconPosition?: 'left' | 'right';
  loading?: boolean;
  fullWidth?: boolean;
  justifyContent?: 'center' | 'between';
}
/**
 * Note:
 * - We apply our border via `ring-offset-` utilities
 * - We apply our "focus ring" via `ring-` utilities
 * - [data-state=open] is applied when used in conjuction with `Select`
 * - We prefer `focus-visible:` over `focus:`
 *   See https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-visible#focus_vs_focus-visible
 * - We only apply hover or focus states when a button is **not** `disabled`
 */
const button = cva({
  base: [
    'group relative inline-flex select-none items-center justify-center rounded bg-[--button-color-bg] font-medium outline-none transition',
    'before:bg-[radial-gradient(75%_75%_at_center_top,theme(colors.white/0.2),transparent)] before:transition after:transition',
    'ring-[0.1875rem] ring-transparent ring-offset-[0.0625rem] ring-offset-[--button-color-border]',
    'focus-visible:ring-[--button-color-ring]',
    'overflow-hidden',
  ],
  variants: {
    size: {
      xs: 'px-2 py-xs text-xs',
      sm: 'px-2 py-1 text-sm',
      base: 'px-3 py-1.5 text-base',
      lg: 'px-5 py-3 text-base',
    },
    shape: {
      rectangle: '',
      square: 'flex-none',
    },
    disabled: {
      true: 'cursor-not-allowed opacity-50',
      false: null,
    },
    loading: {
      true: 'pointer-events-none',
      false: null,
    },
    intent: {
      primary: [
        [
          '[--button-color-border:--button-color-bg]',
          '[--button-color-bg:theme(colors.purple.500)]',
          '[--button-color-icon:theme(colors.white/0.8)]',
          '[--button-color-icon-hover:currentColor]',
          '[--button-color-ring:theme(colors.purple.500/0.2)]',
          '[--button-color-text:theme(colors.white)]',
          '[--button-text-shadow:0px_1px_1px_theme(colors.purple.700)]',
        ],
        'relative shadow-[inset_0px_1px_0px_theme(colors.white/0.08),inset_0px_-1px_0px_theme(colors.white/0.04),0px_0px_0px_1px_theme(colors.purple.500),0px_2px_2px_-1px_theme(colors.purple.900/24%),0px_4px_4px_-2px_theme(colors.purple.900/12%)]',
        'before:absolute before:inset-0 before:rounded-inherit before:transition-opacity',
      ],
      upgrade: [
        [
          '[--button-color-border:--button-color-bg]',
          '[--button-color-bg:theme(colors.sky.400)]',
          '[--button-color-icon:theme(colors.sky.950/0.8)]',
          '[--button-color-icon-hover:currentColor]',
          '[--button-color-ring:theme(colors.sky.500/0.2)]',
          '[--button-color-text:theme(colors.sky.950)]',
          '[--button-text-shadow:0px_1px_0px_theme(colors.white/0.2)]',
        ],
        'relative bg-sky-400 shadow-[inset_0px_0px_0px_1px_theme(colors.white/0.06),inset_0px_1px_0px_theme(colors.white/0.06),inset_0px_-1px_0px_theme(colors.white/0.04),0px_0px_0px_1px_theme(colors.sky.400),0px_2px_2px_-1px_theme(colors.sky.900/24%),0px_4px_4px_-2px_theme(colors.sky.900/12%)]',
        'before:absolute before:inset-0 before:rounded-inherit before:transition-opacity',
      ],
      secondary: [
        [
          '[--button-color-border:theme(colors.black/0.15)]',
          '[--button-color-bg:theme(colors.white)]',
          '[--button-color-icon:theme(textColor.tertiary)]',
          '[--button-color-icon-hover:theme(textColor.primary)]',
          '[--button-color-ring:theme(colors.black/0.08)]',
          '[--button-color-text:theme(textColor.primary)]',
        ],
        'shadow shadow-black/[0.08] transition',
        'before:absolute before:inset-0 before:rounded-inherit before:bg-gradient-to-b before:from-black/0 before:from-50% before:to-black/[0.02] before:transition-opacity',
      ],
      ghost: [
        '[--button-color-border:--button-color-bg]',
        '[--button-color-bg:theme(colors.transparent)]',
        '[--button-color-icon:theme(textColor.tertiary)]',
        '[--button-color-icon-hover:theme(textColor.primary)]',
        '[--button-color-ring:theme(colors.black/0.08)]',
        '[--button-color-text:theme(textColor.primary)]',
      ],
      'ghost-primary': [
        '[--button-color-border:--button-color-bg]',
        '[--button-color-bg:theme(colors.transparent)]',
        '[--button-color-icon:theme(colors.purple.500)]',
        '[--button-color-icon-hover:theme(colors.purple.500)]',
        '[--button-color-ring:theme(colors.black/0.08)]',
        '[--button-color-text:theme(colors.purple.500)]',
      ],
      danger: [
        [
          '[--button-color-border:--button-color-bg]',
          '[--button-color-bg:theme(colors.legacy-red.500)]',
          '[--button-color-icon:theme(colors.white/0.8)]',
          '[--button-color-icon-hover:currentColor]',
          '[--button-color-ring:theme(colors.legacy-red.500/0.2)]',
          '[--button-color-text:theme(colors.white)]',
          '[--button-text-shadow:0px_1px_3px_theme(colors.black/0.25)]',
        ],
        'relative shadow-[0px_2px_3px_theme(colors.legacyGray.800/0.2),_0px_0px_0px_1px_theme(colors.legacy-red.500),_inset_0px_1px_0px_theme(colors.white/0.07)]',
        'before:absolute before:inset-0 before:rounded-inherit before:transition-opacity',
      ],
    },
  },
  compoundVariants: [
    {
      disabled: false,
      intent: 'primary',
      className: 'hover:before:opacity-25',
    },
    {
      disabled: false,
      intent: 'secondary',
      className: 'hover:[--button-color-bg:theme(colors.legacyGray.50)]',
    },
    {
      disabled: false,
      intent: 'upgrade',
      className: 'hover:before:opacity-25',
    },
    {
      disabled: false,
      intent: 'ghost',
      className: [
        'hover:[--button-color-bg:theme(colors.legacyGray.100)]',
        'focus-visible:[--button-color-border:theme(colors.black/0.15)]',
        // data-[state=open] is applied when used in conjuction with `Select`
        'data-[state=open]:[--button-color-bg:theme(colors.legacyGray.100)]',
      ],
    },
    {
      disabled: false,
      intent: 'danger',
      className: 'hover:before:opacity-25',
    },
  ],
});

/**
 * **Note**:
 *
 * `disabled` should be avoided when using with `asChild`, as it may lead to
 * invalid HTML (e.g. on an `a` tag)
 */
export const Button = forwardRef<ButtonRef, ButtonProps>(function Button(
  {
    asChild,
    children,
    className,
    icon,
    iconPosition = 'right',
    prefix,
    disabled = false,
    intent = 'secondary',
    shape = 'rectangle',
    type = asChild ? undefined : 'button',
    size = 'base',
    loading = false,
    fullWidth = false,
    justifyContent = 'center',
    ...props
  },
  ref,
) {
  const Comp = asChild ? Slot : 'button';

  return (
    <Comp
      ref={ref}
      className={cx(
        button({ intent, disabled, loading, shape, size, className }),
        fullWidth && 'w-full rounded-sm',
      )}
      type={type}
      disabled={disabled || loading}
      {...props}
    >
      <Slottable child={children}>
        {child => (
          <React.Fragment>
            <span className='inline-flex w-full'>
              <motion.span
                transition={{
                  type: 'spring',
                  stiffness: 200,
                  damping: 20,
                  mass: 0.8,
                }}
                animate={{
                  y: loading ? 'var(--button-height)' : '0%',
                }}
                className={cx(
                  'inline-flex w-full items-center gap-1.5 whitespace-nowrap text-[--button-color-text] drop-shadow-[--button-text-shadow] transition',
                  '[--button-height:calc((theme(spacing.3)+theme(fontSize.base[1].lineHeight))*-1)]',
                  {
                    'justify-center': justifyContent === 'center',
                    'justify-between': justifyContent === 'between',
                  },
                )}
              >
                {
                  {
                    rectangle: (
                      <>
                        {icon && iconPosition === 'left' && (
                          <span
                            className={cx(
                              'text-[--button-color-icon]',
                              !disabled &&
                                'transition-colors group-hover:text-[--button-color-icon-hover]',
                            )}
                          >
                            <Icon name={icon} size='sm' />
                          </span>
                        )}
                        <span className='flex items-center gap-1.5'>
                          {prefix && (
                            <span className='font-book text-tertiary'>
                              {prefix}
                            </span>
                          )}

                          {child}
                        </span>
                        {icon && iconPosition === 'right' && (
                          <span
                            className={cx(
                              'text-[--button-color-icon]',
                              !disabled &&
                                'transition-colors group-hover:text-[--button-color-icon-hover]',
                            )}
                          >
                            <Icon name={icon} size='sm' />
                          </span>
                        )}
                      </>
                    ),
                    square: (
                      <span
                        className={cx(
                          { base: '-mx-1.5', sm: null, xs: '-mx-1', lg: null }[
                            size
                          ],
                        )}
                      >
                        {icon && <Icon name={icon} size='sm' />}
                        <span className='sr-only'>{child}</span>
                      </span>
                    ),
                  }[shape]
                }
              </motion.span>
              <AnimatePresence>
                {loading && (
                  <motion.span
                    transition={{
                      type: 'spring',
                      stiffness: 200,
                      damping: 20,
                      mass: 0.8,
                    }}
                    initial={{
                      y: '100%',
                    }}
                    animate={{
                      y: '0%',
                    }}
                    exit={{
                      y: '100%',
                    }}
                    className='absolute inset-0 z-10 flex items-center justify-center text-[--button-color-text]'
                  >
                    <Spinner size='sm' />
                  </motion.span>
                )}
              </AnimatePresence>
            </span>
          </React.Fragment>
        )}
      </Slottable>
    </Comp>
  );
});
