import React from 'react';
import { Radio, Text, useRadioGroupContext } from '@chakra-ui/react';

type RadioButtonCardProps = {
  label: React.ReactNode;
  hint?: React.ReactNode;
  value: string;
  children?: React.ReactNode;
  isDisabled?: boolean;
  config?: React.ReactNode;
  onChange?: () => void;
};

export function RadioButtonCard(props: RadioButtonCardProps) {
  const { label, hint, value, children, isDisabled, config, onChange } = props;
  const radioGroupContext = useRadioGroupContext();

  return (
    <label
      data-checked={radioGroupContext.value === value ? true : undefined}
      className='relative flex cursor-pointer gap-4 rounded-lg border border-transparent p-4 transition-all duration-150 hover:border-[#F5F5F5] hover:bg-[#F5F5F5] data-[checked]:border-[#F5F5F5] data-[checked]:bg-[#F5F5F5]'
    >
      <div className='size-5'>
        <Radio
          isDisabled={isDisabled}
          value={value}
          size='lg'
          onChange={onChange}
        />
      </div>
      <div className='flex flex-col gap-0.5'>
        <Text textStyle='md-normal'>{label}</Text>
        {hint && (
          <Text textStyle='sm-normal' color='legacyGray.500'>
            {hint}
          </Text>
        )}
        {children}
      </div>
      {config}
    </label>
  );
}
