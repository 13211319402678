import {
  ApplicationContextType,
  useApplicationContext,
} from '@context/vitals/application/Provider';
import { Application } from '@dapi/applications/types';
import { EnvironmentType } from '@dapi/instances/types';

export const useApplication = (): ApplicationContextType & {
  /**
   * @deprecated Use data instead
   *
   * The 'instance' property is deprecated in favor of 'data'.
   * This change was made to standardize the way a value is returned
   * from the API related hooks.
   *
   * Instead of:
   * const { application } = useApplication()
   *
   * Use:
   * const { data } = useApplication()
   */
  application: Application;

  /**
   * This property is used to determine if the user should be shown a notice
   * to enable smart bot protection.
   * This notice should be shown if any of the current user application has an
   * production instance with invisible captcha enabled.
   */
  shouldShowSmartBotProtectionNotice: boolean;
} => {
  const res = useApplicationContext();

  // Checks if any of the current user application has an production instance with invisible captcha enabled.
  // If so, we should show a notice to the user to enable smart bot protection.
  const shouldShowSmartBotProtectionNotice = res.data?.instances.some(
    instance =>
      instance.environment_type === EnvironmentType.Production &&
      instance.active_auth_config.captcha_widget_type === 'invisible',
  );

  // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
  return { application: res.data, shouldShowSmartBotProtectionNotice, ...res };
};
