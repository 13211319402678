import { mutate } from 'swr';
import useSWRMutation from 'swr/mutation';
import { Key } from 'swr';

import { MutationResponse, MutationFetch } from '@typeDefs/API';
import { SWRMutationConfiguration } from 'swr/mutation';
import { useSession } from '@clerk/nextjs';

import { useLocation } from '@hooks/useLocation';
import { MutableRefObject } from 'react';

type Options<Data, ExtraArgs, Error = any> = SWRMutationConfiguration<
  Data,
  Error,
  Key,
  ExtraArgs
> & {
  /** pathPrefix can be used for endpoints that they are not using as base path the v1/instances/:instanceId
   * some of those endpoints are organizations and applications
   */
  pathPrefix?: string;

  /** withApplicationBasePath can be used for endpoints that they are we like to use  v1/applications/:applicationId as base path
   */
  withApplicationBasePath?: boolean;
  /**
   * abortControllerRef is a reference to a AbortController instance
   */
  abortControllerRef?: MutableRefObject<AbortController>;
};

type PathOptions = {
  path: string;
  cacheKey?: string;
};

const makePathAndCacheKey = ({
  pathPrefix,
  instanceId,
  path,
  cacheKey,
  applicationId,
}) => {
  if (pathPrefix) {
    return {
      enhancedPath: `${pathPrefix}${path}`,
      enhancedCacheKey: cacheKey
        ? `${pathPrefix}${cacheKey}`
        : `${pathPrefix}${path}`,
    };
  }

  if (applicationId) {
    return {
      enhancedPath: `v1/applications/${applicationId}${path}`,
      enhancedCacheKey: cacheKey
        ? `v1/applications/${applicationId}${cacheKey}`
        : `v1/applications/${applicationId}${path}`,
    };
  }

  return {
    enhancedPath: `v1/instances/${instanceId}${path}`,
    enhancedCacheKey:
      cacheKey || cacheKey === ''
        ? `v1/instances/${instanceId}${cacheKey}`
        : `v1/instances/${instanceId}${path}`,
  };
};

export const useMutation = <Data, ExtraArgs = never, Error = any>(
  pathOptions: PathOptions,
  fetcher: MutationFetch<Data, ExtraArgs>,
  options?: Options<Data, ExtraArgs, Error>,
): MutationResponse<Data, ExtraArgs> => {
  const { path, cacheKey } = pathOptions;
  const { pathPrefix, withApplicationBasePath } = options || {};
  const { session } = useSession();
  const { instanceId, applicationId } = useLocation();

  const { enhancedPath, enhancedCacheKey } = makePathAndCacheKey({
    pathPrefix,
    instanceId,
    path,
    cacheKey,
    applicationId: withApplicationBasePath ? applicationId : undefined,
  });

  const extendedFetcher = async (_, { arg }) => {
    const token = await session?.getToken();
    return fetcher({
      path: enhancedPath,
      // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
      token,
      data: arg,
      abortControllerRef: options?.abortControllerRef,
    });
  };

  return useSWRMutation(enhancedCacheKey, extendedFetcher, {
    ...options,
  });
};
export { mutate };
