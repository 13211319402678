'use client';

import { Billing } from '@/components/planAndBilling/organization/Billing';
import { BillingProvider } from '@/components/planAndBilling/organization/BillingProvider';
import { Icon } from '@clerk/ceramic/experimental/components/Icon';
import { OrganizationSwitcher as ClerkOrganizationSwitcher } from '@clerk/nextjs';
import { useMembership } from '@hooks/useMembership';
export function OrganizationSwitcher() {
  const { isAdmin, isLoaded } = useMembership();

  if (!isLoaded) {
    return null;
  }
  return (
    <BillingProvider>
      <ClerkOrganizationSwitcher
        afterSelectPersonalUrl='/'
        afterSelectOrganizationUrl='/'
        afterCreateOrganizationUrl='/?clear_last_active=true'
        afterLeaveOrganizationUrl='/'
        skipInvitationScreen={true}
        appearance={{
          layout: {
            shimmer: false,
          },
          elements: {
            avatarBox: 'size-4 bg-transparent rounded-[0.3125rem] mr-1',

            rootBox:
              '[&_.cl-organizationPreview]:after:opacity-30 [&_.cl-organizationPreview]:after:size-4 [&_.cl-organizationPreview]:after:bg-chevron-up-down [&_.cl-organizationPreview]:hover:after:opacity-55 [&_.cl-organizationPreview]:after:transition [&_.cl-userPreview]:after:opacity-30 [&_.cl-userPreview]:after:size-4 [&_.cl-userPreview]:after:bg-chevron-up-down [&_.cl-userPreview]:hover:after:opacity-55 [&_.cl-userPreview]:after:transition',

            userPreviewMainIdentifier: '[&>span]:text-base',
            userPreview: 'gap-1',
            userPreviewMainIdentifier__personalWorkspace:
              'font-book text-gray-1200',

            organizationPreview: 'p-0 gap-1',
            organizationPreviewMainIdentifier:
              'text-base font-book text-gray-1200',
            organizationPreviewMainIdentifier__organizationSwitcherListedOrganization:
              'font-book',
            organizationPreviewSecondaryIdentifier:
              'text-gray-1100 text-2xs leading-[0.8em] relative top-px',
            organizationSwitcherPopoverActionButtonIconBox: 'w-auto basis-auto',
            organizationSwitcherPopoverActionButtonIcon__createOrganization:
              'size-6 scale-[1.25]',
            organizationSwitcherPopoverActionButton__createOrganization:
              'py-1.5 pl-2 pr-2.5 [font-size:inherit] hover:text-gray-1200 gap-1.5 text-gray-1100 dark:[border-top-color:theme(colors.gray.400)_!important] dark:hover:bg-gray-400',
            organizationSwitcherPopoverActionButton__manageOrganization:
              'text-gray-1100 dark:bg-gray-500 dark:hover:bg-gray-600 hover:text-gray-1200',
            organizationSwitcherPopoverActions:
              '[&>:first-child]:p-3 dark:[&>div]:[border-top-color:theme(colors.gray.400)_!important]',
            organizationSwitcherPopoverCard:
              'w-[18.75rem] -translate-y-1 rounded-[0.875rem] [animation:none] border border-black/5 bg-white dark:bg-gray-400 shadow-lg dark:shadow-black/20',
            organizationSwitcherPopoverFooter:
              '[&>div]:p-3 bg-gray-200 text-sm dark:bg-gray-200 bg-[image:none] [&_p]:text-gray-1000 [&_p]:leading-none [&_a]:text-gray-1000 shadow-[inset_0_0_0_1px_rgb(255_255_255/0.01)] rounded-b-[0.8125rem]',
            organizationSwitcherPopoverMain:
              'bg-white border-b dark:bg-gray-300 shadow-sm shadow-black/[0.02] dark:shadow-black/[0.04] relative before:absolute before:pointer-events-none before:inset-0 dark:before:shadow-[inset_0_1px_0_rgb(255_255_255/0.04),inset_0_0_0_1px_rgb(255_255_255/0.02)] before:rounded-t-[0.8125rem] before:rounded-b-[0.5rem]',
            organizationSwitcherPreviewButton:
              'px-3 py-2 [&>svg]:text-gray-1000 *:text-gray-1100 *:hover:text-gray-1200 [&>svg]:hover:text-gray-1000 dark:[border-top-color:theme(colors.gray.400)_!important] dark:hover:bg-gray-400/60',
            organizationSwitcherTrigger:
              'group py-[0.3125rem] pl-2.5 pr-2 hover:border-black/5 hover:bg-white hover:shadow hover:shadow-black/2 relative isolate rounded border border-transparent font-book transition data-[open]:bg-white data-[open]:border-black/5 data-[open]:shadow data-[open]:shadow-black/2 [&_.cl-organizationPreview]:data-[open]:after:opacity-55 [&_.cl-userPreview]:data-[open]:after:opacity-55',
            organizationSwitcherTriggerIcon: 'hidden',
            organizationSwitcherPopoverInvitationActionsBox: 'px-3 py-1.5',
            organizationSwitcherPopoverInvitationActions: 'py-1.5 bg-gray-100',
            organizationSwitcherInvitationAcceptButton: 'bg-white',

            notificationBadge:
              'bg-purple text-xs font-number font-semibold min-w-4 px-1 animate-fade-in -translate-x-1 translate-y-1',
          },
          variables: {
            fontSize: 'inherit',
          },
        }}
      >
        {isAdmin && (
          <ClerkOrganizationSwitcher.OrganizationProfilePage
            label='Billing'
            url='billing'
            labelIcon={<Icon name='credit-card' size='sm' />}
          >
            <Billing />
          </ClerkOrganizationSwitcher.OrganizationProfilePage>
        )}
      </ClerkOrganizationSwitcher>
    </BillingProvider>
  );
}
