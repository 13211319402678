export function EllipsisVerticalIconPath(props: { size?: 'sm' | 'base' }) {
  const { size = 'base' } = props;
  return (
    <>
      {size === 'base' ? (
        <>
          <path d='M10.005 6.005L9.995 6.005M10.005 10.005H9.995M10.005 14.005H9.995' />
        </>
      ) : (
        <>
          <path d='M8 4V4.01' />
          <path d='M8 8V8.01' />
          <path d='M8 12V12.01' />
        </>
      )}
    </>
  );
}
