export function MonitorIconPath(props: { size?: 'sm' | 'base' }) {
  const { size = 'base' } = props;
  return (
    <>
      {size === 'base' ? (
        <>
          <path
            d='M3 4.38095C3 3.61827 3.61827 3 4.38095 3H16.119C16.8817 3 17.5 3.61827 17.5 4.38095V11.9762C17.5 12.7389 16.8817 13.3571 16.119 13.3571H4.38095C3.61827 13.3571 3 12.7389 3 11.9762V4.38095Z'
            className='fill-[--icon-fill,currentColor]'
            fillOpacity={0.15}
          />
          <path
            d='M10.25 13.7024V17.5M10.25 17.5H7.14286M10.25 17.5H13.3571M4.38095 13.3571H16.119C16.8817 13.3571 17.5 12.7389 17.5 11.9762V4.38095C17.5 3.61827 16.8817 3 16.119 3H4.38095C3.61827 3 3 3.61827 3 4.38095V11.9762C3 12.7389 3.61827 13.3571 4.38095 13.3571Z'
            strokeWidth='1.875'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </>
      ) : (
        <>
          <path
            d='M8 10.5V13.25M8 13.25H5.75M8 13.25H10.25M3.75 10.25H12.25C12.8023 10.25 13.25 9.80228 13.25 9.25V3.75C13.25 3.19772 12.8023 2.75 12.25 2.75H3.75C3.19772 2.75 2.75 3.19772 2.75 3.75V9.25C2.75 9.80228 3.19772 10.25 3.75 10.25Z'
            strokeWidth='1.5'
          />
        </>
      )}
    </>
  );
}
