import React from 'react';
import { Spinner } from '@clerk/ceramic/components/Spinner';

export function PageLoader() {
  return (
    <div className='flex h-[50vh] w-full items-center justify-center text-tertiary'>
      <Spinner color='tertiary' size='jumbo' />
    </div>
  );
}
