export function EyeSlashIconPath(props: { size?: 'sm' | 'base' }) {
  const { size = 'base' } = props;
  return (
    <>
      {size === 'base' ? (
        <>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            stroke='none'
            d='M10 16.25C15 16.25 17.25 11.5 17.25 10C17.25 8.5 15 3.75 10 3.75C5 3.75 2.75 8.5 2.75 10C2.75 11.5 5 16.25 10 16.25ZM10.5581 11.2902C10.7912 11.7132 10.9064 11.9221 11.4142 11.4142C12.1953 10.6332 12.1953 9.36684 11.4142 8.58579C10.6332 7.80474 9.36684 7.80474 8.58579 8.58579C8.04731 9.12427 8.55227 9.45887 9.17381 9.87071C9.45379 10.0562 9.75743 10.2574 10 10.5C10.2732 10.7732 10.4327 11.0627 10.5581 11.2902Z'
            fill='currentColor'
            fillOpacity={0.15}
          />
          <path d='M10 16.25C5 16.25 2.75 11.5 2.75 10C2.75 9.46185 3.03961 8.50537 3.64192 7.5M5.5 5.33916C6.62898 4.42145 8.12013 3.75 10 3.75C15 3.75 17.25 8.5 17.25 10C17.25 10.9036 16.4335 12.9865 14.6914 14.5' />
          <path d='M11.591 11.591C12.4697 10.7123 12.4697 9.28769 11.591 8.40901C10.7123 7.53033 9.28769 7.53033 8.40901 8.40901' />
          <path d='M2.75 2.75L17.25 17.25' />
        </>
      ) : (
        <>
          <path d='M2.949 7C2.80739 7.40946 2.75 7.76622 2.75 8C2.75 9 3.8 12.25 8 12.25C8.35547 12.25 8.68838 12.2267 9 12.1835M11.5 11.1743C12.8384 10.134 13.25 8.62607 13.25 8C13.25 7 12.2 3.75 8 3.75C6.7199 3.75 5.73242 4.05191 4.97809 4.5' />
          <path d='M8.88388 8.88388C9.37204 8.39573 9.37204 7.60427 8.88388 7.11611C8.39573 6.62796 7.60427 6.62796 7.11612 7.11611' />
          <path d='M3 3L13 13' />
        </>
      )}
    </>
  );
}
