export const colors = {
  legacyGray: {
    '25': '#FAFAFA',
    '50': '#F5F5F5',
    '100': '#EBEBEB',
    '200': '#D6D6D6',
    '300': '#B2B2B2',
    '400': '#919191',
    '500': '#737373',
    '600': '#525252',
    '700': '#424242',
    '800': '#292929',
    '900': '#141414',
  },
  primary: {
    '25': '#FAF9FF',
    '50': '#F4F2FF',
    '100': '#E9E4FF',
    '200': '#D3C8FF',
    '300': '#B6A3FF',
    '400': '#896CFF',
    '500': '#6C47FF',
    '600': '#5639CC',
    '700': '#412B99',
    '800': '#2B1C66',
    '900': '#1F0256',
  },
  danger: {
    '25': '#FFFBFA',
    '50': '#FEF3F2',
    '100': '#FEE4E2',
    '200': '#FECDCA',
    '300': '#FDA29B',
    '400': '#F97066',
    '500': '#F04438',
    '600': '#D92D20',
    '700': '#B42318',
    '800': '#912018',
    '900': '#7A271A',
  },
  warning: {
    '25': '#FFFCF5',
    '50': '#FFFAEB',
    '100': '#FEF0C7',
    '200': '#FEDF89',
    '300': '#FEC84B',
    '400': '#FDB022',
    '500': '#F79009',
    '600': '#DC6803',
    '700': '#B54708',
    '800': '#93370D',
    '900': '#7A2E0E',
  },
  success: {
    '25': '#F6FEF9',
    '50': '#ECFDF3',
    '100': '#D1FADF',
    '200': '#A6F4C5',
    '300': '#6CE9A6',
    '400': '#32D583',
    '500': '#12B76A',
    '600': '#039855',
    '700': '#027A48',
    '800': '#05603A',
    '900': '#054F31',
  },
  info: {
    '25': '#F5FAFF',
    '50': '#EFF8FF',
    '100': '#D1E9FF',
    '200': '#B2DDFF',
    '300': '#84CAFF',
    '400': '#53B1FD',
    '500': '#2E90FA',
    '600': '#1570EF',
    '700': '#175CD3',
    '800': '#1849A9',
    '900': '#194185',
  },
  whiteAlpha: {
    '50': 'rgba(255, 255, 255, 0.02)',
    '100': 'rgba(255, 255, 255, 0.04)',
    '200': 'rgba(255, 255, 255, 0.06)',
    '300': 'rgba(255, 255, 255, 0.08)',
    '400': 'rgba(255, 255, 255, 0.16)',
    '500': 'rgba(255, 255, 255, 0.24)',
    '600': 'rgba(255, 255, 255, 0.32)',
    '700': 'rgba(255, 255, 255, 0.64)',
    '800': 'rgba(255, 255, 255, 0.80)',
    '900': 'rgba(255, 255, 255, 1)',
  },

  blackAlpha: {
    '50': 'rgba(0, 0, 0, 0.02)',
    '100': 'rgba(0, 0, 0, 0.04)',
    '200': 'rgba(0, 0, 0, 0.06)',
    '300': 'rgba(0, 0, 0, 0.08)',
    '400': 'rgba(0, 0, 0, 0.16)',
    '500': 'rgba(0, 0, 0, 0.24)',
    '600': 'rgba(0, 0, 0, 0.32)',
    '700': 'rgba(0, 0, 0, 0.64)',
    '800': 'rgba(0, 0, 0, 0.80)',
    '900': 'rgba(0, 0, 0, 1)',
  },
};

export type Colors = typeof colors;
