import { useApplication } from '@hooks/useApplication';
import { useInstance } from '@hooks/useInstance';
import { useUnsafeMetadata } from '@hooks/useUnsafeMetadata';
import { SupportedFeaturesValues } from '@constants';
import { InstanceFeaturePlan } from '@dapi/instances/types';
import { isDevelopment } from '@utils/environment';

type UseSupportedFeatureReturn = {
  isAccessible: (name: string) => boolean;
  getPlanForFeature: (name: string) => InstanceFeaturePlan | null;
  hasNotSeenInfoBillingModal: (name: string) => boolean;
  isUnsupportedAndEnabled: ({ feature, currentValue }) => boolean;
  getIsSupportedFeatureByValue: (
    value: SupportedFeaturesValues,
  ) => boolean | undefined;
  getIsInGracePeriod: (value: SupportedFeaturesValues) => boolean;
};

export const useSupportedFeature = (): UseSupportedFeatureReturn => {
  const { application } = useApplication();
  const { instance } = useInstance();
  const { shouldShowInfoModal } = useUnsafeMetadata();

  /** Returns the plan name of the feature, or null if the feature does not require a plan switch */
  const getPlanForFeature = (name: string): InstanceFeaturePlan | null => {
    return instance?.features?.[name]?.plan || null;
  };

  /**
   * TODO CORE-2263 - Delete `user_accessible_features` in favor of Dashboard permissions
   *
   * This function is being used in order to check if the user has access to use a feature
   * for example non admin users are not able to use impersonate
   *
   * @deprecated
   */
  const isAccessible = (name: string): boolean => {
    return application?.user_accessible_features.includes(name);
  };

  const hasNotSeenInfoBillingModal = (name: string): boolean => {
    const showInfoModal = shouldShowInfoModal(application?.id);

    // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
    return getPlanForFeature(name) && showInfoModal;
  };

  const isUnsupportedAndEnabled = ({ feature, currentValue }) => {
    return !getIsSupportedFeatureByValue(feature) && !!currentValue;
  };

  const getIsInGracePeriod = (value: SupportedFeaturesValues) => {
    // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
    return instance.features[value].is_in_grace_period;
  };

  const getIsSupportedFeatureByValue = (value: SupportedFeaturesValues) => {
    if (isDevelopment(instance?.environment_type)) {
      return instance?.features[value].is_supported;
    }

    return (
      instance?.features[value].is_supported &&
      getPlanForFeature(value) === null
    );
  };

  return {
    getIsInGracePeriod,
    isAccessible,
    hasNotSeenInfoBillingModal,
    isUnsupportedAndEnabled,
    getPlanForFeature,
    getIsSupportedFeatureByValue,
  };
};
