import React from 'react';
import { ChakraProvider } from '@context/ChakraContext';
import { useLocation } from '@hooks/useLocation';
import { useLastActive } from '@hooks/useLastActive';
import ErrorBoundary from '@components/errorBoundary/ErrorBoundary';
import { useUser } from '@clerk/nextjs';
import PlausibleProvider from 'next-plausible';
import { Home } from './Home';
import DashboardApplication from './DashboardApplication';
import { SupportPopover } from '@components/SupportPopover';

const MainLayout = ({ children }: { children: React.ReactNode }) => {
  const { isHome } = useLocation();

  return isHome ? (
    <Home>{children}</Home>
  ) : (
    <DashboardApplication>{children}</DashboardApplication>
  );
};

type LayoutProps = {
  children: React.ReactNode;
};

export function Layout({ children }: LayoutProps) {
  const { user, isLoaded } = useUser();
  useLastActive({ shouldTrack: true });

  if (!isLoaded) {
    return null;
  }

  const isClerkMember = user?.emailAddresses?.some(email =>
    email?.emailAddress?.endsWith('@clerk.dev'),
  );

  return (
    <PlausibleProvider
      // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
      domain={process.env.NEXT_PUBLIC_DASHBOARD_URL}
      enabled={!isClerkMember}
    >
      <ChakraProvider>
        <ErrorBoundary>
          <MainLayout>{children}</MainLayout>
        </ErrorBoundary>
      </ChakraProvider>

      <SupportPopover />
    </PlausibleProvider>
  );
}

export function PublicLayout({ children }: LayoutProps) {
  return (
    <>
      {/* @ts-expect-error FIXME: Exposed from enabling StrictNullChecks */}
      <PlausibleProvider domain={process.env.NEXT_PUBLIC_DASHBOARD_URL}>
        {children}
      </PlausibleProvider>
    </>
  );
}
