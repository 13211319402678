export function RotateClockwiseIconPath(props: { size?: 'sm' | 'base' }) {
  const { size = 'base' } = props;
  return (
    <>
      {size === 'base' ? (
        <>
          <path d='M17.25 2.75V7.25M17.25 7.25H12.75M17.25 7.25C17.25 7.25 14.0041 2.75 10 2.75C5.99594 2.75 2.75 5.99594 2.75 10C2.75 14.0041 5.99594 17.25 10 17.25C12.637 17.25 14.9662 15.8238 16.2473 13.7079' />
        </>
      ) : (
        <>
          <path d='M13.25 2.75V6.00862M13.25 6.00862H9.99138M13.25 6.00862C13.25 6.00862 10.8995 2.75 8 2.75C5.1005 2.75 2.75 5.10051 2.75 8C2.75 10.8995 5.1005 13.25 8 13.25C9.90957 13.25 11.5962 12.2172 12.5239 10.685' />
        </>
      )}
    </>
  );
}
