export function XIconPath(props: { size?: 'sm' | 'base' }) {
  const { size = 'base' } = props;
  return (
    <>
      {size === 'base' ? (
        <>
          <path d='M5.75 5.75L14.25 14.25M14.25 5.75L5.75 14.25' />
        </>
      ) : (
        <>
          <path d='M4 4L12 12M12 4L4 12' />
        </>
      )}
    </>
  );
}
