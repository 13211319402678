import { textStyles } from '../foundations/textStyles';

export const Menu = {
  parts: ['list', 'item', 'groupTitle'],
  baseStyle: {
    list: {
      border: 'none',
      boxShadow: 'dropdown',
      outline: '0 !important',
    },
    groupTitle: {
      color: 'legacyGray.300',
      ...textStyles['xs-medium'],
    },
    item: {
      _hover: { background: 'legacyGray.50' },
      _focus: { boxShadow: 'none !important', background: 'white' },
    },
  },
};
