import * as RadixTooltip from '@radix-ui/react-tooltip';
import { motion, AnimatePresence } from 'framer-motion';
import { useControllableState } from '../../hooks/useControllableState';
interface Props {
  children: React.ReactNode;
  label: React.ReactNode;
  delayDuration?: number;
  defaultOpen?: boolean;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}

export function Tooltip({
  defaultOpen,
  open: openProp,
  onOpenChange,
  children,
  label,
  delayDuration,
}: Props) {
  let [isOpen, setIsOpen] = useControllableState({
    defaultProp: defaultOpen,
    prop: openProp,
    onChange: onOpenChange,
  });

  return (
    <RadixTooltip.Provider>
      <RadixTooltip.Root
        onOpenChange={setIsOpen}
        open={isOpen}
        delayDuration={delayDuration}
      >
        <RadixTooltip.Trigger asChild>{children}</RadixTooltip.Trigger>
        <AnimatePresence>
          {isOpen && (
            <RadixTooltip.Portal forceMount>
              <RadixTooltip.Content
                asChild
                className='max-w-56 rounded-md bg-legacyGray-900 px-1.5 py-1 text-2xs font-medium text-white shadow-lg will-change-transform'
                sideOffset={5}
                collisionPadding={5}
              >
                <motion.div
                  initial={{ opacity: 0, y: 4, scale: 0.96 }}
                  animate={{ opacity: 1, y: 0, scale: 1 }}
                >
                  {label}
                </motion.div>
              </RadixTooltip.Content>
            </RadixTooltip.Portal>
          )}
        </AnimatePresence>
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  );
}
