import {
  RadioGroup as RACRadioGroup,
  Radio as RACRadio,
  type RadioGroupProps as RACRadioGroupProps,
  Label,
} from 'react-aria-components';
import { cva, cx, type VariantProps } from 'cva';

const radioGroup = cva({
  base: 'flex max-w-md flex-col gap-[--radio-group-gap]',
  variants: {
    layout: {
      default: [
        '[--radio-group-gap:theme(spacing.6)]',
        '[--radio-group-item-gap:theme(spacing.2)]',
      ],
      compact: [
        '[--radio-group-gap:theme(spacing.3)]',
        '[--radio-group-item-gap:theme(spacing.1)]',
      ],
    },
  },
});

interface RadioGroupProps
  extends Omit<RACRadioGroupProps, 'children'>,
    VariantProps<typeof radioGroup> {
  layout?: 'default' | 'compact';
  items: Array<{
    suffix?: React.ReactNode;
    label: string;
    value: string;
    children?: React.ReactNode;
  }>;
}

export const RadioGroup = ({
  items,
  layout = 'default',
  ...props
}: RadioGroupProps) => {
  return (
    <RACRadioGroup className={radioGroup({ layout })} {...props}>
      {items.map(({ label, value, suffix, children }, index) => {
        return (
          <div
            className={cx('space-y-[--radio-group-item-gap]', {
              '[&_p]:opacity-50': props.isDisabled,
            })}
            key={index}
          >
            <RACRadio value={value} className='group w-fit'>
              <div
                className={cx('flex items-center gap-2.5', {
                  'opacity-50': props.isDisabled,
                })}
              >
                <div className='relative aspect-square h-[0.875rem] rounded-full ring-1 ring-legacyGray-200'>
                  <div
                    className={cx(
                      'absolute inset-0 scale-0 rounded-full bg-legacyGray-900 bg-gradient-to-b from-white/12 to-white/0 ring-1 transition-transform duration-300 ease-[cubic-bezier(.4,.36,0,1)]',
                      'before:absolute before:inset-1 before:rounded-full before:bg-white',
                      'group-focus:ring-offset-1 group-data-[focus-visible]:ring-[3px] group-data-[focus-visible]:ring-black/8 group-data-[focus-visible]:ring-offset-black/15',
                      'group-data-[selected]:scale-100 group-data-[selected]:ring-black',
                    )}
                  />
                </div>

                <Label
                  className={cx(
                    'font-medium text-secondary',
                    'group-data-[selected]:text-black',
                    'flex items-center gap-2',
                  )}
                >
                  {label}
                  {suffix && <span>{suffix}</span>}
                </Label>
              </div>
            </RACRadio>
            {children ? <div className='ml-6'>{children}</div> : null}
          </div>
        );
      })}
    </RACRadioGroup>
  );
};
