export function ExclamationCircleIconPath(props: { size?: 'sm' | 'base' }) {
  const { size = 'base' } = props;
  return (
    <>
      {size === 'base' ? (
        <>
          <circle
            cx='10'
            cy='10'
            r='7.25'
            fill='currentColor'
            fillOpacity={0.15}
          />
          <path d='M10 6.75V10.25' />
          <path d='M10 13.26V13.25' />
        </>
      ) : (
        <>
          <circle cx='8' cy='8' r='5.25' />
          <path d='M8 5.75V7.25' />
          <path d='M8 10.2502V10.2602' />
        </>
      )}
    </>
  );
}
