export function CodeBracketIconPath(props: { size?: 'sm' | 'base' }) {
  const { size = 'base' } = props;
  return (
    <>
      {size === 'base' ? (
        <>
          <path d='M7.25 6.75L3.75 10L7.25 13.25' />
          <path d='M12.75 6.75L16.25 10L12.75 13.25' />
        </>
      ) : (
        <>
          <path d='M5.25 5.75L2.75 8L5.25 10.25' />
          <path d='M10.75 5.75L13.25 8L10.75 10.25' />
        </>
      )}
    </>
  );
}
