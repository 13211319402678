import { useAuth } from '@clerk/nextjs';
import { useSupportedFeature } from './useSupportedFeature';
import { usePersonalWorkspace } from './usePersonalWorkspace';
import { PERMISSIONS } from '@constants';
import { getDashboardEnvironment } from '@utils/getDashboardEnvironment';
import { useStaffMode } from '@components/sidebar/StaffMode';

/**
 * Wraps the SDK `has` method with Dashboard specific logic
 */
export function useDashboardAuth() {
  const { has, isLoaded } = useAuth();
  const { staffModeEnabled: isUserRolesEnabled } = useStaffMode({
    feature: 'dashboard-user-roles',
  });
  const { isAccessible } = useSupportedFeature();
  const { isPersonalWorkspace } = usePersonalWorkspace();
  const { isProduction } = getDashboardEnvironment();

  const hasImpl = (
    permission: (typeof PERMISSIONS)[keyof typeof PERMISSIONS],
    /** @deprecated */ supportedFeatureFallback?: string,
  ) => {
    if (isPersonalWorkspace) {
      return true;
    }

    // TODO CORE-2343 - Release Dashboard roles and permissions to all customers
    if (isProduction || !isUserRolesEnabled) {
      // TODO CORE-2263 - Delete `user_accessible_features` in favor of Dashboard permissions
      if (supportedFeatureFallback) {
        return isAccessible(supportedFeatureFallback);
      }

      return true;
    }

    return isLoaded && has({ permission });
  };

  return {
    has: hasImpl,
  };
}
