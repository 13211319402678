import { CheckoutSubscriptionResponse } from '@dapi/products/types';
import { useReducer, Reducer, useEffect, useCallback } from 'react';
import { useRouter } from 'next/router';
import { checkoutReducer, CheckoutContext } from './checkoutContext';
import {
  CheckoutActionTypes,
  CheckoutActions,
  CheckoutOptions,
  CheckoutState,
  OptionsActions,
} from './types';

export const CheckoutProvider = ({ children }) => {
  const router = useRouter();
  const [state, dispatch] = useReducer<
    Reducer<CheckoutState, CheckoutActionTypes>
    // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
  >(checkoutReducer, { state: null });
  // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
  const { onClearCheckout } = state?.state || {};

  const clearCheckout = useCallback(() => {
    onClearCheckout?.();
    // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
    dispatch({ type: CheckoutActions.ClearCheckout, payload: null });
  }, [dispatch, onClearCheckout]);

  const setCheckout = useCallback(
    (
      checkout: CheckoutSubscriptionResponse & {
        stripeSessionIdStatusEndpoint: string;
      },
    ) => {
      // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
      dispatch({
        type: CheckoutActions.SetCheckout,
        payload: {
          ...checkout,
        },
      });
    },
    [dispatch],
  );

  const setOptions = useCallback(
    (options: CheckoutOptions) => {
      // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
      dispatch({
        type: OptionsActions.SetOptions,
        payload: {
          ...options,
        },
      });
    },
    [dispatch],
  );

  const showCheckout = useCallback(() => {
    // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
    dispatch({ type: CheckoutActions.SetCheckoutVisible, payload: null });
  }, [dispatch]);

  const initializePaymentElement = useCallback(() => {
    // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
    dispatch({
      type: CheckoutActions.SetPaymentElementInitialized,
      payload: null,
    });
  }, []);

  // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
  const sortedPlans = state?.state?.plans
    ? // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
      state?.state?.plans.sort((a, b) => {
        const aValue = a.is_addon ? 0 : 1;
        const bValue = b.is_addon ? 0 : 1;
        return bValue - aValue;
      })
    : [];

  useEffect(() => {
    const handleRouteChange = () => {
      // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
      if (state?.state?.isCheckoutVisible) {
        clearCheckout();
      }
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
    // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
  }, [state?.state?.isCheckoutVisible]);

  return (
    <CheckoutContext.Provider
      value={{
        state: {
          // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
          ...state.state,
          plans: sortedPlans,
          trial_end:
            // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
            state?.state?.trial_end > 0 ? state?.state?.trial_end * 1000 : null,
          trial_start:
            // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
            state?.state?.trial_start > 0
              ? // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
                state?.state?.trial_start * 1000
              : null,
          // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
          isTrial: state.state?.trial_end > 0 && state.state?.trial_start > 0,
        },
        clearCheckout,
        setCheckout,
        showCheckout,
        initializePaymentElement,
        setOptions,
      }}
    >
      {children}
    </CheckoutContext.Provider>
  );
};
