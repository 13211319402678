const baseStyle = {
  _hover: { textDecoration: 'none', color: 'primary.700' },
  _focus: { boxShadow: 'focusRing' },
  _focusVisible: {
    boxShadow: '0 0 0 1px var(--chakra-colors-primary-500)',
    borderRadius: 'md',
  },
};

const noFocus = {
  _focus: { boxShadow: 'none' },
};

const noHover = {
  _focus: { boxShadow: 'none' },
  _hover: { color: 'inherit' },
};

const variants = {
  'no-focus': noFocus,
  'no-hover': noHover,
};

export const Link = {
  baseStyle,
  variants,
};
