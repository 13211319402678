import { cx } from 'cva';
import { forwardRef } from 'react';

export const NavigationBreadcrumbButton = forwardRef<
  HTMLButtonElement,
  { children: React.ReactNode }
>(function NavigationBreadcrumbButton({ children, ...props }, ref) {
  return (
    <button
      ref={ref}
      className={cx(
        'group relative isolate flex items-center gap-1 rounded border border-transparent py-[0.3125rem] pl-2.5 pr-2 font-book transition',
        'hover:border-black/5 hover:bg-white hover:shadow hover:shadow-black/2',
        'dark:hover:border-black/5 dark:hover:bg-gray-300 dark:hover:shadow-black/2 dark:hover:before:opacity-100',
        'data-[state=open]:border-black/5 data-[state=open]:bg-white data-[state=open]:shadow data-[state=open]:shadow-black/2',
        'dark:data-[state=open]:border-black/10 dark:data-[state=open]:bg-gray-300 dark:data-[state=open]:shadow-black/10 dark:data-[state=open]:before:opacity-100',
        'before:absolute before:inset-0 before:rounded-inherit before:opacity-0 before:shadow-[inset_0_1px_0,inset_0_1px_0,inset_0_0_0_1px] before:shadow-white/2 before:transition',
        'after:absolute after:-inset-1 after:rounded-[0.5625rem] after:border-2 after:border-blue after:opacity-0',
        'focus-visible:after:opacity-100',
      )}
      {...props}
    >
      {children}

      <svg
        width='16'
        height='16'
        viewBox='0 0 16 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className={cx(
          'text-gray-800 transition',
          'group-hover:text-gray-1000 group-data-[state=open]:text-gray-1000',
        )}
      >
        <path
          d='M6 6.5L8.5 4L11 6.5'
          stroke='currentColor'
          strokeWidth='1.25'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M11 9.5L8.5 12L6 9.5'
          stroke='currentColor'
          strokeWidth='1.25'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </button>
  );
});
