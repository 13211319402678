import { buildRestPath } from '@utils/api';
import { BASE_PATH } from './constants';

type BuildInstancePathOptions = {
  resourceId?: string;
  action?: string;
  queryParams?: {
    [key: string]: string;
  };
  isBff?: boolean;
};

export function buildInstancePath({
  resourceId,
  action,
  queryParams,
  isBff = false,
}: BuildInstancePathOptions = {}): string {
  return buildRestPath({
    basePath: `${isBff ? '/bff' : ''}${BASE_PATH}`,
    resourceId,
    action,
    queryParams,
  });
}
