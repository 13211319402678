import { colors } from './colors';

// chakra-bug: need to explicitly reference colors here
export const shadows = {
  dropShadow1: '0px 2px 8px 0px #00000033',
  dropShadow2: '0px 12px 24px rgba(0, 0, 0, 0.08)',
  dropShadow3: '0px -4px 24px rgb(0 0 0 / 0.04)',
  focusRing: `0px 0px 0px 1px ${colors.primary['500']}`,
  header: '0px 5px 30px 0px rgb(0 0 0 / 3%)',
};
