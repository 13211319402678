import { useOrganizationCustomerPortalSession } from '@dapi/currentOrganization/mutations';
import { useOrganization } from '@clerk/nextjs';
import { Button } from '@clerk/ceramic/experimental/components/Button';
import { useRouter } from 'next/router';
import { toast } from '@clerk/ceramic/components/Toast';

export const ManageBillingInfo = () => {
  const { organization } = useOrganization();
  const { push } = useRouter();

  const { trigger: createCustomerPortalSession, isMutating } =
    useOrganizationCustomerPortalSession({
      id: organization?.id as string,
    });

  const returnUrl = window.location.origin;

  const handleUpdateMembership = async () => {
    try {
      const res = await createCustomerPortalSession({ returnUrl });

      if (res && res?.url) {
        window.history.replaceState({}, document.title, window.location.origin);
        window.open(res.url, '_blank');
      }
    } catch (_) {
      await push(returnUrl);

      toast.error(
        'Failed to load billing info. Please try again later or contact support.',
      );
    }
  };

  return (
    <Button
      onClick={handleUpdateMembership}
      intent='primary'
      icon='external-link-legacy'
      loading={isMutating}
    >
      Manage billing info
    </Button>
  );
};
