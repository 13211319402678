// textStyles cannot be used in custom theme overrides currently
// this is a reference of all the textStyles we use all over the app
// we will use this list once the following issue is fixed:
// https://github.com/chakra-ui/chakra-ui/issues/3884
// 'badge-sm': {
//   fontSize: 'xxs',
//   lineHeight: 'xxs',
//   fontWeight: 'semibold',
// },
// 'badge-md': {
//   fontSize: 'xs',
//   lineHeight: 'xs',
//   fontWeight: 'semibold',
// },

import { cx } from 'cva';

const xsClassNames = cx('text-[0.625rem]', 'leading-[0.75rem]');
const smClassNames = cx('text-[0.875rem]', 'leading-[1.125rem]');
const mdClassNames = cx('text-[0.875rem]', 'leading-[1.25rem]');
const lgClassNames = cx('text-[1rem]', 'leading-[1.5rem]');

// Note:
// Migrated `textStyles` from Chakra for ease of migration
// Not to be used for new components
export const textClassNames = {
  h1: cx('text-[1.875rem]', 'font-medium', 'leading-[2.25rem]'),
  h2: cx('text-[1.25rem]', 'font-medium', 'leading-[1.75rem]'),
  h3: '',
  'xs-normal': cx(xsClassNames, 'font-normal'),
  'xs-medium': cx(xsClassNames, 'font-semibold'),
  'sm-normal': cx(smClassNames, 'font-normal'),
  'sm-medium': cx(smClassNames, 'font-medium'),
  'md-normal': cx(mdClassNames, 'font-normal'),
  'md-medium': cx(mdClassNames, 'font-medium'),
  'md-semibold': cx(mdClassNames, 'font-semibold'),
  'lg-normal': cx(lgClassNames, 'font-normal'),
  'lg-medium': cx(lgClassNames, 'font-medium'),
};

const xsTextStyle = {
  fontSize: 'xxs',
  lineHeight: '3',
};

const smTextStyle = {
  fontSize: 'xs',
  lineHeight: '1.125rem',
};

const mdTextStyle = {
  fontSize: 'sm',
  lineHeight: '5',
};

const lgTextStyle = {
  fontSize: 'md',
  lineHeight: '6',
};

export const textStyles = {
  h1: {
    fontSize: '3xl',
    fontWeight: 'medium',
    lineHeight: '9',
  },
  h2: {
    fontSize: 'xl',
    fontWeight: 'medium',
    lineHeight: '7',
  },
  h3: {},
  'xs-normal': {
    ...xsTextStyle,
    fontWeight: 'normal',
  },
  'xs-medium': {
    ...xsTextStyle,
    fontWeight: 'semibold',
  },
  'sm-normal': {
    ...smTextStyle,
    fontWeight: 'normal',
  },
  'sm-medium': {
    ...smTextStyle,
    fontWeight: 'medium',
  },
  'md-normal': {
    ...mdTextStyle,
    fontWeight: 'normal',
  },
  'md-medium': {
    ...mdTextStyle,
    fontWeight: 'medium',
  },
  'md-semibold': {
    ...mdTextStyle,
    fontWeight: 'semibold',
  },
  'lg-medium': {
    ...lgTextStyle,
    fontWeight: 'medium',
  },
};
