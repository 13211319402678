import { useQuery } from '@hooks/useQuery';

import type { APIQueryHook } from '@typeDefs/API';
import type { Application } from './types';

import { buildApplicationPath } from './utils';
import { getApplication, listApplications, listInstances } from './api';
import { Instance } from '@dapi/instances/types';
import { useOrganization, useSession } from '@clerk/nextjs';

export type BaseApplicationQueryHook = {
  id: string;
};

export const useFetchApplication: APIQueryHook<
  Application,
  BaseApplicationQueryHook
> = props => {
  const { id } = props;

  const path = buildApplicationPath({ resourceId: id });
  return useQuery(path, getApplication, { pathPrefix: 'v1', skipRequest: !id });
};

export const useFetchApplications: APIQueryHook<Application[]> = () => {
  /**
   * "The reason we append the organization ID and the session ID to the query
   * parameters of the request is to  create a unique cache for each distinct session and organization.
   * Without these two parameters, we would receive incorrect data if we change
   * the session (multi-session) or organization on the application's pages.
   * This occurs because SWR cannot differentiate that we want to fetch different
   * data when the URL (cache key) remains the same."
   */
  const { session, isLoaded: isSessionLoaded } = useSession();
  const { organization } = useOrganization();

  const path = buildApplicationPath({
    queryParams: {
      // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
      session: session?.id,
      // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
      organizationId: organization?.id || undefined,
    },
  });
  return useQuery(path, listApplications, {
    pathPrefix: 'v1',
    skipRequest: !isSessionLoaded,
  });
};

export const useFetchApplicationInstances: APIQueryHook<
  Instance[],
  BaseApplicationQueryHook
> = params => {
  const { id } = params;
  const path = buildApplicationPath({ action: 'instances', resourceId: id });
  return useQuery(path, listInstances, {
    pathPrefix: 'v1',
  });
};
