import {
  useInstanceContext,
  InstanceContextType,
} from '@context/vitals/instance/Provider';

export const useInstance = (): InstanceContextType & {
  /**
   * @deprecated Use data instead
   *
   * The 'instance' property is deprecated in favor of 'data'.
   * This change was made to standardize the way a value is returned
   * from the API related hooks.
   *
   * Instead of:
   * const { instance } = useInstance()
   *
   * Use:
   * const { data } = useInstance()
   */
  instance: InstanceContextType['data'];
} => {
  const res = useInstanceContext();

  return {
    instance: res.data,
    ...res,
  };
};
