export function FingerprintIconPath(props: { size?: 'sm' | 'base' }) {
  const { size = 'base' } = props;
  return (
    <>
      {size === 'base' ? (
        <>
          <path d='M5.28579 15.8375C5.44785 15.4762 5.59626 15.1095 5.73188 14.7356M12.3068 17.25C12.5715 16.5581 12.745 15.7781 12.9384 15.0668M7.13071 3.40758C8.20399 2.92359 9.39246 2.70048 10.5844 2.7592C11.7763 2.81792 12.9325 3.15656 13.9444 3.74328C14.9563 4.33 15.7905 5.14553 16.3688 6.11321C16.947 7.0809 17.2503 8.16896 17.25 9.27512C17.25 10.7178 17.1272 12.134 16.8912 13.5152L16.1515 17.25M2.75 12.5571C3.0255 11.5042 3.17135 10.4055 3.17135 9.27512C3.17135 7.85625 3.66009 6.54252 4.49085 5.47225M10.2107 9.27433C10.2133 12.0147 9.61748 14.7274 8.46212 17.25M6.6108 10.8788C6.66356 10.3508 6.69059 9.816 6.69059 9.27512C6.69059 8.41003 7.06141 7.58038 7.72147 6.96867C8.38154 6.35696 9.27678 6.01331 10.2103 6.01331C11.1437 6.01331 12.039 6.35696 12.699 6.96867C13.3591 7.58038 13.7299 8.41003 13.7299 9.27512C13.7299 9.67192 13.7197 10.0664 13.6984 10.4584L13.4465 11.991' />
        </>
      ) : (
        <>
          <path d='M4.58626 12.2271C4.70362 11.9655 4.81109 11.6999 4.90929 11.4292M9.67045 13.25C9.86213 12.749 9.98778 12.1841 10.1278 11.669M5.92223 3.22618C6.69944 2.87571 7.56005 2.71414 8.42317 2.75666C9.28629 2.79919 10.1236 3.04441 10.8563 3.46927C11.589 3.89414 12.1931 4.48469 12.6119 5.18543C13.0306 5.88617 13.2502 6.67407 13.25 7.47508C13.25 8.51981 13.1611 9.54529 12.9902 10.5455L12.4545 13.25M2.75 9.85168C2.9495 9.08924 3.05512 8.29361 3.05512 7.47508C3.05512 6.44763 3.40903 5.49631 4.01062 4.72128M8.15256 7.47451C8.15449 9.45894 7.723 11.4233 6.88636 13.25M5.54575 8.63636C5.58396 8.25402 5.60353 7.86676 5.60353 7.47508C5.60353 6.84864 5.87205 6.24786 6.35003 5.8049C6.82801 5.36194 7.47629 5.11309 8.15225 5.11309C8.82821 5.11309 9.47649 5.36194 9.95447 5.8049C10.4324 6.24786 10.701 6.84864 10.701 7.47508C10.701 7.76243 10.6936 8.04805 10.6781 8.33196L10.4957 9.44176' />
        </>
      )}
    </>
  );
}
