import { Button } from '@clerk/ceramic/experimental/components/Button';
import { Icon, IconName } from '@clerk/ceramic/experimental/components/Icon';
import ColorSchemeToggle from '@components/sidebar/ColorSchemeToggle';
import StaffModeToggle from '@components/sidebar/StaffModeToggle';
import CeramicSandboxLink from '@components/sidebar/CeramicSandboxLink';
import useClerkStaff from '@hooks/useClerkStaff';
import { cx } from 'cva';
import { AnimatePresence, motion } from 'framer-motion';
import Link from 'next/link';
import useClerkStaffFeatureToggle from '@hooks/useClerkStaffFeatureToggle';
import { useHotkeys } from 'react-hotkeys-hook';
import { useLocation } from '@hooks/useLocation';
import { useRef, useState } from 'react';
import {
  Button as RACButton,
  Dialog,
  DialogTrigger,
  Heading,
  Popover,
} from 'react-aria-components';
import { CopyButton } from '@components/CopyButton';
import { useMount } from 'react-use';
import StaffCopyURL from './sidebar/StaffCopyURL';

function Logomark(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg viewBox='0 0 18 18' fill='none' aria-hidden {...props}>
      <ellipse cx='9' cy='9' fill='#fff' rx='2.812' ry='2.813' />
      <path
        fill='#fff'
        d='M14.067 15.659c.24.24.216.635-.066.824A8.957 8.957 0 0 1 9 18a8.957 8.957 0 0 1-5.002-1.517.533.533 0 0 1-.066-.824l2.055-2.055a.608.608 0 0 1 .708-.096A5.04 5.04 0 0 0 9 14.062c.83 0 1.614-.2 2.305-.554a.608.608 0 0 1 .708.096l2.055 2.055Z'
      />
      <path
        fill='#fff'
        d='M14.002 1.517a.533.533 0 0 1 .066.824l-2.055 2.055a.608.608 0 0 1-.708.096 5.062 5.062 0 0 0-6.814 6.814c.12.233.09.521-.095.707l-2.055 2.055a.534.534 0 0 1-.824-.066A9 9 0 0 1 14.002 1.517Z'
        opacity='.5'
      />
    </svg>
  );
}

export function SupportPopover() {
  const triggerRef = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const [staffModeEnabled, toggleStaffMode] = useClerkStaffFeatureToggle();
  const isClerkStaff = useClerkStaff();

  useMount(function defaultStaffModeToOnForClerkians() {
    if (isClerkStaff && !staffModeEnabled) {
      toggleStaffMode();
    }
  });

  useHotkeys('control+`', () => toggleStaffMode(), {
    enabled: isClerkStaff,
  });

  const { instanceId } = useLocation();
  interface HelpLinkProps {
    icon: IconName;
    href: string;
    text: string;
  }

  const HelpLinks: HelpLinkProps[] = [
    {
      icon: 'book',
      href: 'https://clerk.com/docs',
      text: 'Read documentation',
    },
    {
      icon: 'phone-legacy',
      href: 'https://clerk.com/contact/sales',
      text: 'Book a sales call',
    },
    {
      icon: 'message-legacy',
      href: 'https://clerk.com/contact/support',
      text: 'Contact support',
    },
  ];

  return (
    <DialogTrigger>
      <RACButton
        onPress={() => setOpen(true)}
        className={cx(
          'fixed bottom-5 right-5 isolate z-50 grid size-10 place-content-center rounded-full bg-legacyGray-900 shadow-[0_1px_theme(colors.white/0.075)_inset,0_1px_3px_theme(colors.legacyGray.900/0.2),0_0_0_1px_theme(colors.legacyGray.900)] outline-none ring-[0.375rem] ring-black/8',
          'before:absolute before:inset-0 before:-z-10 before:rounded-full before:bg-gradient-to-b before:from-white/20 before:opacity-50 hover:before:opacity-100',
          'before:transtion-opacity transition duration-300 ease-[cubic-bezier(0.4,0.36,0,1)] before:duration-300 before:ease-[cubic-bezier(0.4,0.36,0,1)]',
          'data-[focus-visible]:after:absolute data-[focus-visible]:after:-inset-1.5 data-[focus-visible]:after:rounded-full data-[focus-visible]:after:ring-1 data-[focus-visible]:after:ring-purple-500',
          {
            'bg-purple-500 shadow-[0_1px_theme(colors.white/0.075)_inset,0_1px_3px_theme(colors.purple.500/0.2),0_0_0_1px_theme(colors.purple.500)]':
              staffModeEnabled,
          },
        )}
      >
        <div
          ref={triggerRef}
          className='absolute inset-0 -z-10 rounded-full bg-gradient-to-b from-white/10 from-45% to-55% mix-blend-overlay'
        />

        <span className='sr-only'>Support</span>

        <Logomark className='size-[1.125rem]' />
      </RACButton>
      <AnimatePresence>
        {isOpen && (
          <Popover
            isOpen={isOpen}
            onOpenChange={setOpen}
            placement='bottom end'
            offset={0}
            triggerRef={triggerRef}
          >
            <motion.div
              initial={{
                opacity: 0,
                transform: 'translateY(4px)',
              }}
              animate={{
                opacity: 1,
                transform: 'translateY(0px)',
              }}
              exit={{
                opacity: 0,
                transform: 'translateY(4px)',
              }}
            >
              <Dialog className='light mb-6 w-full max-w-60 rounded-xl bg-white text-legacyGray-950 shadow-[0_10px_32px_rgba(34,42,53,0.15),0_1px_1px_theme(colors.black/0.05),0_4px_6px_rgba(34,42,53,0.08),0_1px_1px_rgba(34,42,53,0.1),0_24px_68px_rgba(47,48,55,0.1)] outline-none ring-1 ring-legacyGray-950/5'>
                {isClerkStaff && (
                  <div className='flex flex-col gap-1 border-b border-legacyGray-100 bg-legacyGray-50 p-4 pb-3 first:rounded-t-inherit'>
                    <Heading
                      slot='title'
                      className='text-base/6 font-medium text-legacyGray-950'
                    >
                      Staff Actions
                    </Heading>
                    <ul>
                      <li>
                        <StaffModeToggle />
                      </li>
                      <li>
                        <ColorSchemeToggle />
                      </li>
                      <li>
                        <CeramicSandboxLink />
                      </li>
                      <li className='mt-1 empty:mt-0'>
                        <StaffCopyURL />
                      </li>
                    </ul>
                  </div>
                )}

                {instanceId && (
                  <div className='border-b border-legacyGray-100 bg-legacyGray-50 py-3 pl-4 pr-3 first:rounded-t-inherit'>
                    <Heading
                      slot='title'
                      className='text-base/6 font-medium text-legacyGray-950'
                    >
                      Instance ID
                    </Heading>
                    <div className='flex items-center gap-0.5'>
                      <p className='min-w-0 truncate font-mono text-secondary'>
                        {instanceId}
                      </p>
                      <CopyButton
                        ariaLabel='Copy instance ID to clipboard'
                        text={instanceId}
                        size='md'
                      />
                    </div>
                  </div>
                )}

                <div className='p-4'>
                  <Heading
                    slot='title'
                    className='text-base/6 font-medium text-legacyGray-950'
                  >
                    Need help?
                  </Heading>

                  <p className='mt-0.5 text-base/5 text-legacyGray-600'>
                    Get help with setting up and using Clerk for your
                    application
                  </p>

                  <ul className='mt-4 grid gap-y-1'>
                    {HelpLinks.map(link => (
                      <li key={link.href}>
                        <Link
                          className={cx(
                            'relative flex items-center gap-x-3 rounded text-base/6 font-medium text-legacyGray-950 outline-none',
                            'focus-visible:focus-visible:ring-[0.1875rem] focus-visible:ring-black/8',
                          )}
                          href={link.href}
                          target='_blank'
                        >
                          <span className='text-tertiary'>
                            <Icon name={link.icon} size='sm' />
                          </span>
                          {link.text}
                        </Link>
                      </li>
                    ))}
                    <li className='mt-4 grid'>
                      <Button
                        intent='primary'
                        icon='discord-legacy'
                        iconPosition='left'
                        asChild
                      >
                        <Link
                          href='https://discord.gg/b5rXHjAg7A'
                          target='_blank'
                        >
                          Join Discord
                        </Link>
                      </Button>
                    </li>
                  </ul>
                </div>
              </Dialog>
            </motion.div>
          </Popover>
        )}
      </AnimatePresence>
    </DialogTrigger>
  );
}
