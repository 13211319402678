import { useQuery } from '@hooks/useQuery';
import { APIQueryHook } from '@typeDefs/API';

import {
  currentOrganizationSubscription,
  getOrganizationSubscription,
  getSubscriptionPlans,
} from './api';
import { buildCurrentOrganizationPath } from './utils';

import {
  SubscriptionResponse,
  OrganizationSubscriptionPlanResponse,
  OrganizationCurrentSubscriptionResponse,
} from './types';

export type UseOrganizationSubscriptionProps = {
  id: string;
};

export const useGetOrganizationSubscription: APIQueryHook<
  SubscriptionResponse,
  UseOrganizationSubscriptionProps
> = params => {
  const { id, options = {} } = params;
  const path = buildCurrentOrganizationPath({
    resourceId: id,
    action: 'subscription',
  });

  return useQuery(path, getOrganizationSubscription, {
    pathPrefix: 'v1',
    ...options,
  });
};

export type UseGetSubscriptionPlansProps = {
  id: string;
};

export const useGetSubscriptionPlans: APIQueryHook<
  OrganizationSubscriptionPlanResponse,
  UseGetSubscriptionPlansProps
> = params => {
  const { id, options = {} } = params;
  const path = buildCurrentOrganizationPath({
    resourceId: id,
    action: 'subscription_plans',
  });

  return useQuery(path, getSubscriptionPlans, {
    pathPrefix: 'v1',
    ...options,
  });
};

export const useFetchCurrentOrganizationSubscription: APIQueryHook<
  OrganizationCurrentSubscriptionResponse,
  { id: string }
> = params => {
  const { id, options = {} } = params;
  const path = buildCurrentOrganizationPath({
    resourceId: id,
    action: 'current_subscription',
  });

  return useQuery(path, currentOrganizationSubscription, {
    pathPrefix: 'v1',
    skipRequest: !id,
    ...options,
  });
};
