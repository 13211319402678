import { Icon } from '@clerk/ceramic/experimental/components/Icon';
import { useAnalytics } from '@hooks/useAnalytics';
import { useApplication } from '@hooks/useApplication';
import { useInstance } from '@hooks/useInstance';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { cx } from 'cva';
import { useRouter } from 'next/router';
import Image from 'next/image';
import { useUIStore } from '@stores/ui.store';
import { useFetchApplications } from '@dapi/applications/queries';
import { Application } from '@dapi/applications/types';
import Link from 'next/link';
import { PERMISSIONS } from '@constants';
import { useDashboardAuth } from '@hooks/useDashboardAuth';
import { useState } from 'react';

export function ApplicationSwitcher() {
  const { has } = useDashboardAuth();
  const { track } = useAnalytics();
  const { application } = useApplication();
  const { data: applications } = useFetchApplications();
  const { instance } = useInstance();
  const [isOpen, setIsOpen] = useState(false);
  const router = useRouter();
  const activeApplicationId = application?.id;
  const setCrumbs = useUIStore(state => state.setCrumbs);
  const onSelectApp = (app: Application) => {
    // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
    const activeInstance = app.instances.find(({ id }) => id === instance.id);
    track('Dashboard_Top Nav_Application Menu Item Clicked', {
      surface: 'Dashboard',
      location: 'Top Nav',
      environmentType: activeInstance ? activeInstance.environment_type : '',
      clickedOn: app.name,
    });
    const { pathname, query, push } = router;
    void push({
      pathname,
      query: {
        ...query,
        applicationId: app.id,
        instanceId: app.instances[0].id,
      },
    });
    setCrumbs([]);
  };

  const hasManageApplicationsAccess = has(PERMISSIONS.MANAGE_USERS);

  return (
    <DropdownMenu.Root open={isOpen} onOpenChange={setIsOpen}>
      <DropdownMenu.Trigger
        className={cx(
          'group relative isolate flex items-center gap-1 rounded-[7px] border bg-clip-padding py-[0.3125rem] pl-2.5 pr-2 font-book transition',
          'hover:border-black/5 hover:bg-white hover:shadow hover:shadow-black/2',
          'dark:hover:border-black/5 dark:hover:bg-gray-300 dark:hover:shadow-black/2 dark:hover:before:opacity-100',
          'before:absolute before:inset-0 before:rounded-inherit before:opacity-0 before:shadow-[inset_0_1px_0,inset_0_1px_0,inset_0_0_0_1px] before:shadow-white/2 before:transition before:pointer-events-none',
          'after:absolute after:-inset-1 after:rounded-[0.5625rem] after:border-2 after:border-legacy-blue-500 after:opacity-0 after:pointer-events-none',
          'focus-within:after:opacity-100',
          {
            'border-black/5 bg-white shadow-sm shadow-black/2 dark:border-black/10 dark:bg-gray-300 dark:shadow-black/10 dark:before:opacity-100':
              isOpen,
            'border-transparent': !isOpen,
          },
        )}
      >
        <span className='block flex-1 truncate text-left max-w-60'>
          {application.name}
        </span>

        <PlanBadge application={application} />

        <svg
          width='16'
          height='16'
          viewBox='0 0 16 16'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          className={cx('transition', 'group-hover:text-gray-1000', {
            'text-gray-1000': isOpen,
            'text-gray-800': !isOpen,
          })}
        >
          <path
            d='M6 6.5L8.5 4L11 6.5'
            stroke='currentColor'
            strokeWidth='1.25'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M11 9.5L8.5 12L6 9.5'
            stroke='currentColor'
            strokeWidth='1.25'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          onCloseAutoFocus={e => e.preventDefault()}
          align='start'
          sideOffset={4}
          className={cx(
            'relative isolate z-10 w-[16.875rem] overflow-hidden rounded-xl bg-clip-padding font-book',
            'border border-black/5 bg-legacyGray-50 shadow-xl dark:bg-legacyGray-850',
            'dark:border-black/40 dark:shadow-black',
            'dark:before:pointer-events-none dark:before:absolute dark:before:inset-0 dark:before:rounded-[inherit] dark:before:border dark:before:border-white/[0.02]',
          )}
        >
          <div className='rounded-b-lg border-b border-black/4 bg-white bg-clip-padding text-secondary shadow-[0_1px_0_-1px,0_1px_1px_-0.5px] shadow-black/3 dark:bg-legacyGray-800'>
            <div className='p-3'>
              <div className='flex items-center gap-3'>
                {application?.favicon_image_url ? (
                  <Image
                    src={application?.favicon_image_url}
                    alt={application?.name}
                    height={24}
                    width={24}
                    className='size-6 rounded-md object-cover'
                  />
                ) : application?.logo_image_url ? (
                  <Image
                    src={application?.logo_image_url}
                    alt={application?.name}
                    height={24}
                    width={24}
                    className='size-6 rounded-md object-cover'
                  />
                ) : (
                  <div className='flex size-6 items-center justify-center rounded bg-legacyGray-100 p-1 text-secondary dark:bg-legacyGray-700'>
                    <Icon name='application' fill='transparent' />
                  </div>
                )}

                <span className='flex-grow truncate text-primary'>
                  {application?.name}
                </span>
              </div>
            </div>

            {applications && applications.length > 1 && (
              <DropdownMenu.Group className='max-h-[50dvh] divide-y divide-legacyGray-100 overflow-y-auto rounded-inherit border-t border-legacyGray-100 dark:divide-legacyGray-850 dark:border-legacyGray-850'>
                {applications
                  ?.filter(app => app.id !== activeApplicationId)
                  .map(app => (
                    <DropdownMenu.Item
                      key={app.id}
                      className='group flex cursor-pointer items-center gap-4 py-2 pl-4 pr-4 text-secondary hover:bg-legacyGray-25 hover:text-primary focus:bg-legacyGray-50 focus:text-primary focus:outline-none dark:hover:bg-legacyGray-750'
                      onClick={() => {
                        onSelectApp(app);
                      }}
                    >
                      {app?.logo_image_url ? (
                        <Image
                          src={app?.logo_image_url}
                          alt={app?.name}
                          height={16}
                          width={16}
                          className='rounded-sm'
                        />
                      ) : (
                        <div className='size-4 text-secondary'>
                          <Icon name='application' size='sm' />
                        </div>
                      )}

                      <span className='flex-1 truncate'>{app.name}</span>

                      <svg
                        fill='currentColor'
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 16 16'
                        width={16}
                        height={16}
                        className='invisible text-gray-800 group-hover:visible'
                      >
                        <path d='M9.53 4.47a.75.75 0 0 0-1.06 1.06l1.72 1.72H4a.75.75 0 0 0 0 1.5h6.19l-1.72 1.72a.75.75 0 1 0 1.06 1.06l3-3a.75.75 0 0 0 0-1.06l-3-3Z'></path>
                      </svg>
                    </DropdownMenu.Item>
                  ))}
              </DropdownMenu.Group>
            )}
          </div>

          {hasManageApplicationsAccess && (
            <DropdownMenu.Item
              className='focus:outline-none'
              onSelect={event => {
                track(
                  'Dashboard_Applications_New Application Selector Button Clicked',
                  {
                    location: 'Sidebar',
                    surface: 'Dashboard',
                  },
                );
                event.preventDefault();
                setCrumbs([]);
              }}
            >
              <Link
                href='/apps/new'
                className='flex items-center gap-3.5 px-3.5 py-2 text-secondary hover:text-primary'
              >
                <div className='p-0.5 text-secondary'>
                  <svg
                    viewBox='0 0 26 26'
                    width={16}
                    height={16}
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M13 25C19.6274 25 25 19.6274 25 13C25 6.37258 19.6274 1 13 1C6.37258 1 1 6.37258 1 13C1 19.6274 6.37258 25 13 25Z'
                      fill='currentColor'
                      fillOpacity='0.1'
                      stroke='currentColor'
                      strokeOpacity='0.4'
                      strokeDasharray='2 2'
                    />
                    <path
                      d='M13.7715 8.62857C13.7715 8.42397 13.6902 8.22776 13.5455 8.08308C13.4008 7.93842 13.2046 7.85714 13 7.85714C12.7955 7.85714 12.5992 7.93842 12.4545 8.08308C12.3099 8.22776 12.2286 8.42397 12.2286 8.62857V12.2286H8.62861C8.42401 12.2286 8.22779 12.3098 8.08312 12.4545C7.93846 12.5992 7.85718 12.7954 7.85718 13C7.85718 13.2046 7.93846 13.4008 8.08312 13.5455C8.22779 13.6902 8.42401 13.7714 8.62861 13.7714H12.2286V17.3714C12.2286 17.576 12.3099 17.7722 12.4545 17.9169C12.5992 18.0616 12.7955 18.1429 13 18.1429C13.2046 18.1429 13.4008 18.0616 13.5455 17.9169C13.6902 17.7722 13.7715 17.576 13.7715 17.3714V13.7714H17.3715C17.576 13.7714 17.7723 13.6902 17.9169 13.5455C18.0616 13.4008 18.1429 13.2046 18.1429 13C18.1429 12.7954 18.0616 12.5992 17.9169 12.4545C17.7723 12.3098 17.576 12.2286 17.3715 12.2286H13.7715V8.62857Z'
                      fill='currentColor'
                    />
                  </svg>
                </div>

                <span>Create application</span>
              </Link>
            </DropdownMenu.Item>
          )}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
}

function PlanBadge(props: { application: Application }) {
  return (
    <span
      className={cx(
        'ml-1 rounded-sm px-1 text-xs font-medium leading-normal',
        props.application.subscription_plan_title === 'Free'
          ? 'bg-gray-300 text-gray-1100 border'
          : 'bg-sky-300 text-sky-950 border border-sky-400',
      )}
    >
      {props.application.subscription_plan_title}
    </span>
  );
}
