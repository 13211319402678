import { buildRestPath } from '@utils/api';
import { BASE_PATH } from './constants';

type BuildApplicationPathOptions = {
  resourceId?: string;
  action?: string;
  queryParams?: {
    [key: string]: string;
  };
};

export function buildApplicationPath({
  resourceId,
  action,
  queryParams,
}: BuildApplicationPathOptions = {}): string {
  return buildRestPath({
    basePath: BASE_PATH,
    resourceId,
    action,
    queryParams,
  });
}
