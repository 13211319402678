import React from 'react';

import { QueryResponse, PartialQueryResponse } from '@typeDefs/API';
import { UserSettings } from '@dapi/userSettings/types';
import { OrganizationSettings } from '@dapi/instances/types';
import { useFetchUserSettings } from '@dapi/userSettings/queries';
import { useFetchOrganizationSettings } from '@dapi/instances/queries';

export type SettingsContextType = {
  user: QueryResponse<UserSettings>;
  organization: QueryResponse<OrganizationSettings>;
};

export type PartialSettingsContextType = {
  user: PartialQueryResponse<UserSettings>;
  organization: PartialQueryResponse<OrganizationSettings>;
};

export const SettingsContext =
  // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
  React.createContext<SettingsContextType>(null);

export const useSettingsContext = (): SettingsContextType => {
  const context = React.useContext(SettingsContext);

  if (!context) {
    throw new Error(
      'SettingsContext must be used within a SettingsContextProvider',
    );
  }
  return context;
};

const Provider = ({ children }: { children: React.ReactNode }) => {
  const userSettingsSwr = useFetchUserSettings();
  const organizationSettingsSwr = useFetchOrganizationSettings();

  return (
    <SettingsContext.Provider
      value={{
        user: userSettingsSwr,
        organization: organizationSettingsSwr,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export default Provider;
