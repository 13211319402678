import { SUPPORTED_FEATURES_TITLES } from '@constants';
import * as Popover from '@radix-ui/react-popover';
import { Button } from '@clerk/ceramic/experimental/components/Button';
import { Icon } from '@clerk/ceramic/experimental/components/Icon';
import { useCurrentSubscription } from '@components/planAndBilling/currentSubscription/context/CurrentSubscriptionProvider';
import { applicationInstancePath } from '@utils/routes';
import { useInstance } from '@hooks/useInstance';
import { useApplication } from '@hooks/useApplication';
import { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

import { ProBadge } from '@components/common/Badges/Badges';
import { useSupportedFeature } from '@hooks/useSupportedFeature';
import Link from 'next/link';

export const UnsupportedFeatures = () => {
  const { data: currentSubscription } = useCurrentSubscription();
  const { data: instance } = useInstance();
  const { data: application } = useApplication();
  const { getPlanForFeature } = useSupportedFeature();
  const { unsupported_features } = currentSubscription;

  const [open, setOpen] = useState(false);

  return (
    <Popover.Root open={open} onOpenChange={setOpen}>
      This application is using{' '}
      <Popover.Trigger className='font-medium outline-none'>
        <span className='underline'>features unsupported</span>
      </Popover.Trigger>{' '}
      by the current plan.
      <Popover.Portal forceMount>
        <Popover.Content sideOffset={3} className='outline-none'>
          <AnimatePresence>
            {open && (
              <motion.div
                initial={{ opacity: 0, y: -4 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{
                  opacity: 0,
                  y: -4,
                  transition: { duration: 0.15 },
                }}
                transition={{
                  type: 'spring',
                  stiffness: 500,
                  damping: 28,
                  opacity: { duration: 0.2 },
                }}
                className='w-96 overflow-hidden rounded-xl border border-black/[0.06] bg-surface-100 bg-clip-padding shadow-lg'
              >
                <div className='rounded-b-lg border-b border-legacyGray-200/75 bg-surface-200 shadow-[0_1px_2px_theme(colors.black/0.04)]'>
                  <header className='space-y-1 border-b border-primary px-4 py-3'>
                    <p className='font-medium'>Unsupported features</p>
                    <p className='text-sm text-secondary'>
                      List of active unsupported features under your current
                      plan
                    </p>
                  </header>

                  <ul className='divide-y divide-legacyGray-100'>
                    {unsupported_features.map(feature => (
                      <li
                        className='flex items-center gap-2 px-4 py-3 text-tertiary'
                        key={feature}
                      >
                        <Icon name='exclamation-circle' size='sm' />
                        <p className='text-sm font-book leading-none text-secondary'>
                          {SUPPORTED_FEATURES_TITLES[feature]}
                        </p>
                        <ProBadge>{getPlanForFeature(feature)?.name}</ProBadge>
                      </li>
                    ))}
                  </ul>
                </div>

                <footer className='flex justify-end p-4'>
                  <Button
                    asChild
                    intent='primary'
                    size='sm'
                    onClick={() => setOpen(false)}
                  >
                    <Link
                      href={`${applicationInstancePath({
                        // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
                        instanceId: instance?.id,
                        // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
                        applicationId: application?.id,
                      })}/plan-billing`}
                    >
                      Upgrade plan
                    </Link>
                  </Button>
                </footer>
              </motion.div>
            )}
          </AnimatePresence>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};
