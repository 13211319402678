import { Instance } from '@dapi/instances/types';
import { EnvironmentType } from './environment';

const envTypeOrder = {
  [EnvironmentType.Development]: 0,
  [EnvironmentType.Staging]: 1,
  [EnvironmentType.Production]: 2,
};

type InstanceItem = Partial<Instance>;

export const devInstancesFirst = (a: InstanceItem, b: InstanceItem) =>
  // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
  envTypeOrder[a.environment_type] - envTypeOrder[b.environment_type];
