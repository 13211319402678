import { cx } from 'cva';
import { camelCase, upperFirst } from 'lodash';
import * as IconPaths from './IconPaths';
import React from 'react';

/**
   * Adding and using an icon:
   *
   *   1. Each icon must have its own file in the ./IconPaths folder named as: [YourNewIcon]IconPath.tsx
   *       - Replace [YourNewIcon] with the icon's name (e.g., ArrowDownCircleIconPath.tsx).
   *
   *   2. Export a function returning the SVG paths
   *       - Each icon must have both `sm` (`viewBox`: `0 0 16 16`) and `base` (`viewBox`: `0 0 20 20`) size variants.
   *
   *   3. Base icons that are two-tone require a `fill="currentColor"` and `fillOpacity={0.15}` on the `path` that has an optional fill.
   *
   *   4. Add the icon name to IconProps
   *       - Use lowercase letters, and if an icon name has two or more words, separate them with a dash (e.g., 'arrow-down-circle').
   *       - Make sure the name matches the file name (e.g., 'arrow-down-circle' matches the 'ArrowDownCircleIconPath.tsx' file name).
   *
   *   5. Add icon export to ./IconPaths/index.ts
   *
   *   6. Usage:
   * ```tsx
   *    import { Icon } from '@components/Icon';
   *
   *    When using an icon of the base variant:
   *    <Icon name="arrow-down-circle" />

   *
   *    When using an icon of the small variant:
   *    <Icon name="arrow-down-circle" size="sm" />
   *
   * ```
   */

export interface IconProps {
  name:
    | 'application'
    | 'application2'
    | 'arrow-down-circle'
    | 'arrow-right'
    | 'arrow-up-circle'
    | 'arrow-up-square'
    | 'arrow-up-tray'
    | 'at-symbol'
    | 'bell'
    | 'block'
    | 'bolt'
    | 'book'
    | 'box'
    | 'building'
    | 'chart-bar-square'
    | 'check-circle'
    | 'checkmark'
    | 'chevron-down'
    | 'chevron-left'
    | 'chevron-right'
    | 'chevron-up-down'
    | 'chevron-up'
    | 'clipboard'
    | 'cog'
    | 'code-bracket-square'
    | 'code-bracket'
    | 'cog-6-teeth'
    | 'connections'
    | 'credit-card'
    | 'cross'
    | 'document'
    | 'door'
    | 'ellipsis-horizontal'
    | 'ellipsis-vertical'
    | 'envelope'
    | 'exclamation-circle'
    | 'exclamation-mark-square'
    | 'exclamation-octagon'
    | 'exclamation-triangle'
    | 'eye-slash'
    | 'eye'
    | 'fingerprint'
    | 'funnel'
    | 'globe'
    | 'home'
    | 'information-circle'
    | 'information-square'
    | 'jwt'
    | 'key'
    | 'lifebuoy'
    | 'link'
    | 'lock'
    | 'magnifying-glass'
    | 'minus'
    | 'megaphone'
    | 'minus-circle'
    | 'monitor'
    | 'moon'
    | 'native-apps'
    | 'pen'
    | 'plug'
    | 'plus-circle'
    | 'plus'
    | 'progress'
    | 'rocket'
    | 'rotate-anti-clockwise'
    | 'rotate-clockwise'
    | 'route'
    | 'shield'
    | 'speech-bubble'
    | 'square-check'
    | 'stacked-rectangle'
    | 'sun'
    | 'swatch'
    | 'thumb-down'
    | 'thumb-up'
    | 'trash'
    | 'user-circle'
    | 'user-dotted-circle'
    | 'webhooks'
    | 'x';
  size?: 'sm' | 'base';
  className?: string;
}

export function Icon({ name, size = 'base', className }: IconProps) {
  const moduleName = `${upperFirst(camelCase(name))}IconPath`;
  const IconName = IconPaths[moduleName];

  if (!IconName) {
    return null;
  }

  return (
    <svg
      className={cx(className, {
        'size-4': size === 'sm',
        'size-5': size === 'base',
      })}
      viewBox={size === 'sm' ? '0 0 16 16' : '0 0 20 20'}
      fill='none'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
      xmlns='http://www.w3.org/2000/svg'
    >
      <IconName size={size} />
    </svg>
  );
}
