export function LockIconPath(props: { size?: 'sm' | 'base' }) {
  const { size = 'base' } = props;
  return (
    <>
      {size === 'base' ? (
        <>
          <path
            d='M3.75 15.25V9.75C3.75 9.19772 4.19772 8.75 4.75 8.75H15.25C15.8023 8.75 16.25 9.19772 16.25 9.75V15.25C16.25 16.3546 15.3546 17.25 14.25 17.25H5.75C4.64543 17.25 3.75 16.3546 3.75 15.25Z'
            fill='currentColor'
            fillOpacity={0.15}
          />
          <path d='M5.75 8.5V7C5.75 4.65279 7.65279 2.75 10 2.75V2.75C12.3472 2.75 14.25 4.65279 14.25 7V8.5' />
        </>
      ) : (
        <>
          <path d='M5.25 7.75H3.75V11.25C3.75 12.3546 4.64543 13.25 5.75 13.25H10.25C11.3546 13.25 12.25 12.3546 12.25 11.25V7.75H10.75M5.25 7.75V5.5C5.25 3.98122 6.48122 2.75 8 2.75V2.75C9.51878 2.75 10.75 3.98122 10.75 5.5V7.75M5.25 7.75H10.75' />
        </>
      )}
    </>
  );
}
