import { useOrganization } from '@clerk/nextjs';
import { useMembership } from './useMembership';
import { useGetSubscriptionPlans } from '@dapi/currentOrganization/queries';

export function useOrganizationSubscriptionPlans(): ReturnType<
  typeof useGetSubscriptionPlans
> {
  const { isAdmin } = useMembership();
  const { organization } = useOrganization();

  return useGetSubscriptionPlans({
    // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
    id: organization?.id,
    options: { skipRequest: !isAdmin || !organization?.id },
  });
}
