export function ChevronDownIconPath(props: { size?: 'sm' | 'base' }) {
  const { size = 'base' } = props;
  return (
    <>
      {size === 'base' ? (
        <>
          <path d='M5.75 8.75L10 13.25L14.25 8.75' />
        </>
      ) : (
        <>
          <path d='M4.75 6.75L8 10.25L11.25 6.75' />
        </>
      )}
    </>
  );
}
