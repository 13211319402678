import { useQuery } from '@hooks/useQuery';
import type { APIQueryHook } from '@typeDefs/API';

import type { UserSettings } from './types';

import { buildUserSettingsPath } from './utils';
import { getUserSettings } from './api';

export const useFetchUserSettings: APIQueryHook<UserSettings> = () => {
  const path = buildUserSettingsPath();
  return useQuery(path, getUserSettings);
};
