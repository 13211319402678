import React from 'react';
import { useAuth } from '@clerk/nextjs';
import { useLocation } from './useLocation';

function buildLastActiveKey(userId: string) {
  return `clerk-la-${userId}`;
}

type UseLastActive = (args?: { shouldTrack?: boolean }) => {
  getLastActive: () =>
    | {
        applicationId: string;
        instanceId: string;
      }
    | Record<string, never>;
  clearLastActive: () => void;
};

export const useLastActive: UseLastActive = ({
  shouldTrack, // In case you only want to get the last active, leave this out
} = {}) => {
  const { userId, isSignedIn } = useAuth();
  const { applicationId, instanceId } = useLocation();
  const lastActive = React.useRef({
    applicationId,
    instanceId,
  });

  const clearLastActive = () => {
    if (!isSignedIn) {
      return;
    }
    localStorage.removeItem(buildLastActiveKey(userId));
  };

  const saveToLocalStorage = () => {
    const { applicationId, instanceId } = lastActive.current;

    if (!isSignedIn || !applicationId || !instanceId) {
      return;
    }

    localStorage.setItem(
      buildLastActiveKey(userId),
      JSON.stringify({
        applicationId,
        instanceId,
      }),
    );
  };

  const getLastActive = () => {
    if (!isSignedIn) {
      return {};
    }

    const lastActive = localStorage.getItem(buildLastActiveKey(userId));
    if (!lastActive) {
      return {};
    }

    return JSON.parse(lastActive);
  };

  // Add and remove focus listener
  React.useEffect(() => {
    if (shouldTrack) {
      window.addEventListener('focus', saveToLocalStorage);

      return () => {
        window.removeEventListener('focus', saveToLocalStorage);
      };
    }
  }, []);

  // Update when app or instance ID changes
  React.useEffect(() => {
    if (shouldTrack) {
      lastActive.current = {
        applicationId,
        instanceId,
      };
      saveToLocalStorage();
      return () => {
        clearLastActive();
      };
    }
  }, [applicationId, instanceId, shouldTrack]);

  return {
    getLastActive,
    clearLastActive,
  };
};
