import { BreadcrumbsProps } from '@ui/components/Breadcrumbs';
import { create } from 'zustand';

interface UIStore {
  // properties
  crumbs: BreadcrumbsProps['crumbs'];

  // setters
  setCrumbs: (crumbs: BreadcrumbsProps['crumbs']) => void;
}

export const useUIStore = create<UIStore>()(set => ({
  setCrumbs: crumbs => {
    set({ crumbs });
  },
  crumbs: [] as BreadcrumbsProps['crumbs'],
}));
