import React from 'react';
import { Text } from '@chakra-ui/react';
import { InputBox } from '@components/common/InputBox';

type InputBoxPreview = {
  domain: string;
  subdomain: string;
  homeUrl: string;
};

export function InputBoxPreview({
  domain,
  subdomain,
  homeUrl,
}: InputBoxPreview) {
  return (
    <InputBox
      p={0}
      mb={2}
      width='100%'
      label='Subdomain'
      description='This is used to form the basis for your application redirects. You can set or modify this in the Paths page later.'
    >
      <div className='h-10 rounded-md border border-[#E2E8F0] bg-[#EBEBEB] px-3.5 py-2.5 text-[0.875rem] shadow-none'>
        {subdomain && homeUrl ? (
          <>
            <Text as='span' color='legacyGray.800'>
              {subdomain}
            </Text>
            <Text as='span' color='legacyGray.400'>{`.${domain}`}</Text>
          </>
        ) : (
          ''
        )}
      </div>
    </InputBox>
  );
}
