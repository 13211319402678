import { GET, POST } from '@utils/api';

import {
  SubscriptionResponse,
  OrganizationSubscriptionPlanResponse,
  OrganizationRefreshPaymentStatusParams,
  CreateCustomerPortalSessionParams,
  CreateCustomerPortalSessionResponse,
  CreateCheckoutSessionResponse,
  CreateCheckoutSessionParams,
  OrganizationCurrentSubscriptionResponse,
} from './types';
import {
  CheckoutSubscriptionParams,
  CheckoutSubscriptionResponse,
} from '@dapi/products/types';

export const getOrganizationSubscription = ({
  path,
  token,
}: {
  path: string;
  token: string;
}): Promise<SubscriptionResponse> => {
  return GET<SubscriptionResponse>({ path, token });
};

export const getSubscriptionPlans = ({
  path,
  token,
}: {
  path: string;
  token: string;
}): Promise<OrganizationSubscriptionPlanResponse> => {
  return GET<OrganizationSubscriptionPlanResponse>({ path, token });
};

export const refreshPaymentStatus = ({
  path,
  token,
  data,
}: {
  path: string;
  token: string;
  data: OrganizationRefreshPaymentStatusParams;
}): Promise<never> => {
  return POST<never>({ path, token, data });
};

export const customerPortalSession = ({
  path,
  token,
  data,
}: {
  path: string;
  token: string;
  data: CreateCustomerPortalSessionParams;
}): Promise<CreateCustomerPortalSessionResponse> => {
  return POST<CreateCustomerPortalSessionResponse>({ path, token, data });
};

export const organizationCheckoutSession = ({
  path,
  token,
  data,
}: {
  path: string;
  token: string;
  data: CreateCheckoutSessionParams;
}): Promise<CreateCheckoutSessionResponse> => {
  return POST<CreateCheckoutSessionResponse>({ path, token, data });
};

export const currentOrganizationSubscription = ({
  path,
  token,
}: {
  path: string;
  token: string;
}): Promise<OrganizationCurrentSubscriptionResponse> => {
  return GET<OrganizationCurrentSubscriptionResponse>({ path, token });
};

export const checkoutSubscription = ({
  path,
  token,
  data,
}: {
  path: string;
  token: string;
  data: CheckoutSubscriptionParams;
}): Promise<CheckoutSubscriptionResponse> => {
  return POST<CheckoutSubscriptionResponse>({ path, token, data });
};

export const completeSubscription = ({
  path,
  token,
}: {
  path: string;
  token: string;
}): Promise<never> => {
  return POST<never>({ path, token, data: {} });
};
