import React from 'react';

import { QueryResponse, PartialQueryResponse } from '@typeDefs/API';
import { FeatureFlags } from '@dapi/featureFlags/types';
import { useFetchFeatureFlags } from '@dapi/featureFlags/queries';

export type FeatureFlagsContextType = QueryResponse<FeatureFlags>;
export type PartialFeatureFlagsContextType = PartialQueryResponse<FeatureFlags>;

export const FeatureFlagsContext = React.createContext<FeatureFlagsContextType>(
  {
    isLoading: true,
    data: undefined,
    // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
    isValidating: undefined,
    error: undefined,
    // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
    mutate: undefined,
  },
);

export const useFeatureFlagContext = (): FeatureFlagsContextType => {
  return React.useContext(FeatureFlagsContext);
};

const Provider = ({ children }: { children: React.ReactNode }) => {
  const swr = useFetchFeatureFlags();

  return (
    <FeatureFlagsContext.Provider value={swr}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};

export default Provider;
