var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"6ae94fc34c0cfe93c223637a166de1abcf5d8977"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,

  // Traces sampling rate to 0.1% of requests for performance monitoring.
  // We should achieve statistical significance with this profiling amount.
  // All errors will still be sent to Sentry.
  tracesSampleRate: 0.001,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  ignoreErrors: [
    // Random plugins/extensions
    'top.GLOBALS',
    /ClerkJS: Token refresh failed /i,
  ],

  denyUrls: [
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    /^chrome-extension:\/\//i,
  ],

  beforeSend(event, hint) {
    try {
      const error = hint.originalException;
      const statusError = (error as any)?.status;
      if (statusError >= 400 || statusError < 500) {
        return null;
      }
      if (
        error &&
        (error as any)?.message &&
        (error as any)?.message?.match(/ClerkJS: Token refresh failed /i)
      ) {
        return null;
      }
    } catch (e: any) {
      Sentry.captureException(e, {
        tags: {
          section: 'beforeSend',
          function: 'handlingError',
        },
        contexts: {
          stacktrace: e.stack,
        },
      });
      return null;
    }

    return event;
  },
});
