import { inputAnatomy as parts } from '@chakra-ui/anatomy';
import type { PartsStyleObject } from '@chakra-ui/theme-tools';
import type { SystemStyleObject } from '@chakra-ui/react';
import { colors } from '../foundations/colors';

// TODO: Revisit this
// https://github.com/chakra-ui/chakra-ui/issues/2347
const baseStyle: PartsStyleObject<typeof parts> = {
  field: {
    _hover: {
      borderColor: 'primary.500',
    },
    _focus: {
      borderColor: 'primary.500 !important',
      boxShadow: 'none !important',
    },
    _focusVisible: {
      borderColor: 'primary.500 !important',
      boxShadow: 'none !important',
    },
    '&[aria-invalid=true]': {
      borderColor: 'danger.500',
      boxShadow: 'none !important',
    },
    /*
     default userSelect: all for readonly
     input prevent selection in Safari
    */
    '&[aria-readonly=true]': {
      userSelect: 'auto !important',
    },
  },
};

const size: Record<string, SystemStyleObject> = {
  md: {
    fontSize: 'xs',
    fontWeight: 'normal',
    h: 8,
    px: 3,
    borderRadius: 'md',
  },
};

const sizes: Record<string, PartsStyleObject<typeof parts>> = {
  md: {
    field: size.md,
    addon: size.md,
  },
};

const defaultProps = {
  size: 'md',
  variant: 'outline',
  errorBorderColor: colors.danger['500'],
};

export const Input = {
  parts: parts.keys,
  baseStyle,
  sizes,
  defaultProps,
};
