import type {
  ChakraTheme,
  ThemeComponents,
  ThemeConfig,
} from '@chakra-ui/react';
import { extendTheme, withDefaultColorScheme } from '@chakra-ui/react';
import type { Styles } from '@chakra-ui/theme-tools';
import { suisse } from '@clerk/ceramic/fonts';
import { Accordion } from '@theme/components/accordion';
import { Box } from '@theme/components/box';
import { Badge } from '@theme/components/badge';
import { Button } from '@theme/components/button';
import { Checkbox } from '@theme/components/checkbox';
import { Code } from '@theme/components/code';
import { Divider } from '@theme/components/divider';
import { Heading } from '@theme/components/heading';
import { Input } from '@theme/components/input';
import { Link } from '@theme/components/link';
import { Menu } from '@theme/components/menu';
import { Modal } from '@theme/components/modal';
import { Radio } from '@theme/components/radio';
import { Spinner } from '@theme/components/spinner';
import { Switch } from '@theme/components/switch';
import { Select } from '@theme/components/select';
import { Table } from '@theme/components/table';
import { Tabs } from '@theme/components/tabs';
import { Textarea } from '@theme/components/textarea';
import { Text } from '@theme/components/text';
import { Tooltip } from '@theme/components/tooltip';
import { Popover } from '@theme/components/popover';
import { FormError } from '@theme/components/formError';
import { Colors, colors } from '@theme/foundations/colors';
import {
  SemanticTokens,
  semanticTokens,
} from '@theme/foundations/semanticTokens';
import { textStyles } from '@theme/foundations/textStyles';
import { layerStyles } from '@theme/foundations/layerStyles';
import { sizes } from '@theme/foundations/sizes';
import { fonts } from '@theme/foundations/fonts';
import { fontSizes } from '@theme/foundations/fontSizes';
import { shadows } from '@theme/foundations/shadows';
import { lineHeights } from '@theme/foundations/lineHeights';
import { borders } from '@theme/foundations/border';

const config: ThemeConfig = {
  initialColorMode: 'light',
};

const styles: Styles = {
  global: () => ({
    // Override Chakra's default border with `@clerk/ceramic`'s
    // Most Chakra components _should_ be unaffected as they inherit from `Box`
    '*, *::before, *::after': {
      borderColor: 'var(--border-color-default)',
    },
    // Any remaining Chakra components that don't inherit from `Box` will
    // get set caught by this selector
    '[class*="chakra"]': {
      borderColor: 'var(--chakra-colors-chakra-border-color)',
    },
    body: {
      fontFamily: suisse.fontFamily,
    },
  }),
};

const components: ThemeComponents = {
  Box,
  Accordion,
  Badge,
  Button,
  Checkbox,
  Code,
  Divider,
  Heading,
  Input,
  Link,
  Menu,
  Modal,
  Radio,
  Spinner,
  Switch,
  Select,
  Table,
  Tabs,
  Textarea,
  Tooltip,
  Popover,
  FormError,
  Text,
};

const foundations = {
  colors,
  textStyles,
  layerStyles,
  sizes,
  fonts,
  fontSizes,
  shadows,
  lineHeights,
  borders,
  semanticTokens,
};

type Theme = Omit<ChakraTheme, 'colors' | 'semanticTokens'> & {
  colors: Colors;
  semanticTokens: SemanticTokens;
};

const themeOverrides: Partial<Theme> = {
  styles,
  config,
  components,
  ...foundations,
};

const theme = extendTheme(
  themeOverrides,
  withDefaultColorScheme({ colorScheme: 'primary' }),
);

export default theme;
