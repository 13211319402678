export function TrashIconPath(props: { size?: 'sm' | 'base' }) {
  const { size = 'base' } = props;
  return (
    <>
      {size === 'base' ? (
        <>
          <path
            d='M5.58796 15.4271L5 5.75H7L7.54415 4.11754C7.81638 3.30086 8.58066 2.75 9.44152 2.75H10.5585C11.4193 2.75 12.1836 3.30086 12.4558 4.11754L13 5.75H15L14.412 15.4271C14.3203 16.459 13.4558 17.25 12.4199 17.25H7.58011C6.54416 17.25 5.67968 16.459 5.58796 15.4271Z'
            fill='currentColor'
            fillOpacity={0.15}
          />
          <path d='M5 5.75L5.58796 15.4271C5.67968 16.459 6.54416 17.25 7.58011 17.25H12.4199C13.4558 17.25 14.3203 16.459 14.412 15.4271L15 5.75M2.75 5.75H7M17.25 5.75H13M7 5.75L7.54415 4.11754C7.81638 3.30086 8.58066 2.75 9.44152 2.75H10.5585C11.4193 2.75 12.1836 3.30086 12.4558 4.11754L13 5.75M7 5.75H13' />
        </>
      ) : (
        <>
          <path d='M3.75 5.25L4.6405 12.374C4.70306 12.8745 5.12846 13.25 5.63278 13.25H10.3672C10.8715 13.25 11.2969 12.8745 11.3595 12.374L12.25 5.25M3.75 5.25H2.75M3.75 5.25H5.75M12.25 5.25H13.25M12.25 5.25H10.25M5.75 5.25V4.75C5.75 3.64543 6.64543 2.75 7.75 2.75H8.25C9.35457 2.75 10.25 3.64543 10.25 4.75V5.25M5.75 5.25H10.25' />
        </>
      )}
    </>
  );
}
