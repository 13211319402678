import type { tableAnatomy as parts } from '@chakra-ui/anatomy';
import type { PartsStyleObject } from '@chakra-ui/theme-tools';

const baseStyle: PartsStyleObject<typeof parts> = {
  th: {
    fontWeight: 'medium',
    letterSpacing: 'normal',
    textTransform: 'capitalize',
  },
};

export const Table = {
  baseStyle,
};
