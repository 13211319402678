import { cva, type VariantProps } from 'cva';
import { getIconId } from '../utils/id';
import {
  _LEGACY_SYMBOLS_SUFFIX,
  _LEGACY_SYMBOLS_VIEWBOX,
  _SYMBOLS_VIEWBOX,
  type IconSpriteName,
} from './IconSprite';

const icon = cva({
  base: 'shrink-0 overflow-visible stroke-[1.25]',
  variants: {
    size: {
      sm: 'size-4',
      base: 'size-5',
    },
    fill: {
      transparent: '[--icon-fill:transparent]',
    },
  },
});

export type IconName = IconSpriteName;

export interface IconProps
  extends Pick<
      React.ComponentProps<'svg'>,
      'aria-hidden' | 'aria-label' | 'className'
    >,
    VariantProps<typeof icon> {
  name: IconName;
}

export function Icon({
  className,
  name,
  fill,
  size = 'base',
  'aria-hidden': ariaHidden = true,
  'aria-label': ariaLabel,
}: IconProps) {
  // https://linear.app/clerk/issue/DES-937/remove-legacy-icons
  const isLegacy = name.endsWith(_LEGACY_SYMBOLS_SUFFIX);

  return (
    <svg
      fill='none'
      fillOpacity={10}
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      className={icon({ size, fill, className })}
      aria-hidden={ariaLabel ? undefined : ariaHidden}
      aria-label={ariaLabel}
      role={ariaLabel ? 'img' : undefined}
      data-icon=''
      data-size={size}
      viewBox={isLegacy ? _LEGACY_SYMBOLS_VIEWBOX : _SYMBOLS_VIEWBOX[size]}
    >
      <use
        href={`#${getIconId({ name, size: isLegacy ? undefined : size })}`}
      />
    </svg>
  );
}
