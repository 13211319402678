import { ApplicationSubscription as TApplicationSubscription } from '@dapi/currentOrganization/types';
import { Badge } from '@components/planAndBilling/Badge';
import { Section } from '@components/planAndBilling/organization/Section';
import { priceFromCentsToDollars } from '@components/planAndBilling/utils';
import { Number } from '@clerk/ceramic/experimental/components/Number';
import { SegmentGraph } from '@clerk/ceramic/experimental/components/SegmentGraph';
import { format, parseISO } from 'date-fns';
import { FREE_PLAN_ID } from '@components/planAndBilling/currentSubscription/constants';
import { Icon } from '@clerk/ceramic/experimental/components/Icon';
import { ManagePlan } from './ManagePlan';
import { DATE_FORMAT } from '@components/planAndBilling/constants';

export const ApplicationSubscription = ({
  clearSelectedApplication,
  application,
}: {
  application: TApplicationSubscription;
  clearSelectedApplication: () => void;
}) => {
  const enabledAddons = application.addons.filter(addon => addon.is_subscribed);
  return (
    <div className='space-y-10'>
      <div className='space-y-5'>
        <button onClick={() => clearSelectedApplication()}>
          <span className='inline-flex items-center gap-1.5 text-sm font-medium text-secondary'>
            <Icon name='arrow-left-legacy' size='sm' />
            Billing
          </span>
        </button>

        <Section title={application.name} showDivider={false}>
          <div className='space-y-5'>
            <div className='mt-4 flex items-center justify-between'>
              <div>
                <Number
                  prefix='$'
                  className='text-2xl font-medium'
                  aria-label={`Total amount due for ${application.plan.name}`}
                >
                  {priceFromCentsToDollars(application.amount_due)}
                </Number>

                {application.credit_balance > 0 && (
                  <span className='ml-1 text-legacyGray-500'>
                    (
                    <Number prefix='$' className='font-medium'>
                      {priceFromCentsToDollars(application.credit_balance)}
                    </Number>{' '}
                    credit balance)
                  </span>
                )}
              </div>

              <ManagePlan applicationId={application.application_id} />
            </div>

            {application.plan.id !== FREE_PLAN_ID && (
              <div className='border-y border-primary py-3'>
                <div>
                  <div className='grid w-full grid-cols-3 [&>div:first-child]:border-r [&>div:last-child]:border-l [&>div]:border-primary'>
                    <div className='space-y-1'>
                      <h4 className='text-sm font-book text-legacyGray-500'>
                        Active plan
                      </h4>
                      <div className='flex flex-col items-start gap-2 sm:flex-row sm:items-center'>
                        <Badge color='skyblue'>{application.plan.name}</Badge>
                        {enabledAddons.length > 0 && (
                          <Badge color='spectrum'>{`+${enabledAddons.length} add-ons`}</Badge>
                        )}
                      </div>
                    </div>

                    <div className='space-y-1 px-3'>
                      <h4 className='text-sm font-book text-legacyGray-500'>
                        Billing cycle
                      </h4>
                      <span className='font-book text-legacyGray-700'>
                        <time>
                          {format(
                            // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
                            parseISO(application.billing_cycle.start),
                            DATE_FORMAT,
                          )}
                        </time>
                        {' - '}
                        <time>
                          {format(
                            // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
                            parseISO(application.billing_cycle.end),
                            DATE_FORMAT,
                          )}
                        </time>
                      </span>
                    </div>

                    <div className='space-y-1 px-3'>
                      <h4 className='text-sm font-book text-legacyGray-500'>
                        Next bill date
                      </h4>
                      <time className='font-book text-legacyGray-700'>
                        {format(
                          // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
                          parseISO(application.billing_cycle.end),
                          DATE_FORMAT,
                        )}
                      </time>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Section>
      </div>

      <Section title='Current plan' showDivider={false}>
        <div className='mt-3 rounded-lg shadow-[0_1px_2px_theme(colors.black/0.12),0_0_2px_theme(colors.black/0.08)] ring-1 ring-legacyGray-950/[0.06]'>
          <table className='w-full'>
            <tbody className='[&>tr:last-child]:border-b-0 [&>tr]:border-b [&>tr]:border-primary'>
              <tr className='[&_>td]:p-3'>
                <td>
                  <div className='space-y-1'>
                    <div className='flex items-center gap-2'>
                      {application.plan.name}
                    </div>

                    {application.discount.percent_off > 0 && (
                      <div className='text-legacyGray-500'>
                        <Number
                          aria-label='Discount percentage for this subscription'
                          suffix='%'
                        >
                          {application.discount.percent_off}
                        </Number>{' '}
                        discount applied, expires on{' '}
                        {format(
                          new Date(application.discount.ends_at),
                          DATE_FORMAT,
                        )}
                        .
                      </div>
                    )}
                  </div>
                </td>
                <td className='text-right'>
                  <Number
                    prefix='$'
                    className='font-medium'
                    aria-label='Base plan price'
                  >
                    {priceFromCentsToDollars(application.plan.base_amount)}
                  </Number>
                </td>
              </tr>

              {enabledAddons.map(addon => (
                <tr key={addon.name} className='[&_>td]:p-3'>
                  <td>
                    <div className='flex items-center gap-2'>
                      <span className='max-w-[120px] truncate sm:max-w-full'>
                        {addon.name}
                      </span>
                      <Badge color='spectrum'>Add-on</Badge>
                    </div>
                  </td>
                  <td className='text-right'>
                    <Number
                      prefix='$'
                      className='font-medium'
                      aria-label={`Price for ${addon.name} add-on`}
                    >
                      {priceFromCentsToDollars(addon.base_amount)}
                    </Number>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Section>

      <Section title='Usage detail' showDivider={false}>
        <div className='mt-3 rounded-lg shadow-[0_1px_2px_theme(colors.black/0.12),0_0_2px_theme(colors.black/0.08)] ring-1 ring-legacyGray-950/[0.06]'>
          <table className='w-full'>
            <tbody className='[&>tr:last-child]:border-b-0 [&>tr]:border-b [&>tr]:border-primary'>
              <UsageRow
                title='Monthly active users'
                // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
                {...application.usage.mau}
              />
              <UsageRow
                title='Monthly organization users'
                // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
                {...application.usage.mao}
              />

              {/* @ts-expect-error FIXME: Exposed from enabling StrictNullChecks */}
              {application.usage.sms_tier_a.is_used && (
                <UsageRow
                  showGraph={false}
                  title='SMS usage'
                  total_units={
                    // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
                    application.usage.sms_tier_a.total_units +
                    // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
                    application.usage.sms_tier_b.total_units +
                    // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
                    application.usage.sms_tier_c.total_units +
                    // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
                    application.usage.sms_tier_d.total_units +
                    // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
                    application.usage.sms_tier_e.total_units +
                    // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
                    application.usage.sms_tier_f.total_units
                  }
                  amount_due={
                    // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
                    application.usage.sms_tier_b.amount_due +
                    // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
                    application.usage.sms_tier_a.amount_due +
                    // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
                    application.usage.sms_tier_c.amount_due +
                    // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
                    application.usage.sms_tier_d.amount_due +
                    // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
                    application.usage.sms_tier_e.amount_due +
                    // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
                    application.usage.sms_tier_f.amount_due
                  }
                />
              )}

              {/* @ts-expect-error FIXME: Exposed from enabling StrictNullChecks */}
              {application.usage.saml_connections.is_used && (
                <UsageRow
                  showGraph={false}
                  title='SAML Connections'
                  // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
                  {...application.usage.saml_connections}
                />
              )}

              {/* @ts-expect-error FIXME: Exposed from enabling StrictNullChecks */}
              {application.usage.domains.is_used && (
                <UsageRow
                  showGraph={false}
                  title='Multi-domain auth'
                  // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
                  {...application.usage.domains}
                />
              )}
            </tbody>
          </table>
        </div>
      </Section>
    </div>
  );
};

const UsageRow = ({
  title,
  hard_limit,
  free_limit,
  total_units,
  amount_due,
  showGraph = true,
}: {
  title: string;
  hard_limit?: number;
  free_limit?: number;
  total_units: number;
  amount_due: number;
  showGraph?: boolean;
}) => (
  <tr className='[&_>td]:p-3'>
    <td>
      <span className='mb-2 block text-legacyGray-700'>
        <span className='text-secondary'>{title}: </span>
        {total_units.toLocaleString()}
      </span>

      {showGraph && (
        <SegmentGraph
          // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
          totalLimit={hard_limit}
          // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
          freeAllowance={free_limit}
          totalUsage={total_units}
        />
      )}
    </td>
    <td className='text-right align-top'>
      <Number
        prefix='$'
        className='font-medium'
        aria-label={`Price for ${title}`}
      >
        {priceFromCentsToDollars(amount_due)}
      </Number>
    </td>
  </tr>
);
