import { buildRestPath } from '@utils/api';
import { BASE_PATH } from './constants';

type BuildProductPathOptions = {
  resourceId?: string;
  action?: string;
  queryParams?: {
    [key: string]: string;
  };
};

export function buildProductsPath({
  resourceId,
  action,
  queryParams,
}: BuildProductPathOptions = {}): string {
  return buildRestPath({
    basePath: BASE_PATH,
    resourceId,
    action,
    queryParams,
  });
}

type ReturnBuildQueryParams = {
  offset?: string;
  limit?: string;
};
export const buildQueryParams = ({
  page,
  limit,
}: {
  limit?: number;
  page?: number;
}): ReturnBuildQueryParams => ({
  // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
  ...(page ? { offset: limit * (page - 1) + '' } : {}),
  ...(limit ? { limit: `${limit}` } : {}),
});
