export function AtSymbolIconPath(props: { size?: 'sm' | 'base' }) {
  const { size = 'base' } = props;
  return (
    <>
      {size === 'base' ? (
        <>
          <path d='M10 17.25C5.99594 17.25 2.75 14.0041 2.75 10C2.75 5.99594 5.99594 2.75 10 2.75C14.0041 2.75 17.25 5.99594 17.25 10V11.25C17.25 12.3546 16.3546 13.25 15.25 13.25C14.1454 13.25 13.25 12.3546 13.25 11.25V6.75M10 13.25C8.20507 13.25 6.75 11.7949 6.75 10C6.75 8.20507 8.20507 6.75 10 6.75C11.7949 6.75 13.25 8.20507 13.25 10C13.25 11.7949 11.7949 13.25 10 13.25Z' />
        </>
      ) : (
        <>
          <rect x='5.75' y='5.75' width='4.5' height='4.5' rx='2.25' />
          <path d='M8 13.25C5.10051 13.25 2.75 10.8995 2.75 8C2.75 5.10051 5.10051 2.75 8 2.75H10.25C11.9069 2.75 13.25 4.09315 13.25 5.75V9.25C13.25 9.80228 12.8023 10.25 12.25 10.25V10.25C11.4216 10.25 10.75 9.57843 10.75 8.75V5.75' />
        </>
      )}
    </>
  );
}
