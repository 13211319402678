export enum EnvironmentType {
  Production = 'production',
  Development = 'development',
  Staging = 'staging',
}

export function isProduction(environment: string | undefined): boolean {
  return String(environment) === EnvironmentType.Production;
}

export function isDevelopment(environment: string | undefined): boolean {
  return String(environment) === EnvironmentType.Development;
}
