export function MinusCircleIconPath(props: { size?: 'sm' | 'base' }) {
  const { size = 'base' } = props;
  return (
    <>
      {size === 'base' ? (
        <>
          <rect
            x='2.75'
            y='2.75'
            width='14.5'
            height='14.5'
            rx='7.25'
            fill='currentColor'
            fillOpacity={0.15}
          />
          <path d='M6.75 10H13.25' />
        </>
      ) : (
        <>
          <circle cx='8' cy='8' r='5.25' />
          <path d='M5.75 8H10.25' />
        </>
      )}
    </>
  );
}
