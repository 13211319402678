import { CheckoutSubscriptionResponse } from '@dapi/products/types';

export const CheckoutActions = {
  SetCheckout: 'SetCheckout',
  ClearCheckout: 'ClearCheckout',
  SetCheckoutVisible: 'SetCheckoutVisible',
  SetPaymentElementInitialized: 'SetPaymentElementInitialized',
} as const;

export const OptionsActions = {
  SetOptions: 'SetOptions',
} as const;

export type OptionsAction = {
  type: keyof typeof OptionsActions;
  payload: CheckoutOptions;
};

export type CheckoutAction = {
  type: keyof typeof CheckoutActions;
  payload: CheckoutSubscriptionResponse;
};

export type CheckoutActionTypes = CheckoutAction | OptionsAction;

export type CheckoutContextProps = {
  clearCheckout: () => void;
  setCheckout: (
    checkout: CheckoutSubscriptionResponse & {
      stripeSessionIdStatusEndpoint: string;
    },
  ) => void;
  setOptions: (options: CheckoutOptions) => void;
  showCheckout: () => void;
  initializePaymentElement: () => void;
} & CheckoutState;

export type CheckoutState = {
  state: CheckoutSubscriptionResponse & {
    isCheckoutVisible: boolean;
    isPaymentElementInitialized: boolean;
    isTrial: boolean;
    stripeSessionIdStatusEndpoint: string;
  } & CheckoutOptions;
};

export type CheckoutOptions = {
  afterCheckoutCompletedUrl?: string;
  onClearCheckout?: () => void;
};
