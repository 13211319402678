import React, { forwardRef } from 'react';
import type { BadgeProps } from '@chakra-ui/react';
import { Badge } from '@chakra-ui/react';
import Link from 'next/link';
import { Tooltip } from '@clerk/ceramic/experimental/components/Tooltip';
import { cx } from 'cva';
import { useSupportedFeature } from '@hooks/useSupportedFeature';
import { SupportedFeaturesValues } from '@constants';

export function BetaBadge(props: BadgeProps) {
  return (
    <Badge colorScheme='green' size='sm' {...props}>
      Beta
    </Badge>
  );
}

export function ComingSoonBadge(props: BadgeProps) {
  return (
    <Badge colorScheme='warning' size='sm' {...props}>
      Coming soon
    </Badge>
  );
}

export function GenericBadge(
  props: BadgeProps & { label: string; isPrimary?: boolean },
) {
  const { isPrimary, label, ...rest } = props;

  return (
    <Badge
      colorScheme={isPrimary ? 'primary' : 'legacyGray'}
      size='sm'
      {...rest}
    >
      {label}
    </Badge>
  );
}

export function UpdateNeededBadge(props: BadgeProps & { href: string }) {
  return (
    <Badge variant='blue' size='sm' {...props}>
      <Link href={props.href} passHref>
        Update required
      </Link>
    </Badge>
  );
}

export function HostBadge(props: BadgeProps) {
  return (
    <Badge
      bg='legacyGray.50'
      color='red.500'
      fontSize='0.75rem'
      fontFamily='sohneMono'
      lineHeight='1.4'
      letterSpacing={0.4}
      display='inline-flex'
      alignItems='center'
      gap={1}
      {...props}
    />
  );
}

type PlanBadgeProps = {
  className?: string;
  feature: SupportedFeaturesValues;
};

const TooltipContent = ({ children }: { children: React.ReactNode }) => {
  return <span className='text-base'>{children}</span>;
};

export function PlanBadge(props: PlanBadgeProps) {
  const { feature } = props;

  const { getIsInGracePeriod, getPlanForFeature } = useSupportedFeature();

  if (!feature) {
    return null;
  }

  const isInGracePeriod = getIsInGracePeriod(feature);
  const plan = getPlanForFeature(feature);

  if (!plan) {
    return null;
  }

  if (isInGracePeriod) {
    const tooltipContent = plan.is_addon ? (
      <TooltipContent>Enable {plan.name}</TooltipContent>
    ) : (
      <TooltipContent>Upgrade to {plan.name}</TooltipContent>
    );

    return (
      <Tooltip delayDuration={300} label={tooltipContent}>
        <div className='group relative isolate ml-2 inline-flex w-fit transform-gpu overflow-hidden rounded-md bg-legacy-orange-300 px-1.5 shadow-[inset_0px_1px_0px_theme(colors.white/26%)] [--text-color:theme(colors.legacyGray.900)] after:absolute after:inset-0 after:bg-[linear-gradient(theme(colors.white/24%),theme(colors.white/24%)_46%,theme(colors.white/12%)_54%,theme(colors.white/8%))]'>
          <span className='relative z-10 whitespace-nowrap text-[0.625rem] font-semibold leading-[1.1875rem] text-[--text-color]'>
            To be disabled
          </span>
        </div>
      </Tooltip>
    );
  }

  if (plan.is_addon) {
    return (
      <Tooltip
        delayDuration={300}
        label={<TooltipContent>{plan.name}</TooltipContent>}
      >
        <ProBadge {...props}>Add-on</ProBadge>
      </Tooltip>
    );
  }

  return <ProBadge {...props}>{plan.name}</ProBadge>;
}

type ProBadgeProps = React.PropsWithChildren<{ className?: string }>;

export const ProBadge = forwardRef<HTMLSpanElement, ProBadgeProps>(
  function ProBadge(props, forwardedRef) {
    const { children, className } = props;

    return (
      <span
        ref={forwardedRef}
        className={cx([
          'group relative isolate inline-flex w-fit transform-gpu overflow-hidden rounded-md bg-sky-300 px-1.5 shadow-[inset_0px_1px_0px_theme(colors.white/0.26)] [--text-color:theme(colors.sky.900)] after:absolute after:inset-0 after:bg-[linear-gradient(theme(colors.white/0.24),theme(colors.white/0.24)_46%,theme(colors.white/0.12)_54%,theme(colors.white/0.08))]',
          className,
        ])}
      >
        <span className='relative z-10 whitespace-nowrap text-[0.625rem] font-semibold leading-[1.1875rem] text-[--text-color]'>
          {children}
        </span>
      </span>
    );
  },
);
