export function CheckmarkIconPath(props: { size?: 'sm' | 'base' }) {
  const { size = 'base' } = props;
  return (
    <>
      {size === 'base' ? (
        <>
          <path d='M5.25 10.95L9.05 14.75L14.75 5.25' />
        </>
      ) : (
        <>
          <path d='M4.25 8.75L7.25 11.75L11.75 4.25' />
        </>
      )}
    </>
  );
}
